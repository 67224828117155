/* eslint-disable react/destructuring-assignment */
import { useRef } from 'react';
import { useRecoilState } from 'recoil';
import GifPicker from '../pages/lnuser/GifPicker';
import EmojiPicker from '../pages/lnuser/EmojiPicker';
import Button from './Button';
import { uploadingFileState } from '../_states/template.states';
import useLinkedinActions from '../_actions/linkedin.actions';

const Attachments = ({ inputRef, message, setMessage, files, setFiles }) => {
    const fileRef = useRef();
    const linkedinActions = useLinkedinActions();
    const [uploadingFile, setUploadingFile] = useRecoilState(uploadingFileState);
    const onEmojiClick = (emojiData) => {
        if (inputRef.current?.inputRef?.current?.selectionStart) {
            setMessage(
                `${message.slice(0, inputRef.current?.inputRef?.current?.selectionStart)}${
                    emojiData.emoji
                }${message.slice(inputRef.current?.inputRef?.current?.selectionStart)}`
            );
        } else {
            setMessage(`${message}${emojiData.emoji}`);
        }
    };
    const onGifClick = (gifData) => {
        const newTemplateFiles = [...files];
        newTemplateFiles.push({ externalMedia: gifData });
        setFiles(newTemplateFiles);
    };
    const onFileChange = (e) => {
        e.preventDefault();
        let filesInput;
        if (e.dataTransfer) {
            filesInput = e.dataTransfer.files;
        } else if (e.target) {
            filesInput = e.target.files;
        }
        const fileToRead = filesInput[0];
        if (fileToRead) {
            const reader = new FileReader();
            reader.onload = () => {
                setUploadingFile(true);
                linkedinActions.uploadTemplateFile(
                    {
                        name: fileToRead.name,
                        size: fileToRead.size,
                        type: fileToRead.type,
                        content: reader.result,
                    },
                    files,
                    setFiles
                );
            };

            reader.readAsDataURL(fileToRead);
        }
    };
    return (
        <div className="templates__section__footer">
            <div className="templates__section__footer__buttons">
                <div className="templates__section__attachments">
                    <EmojiPicker
                        btnType="secondary"
                        placement="top-center"
                        onEmojiClick={(e) => onEmojiClick(e, inputRef, message, setMessage)}
                    />
                </div>
                <div className="templates__section__attachments">
                    <Button
                        type="secondary"
                        icon="paperclip"
                        isLoading={uploadingFile}
                        onClick={() => {
                            fileRef.current.click();
                        }}
                    />
                    <input style={{ display: 'none' }} ref={fileRef} type="file" onChange={onFileChange} />
                </div>
                <div className="templates__section__attachments">
                    <GifPicker btnType="secondary" placement="top-center" onGifClick={onGifClick} />
                </div>
            </div>
            {files &&
                files.map((templateFile) => (
                    <div
                        className="publication__attachment"
                        key={templateFile.file?.name || templateFile.externalMedia?.title}
                    >
                        <span className="ellipsis">{templateFile.file?.name || templateFile.externalMedia?.title}</span>
                        <Button
                            icon="close"
                            size="mini"
                            type="link"
                            onClick={() => {
                                const newTemplateFiles = [...files];
                                newTemplateFiles.splice(files.indexOf(templateFile), 1);
                                setFiles(newTemplateFiles);
                            }}
                        />
                    </div>
                ))}
        </div>
    );
};

export default Attachments;
