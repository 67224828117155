import React, { useEffect } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { tr } from '../common/locale';
import Logo from '../images/logo.svg';
import LogoDark from '../images/logo-dark.svg';
import useUserActions from '../_actions/user.actions';

function ConfirmEmail() {
    const userActions = useUserActions('login');
    const [token] = useQueryParam('token', StringParam);
    useEffect(() => {
        if (token) {
            userActions.confirmEmail(token);
        }
    }, []);
    const mobilebrand = (
        <div className="mobile">
            <img
                className="login__logo"
                src={Logo}
                alt={tr('Scraping Linkedin, Overpowered Linkedin Messaging Inbox and CRM Pipelines')}
            />
            <img
                className="login__logo--dark"
                src={LogoDark}
                alt={tr('Scraping Linkedin, Overpowered Linkedin Messaging Inbox and CRM Pipelines')}
            />
            <p className="login__par login__par__title">{tr('Welcome in Kanbox App')}</p>
        </div>
    );

    return (
        <div className="login__right get-email">
            {mobilebrand}
            <div className="login__step">
                <div className="login__step__header">
                    <span className="login__step__label">{tr('Success')}</span>
                </div>
                <div className="login__step__body">
                    <div className="login__step__body">
                        <h1>{tr('Your email address is confirmed!')}</h1>
                        <div className="login__form">
                            <p className="par red">
                                {tr('You need a Desktop Chrome or Chromium browser to use Kanbox.')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmEmail;
