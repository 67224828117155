import { useEffect } from 'react';
import ChromeButton from '../images/kanbox-extension-button-remove.svg';
import useUserActions from '../_actions/user.actions';
import ScrollBar from '../components/ScrollBar';
import { tr } from '../common/locale';
import Logo from '../images/logo.svg';
import LogoDark from '../images/logo-dark.svg';

export default function Delete() {
    const { logout } = useUserActions();
    useEffect(() => {
        logout();
    }, []);
    return (
        <ScrollBar>
            <div className="delete">
                <img
                    className="login__logo"
                    src={Logo}
                    alt={tr('Scraping Linkedin, Overpowered Linkedin Messaging Inbox and CRM Pipelines')}
                />
                <img
                    className="login__logo--dark"
                    src={LogoDark}
                    alt={tr('Scraping Linkedin, Overpowered Linkedin Messaging Inbox and CRM Pipelines')}
                />
                <h1>{tr('Your account has been deleted.')}</h1>
                <div className="login__step__body">
                    <h3 className="not-found">{tr('You can delete your Kanbox Chrome extension')}</h3>
                    <p className="par">{tr('Click the button below to manage Kanbox Extension')}</p>
                    <a
                        className="btn btn--link"
                        target="_blank"
                        href="https://chrome.google.com/webstore/detail/bodcackmmefldjpmeefapllhcpdlhfhp"
                        rel="noreferrer"
                    >
                        <img className="chrome-extension-delete" src={ChromeButton} alt="Remove Chrome Extension" />
                    </a>
                </div>
            </div>
        </ScrollBar>
    );
}
