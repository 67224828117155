import { useSetRecoilState, useRecoilValue } from 'recoil';
import { snackMessageState } from '../../_states/alert.states';
import { tr } from '../../common/locale';
import Icon from '../../components/Icon';
import { affiliateAccountState } from '../../_states/user.states';

function AffiliateOptions() {
    const affiliateAccount = useRecoilValue(affiliateAccountState);
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const link = `https://www.kanbox.io?c=${affiliateAccount?.code}`;
    const copy = () => {
        navigator.clipboard.writeText(link);
        setSnackMessage({ text: tr('Your affiliation link is copied! '), type: 'success' });
    };
    return (
        <div className="content__body affiliate-options">
            <div className="settings__header">
                <h2 className="title">{tr('Affiliation link & options')}</h2>
            </div>
            <div className="settings__content">
                <ul className="affiliate-options__menu">
                    {affiliateAccount?.url && (
                        <li>
                            <a
                                href={affiliateAccount?.url}
                                target="_blank"
                                className="btn btn--has-icon btn--secondary"
                                title={tr('My account')}
                                rel="noreferrer nofollow"
                            >
                                <Icon size={20} icon="padlock" />
                                <span className="btn__label">{tr('My account')}</span>
                            </a>
                        </li>
                    )}
                    <li>
                        <a
                            href="https://drive.google.com/drive/folders/1JaNnpfrGVK1DqtNYerEZ39Nsfq7f9F1d"
                            className="btn btn--has-icon btn--secondary"
                            title={tr('Brand Kit')}
                            target="_blank"
                            rel="noreferrer nofollow"
                        >
                            <Icon size={20} icon="picture" />
                            <span className="btn__label">{tr('Brand Kit')}</span>
                        </a>
                    </li>
                </ul>
                <div className="affiliate__link">
                    <Icon size={80} icon="link" />
                    <h1 className="title">{tr('Share this link with your audience')}</h1>
                    <p>{tr('Receive a 30% commission for each new affiliate customer.')}</p>
                    <div
                        className="affiliate__link__container"
                        tabIndex="-1"
                        role="button"
                        onKeyDown={copy}
                        onClick={copy}
                    >
                        <span className="link">{link}</span>
                        <span className="copy">Copy</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AffiliateOptions;
