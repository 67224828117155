import React, { Component } from 'react';
import cl from 'classnames';
import PropTypes from 'prop-types';

export default class Loader extends Component {
    constructor(props) {
        super(props);
        this.elementRef = React.createRef();
    }

    render() {
        const { isLarge, isSmall, isQuick, isChat, className, text, testId } = this.props;
        const classes = cl(
            'loader',
            { 'loader--quick': isQuick && isChat },
            { 'loader--small': isSmall && isChat },
            { 'loader--large': isLarge },
            { 'loader--chat': isChat },
            className
        );
        return (
            <div className="loader-wrapper">
                <span className={classes} data-testid={testId}>
                    <span className="loader-item" />
                </span>
                {text ? <span className="loader-text">{text}</span> : null}
            </div>
        );
    }
}

Loader.defaultProps = {
    isLarge: false,
    isChat: false,
    isQuick: false,
    className: null,
    text: null,
    testId: 'loader',
};

Loader.propTypes = {
    isLarge: PropTypes.bool,
    isChat: PropTypes.bool,
    isQuick: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string,
    testId: PropTypes.string,
};
