import React from 'react';
import ReactDOM from 'react-dom/client';
import RecoilNexus from 'recoil-nexus';
import TagManager from 'react-gtm-module';
import { RecoilRoot } from 'recoil';
import { RecoilURLSyncJSON } from 'recoil-sync';
import * as Sentry from '@sentry/react';
import App from './App';

const tagManagerArgs = {
    gtmId: 'GTM-KHVLCKCH',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
    Sentry.init({
        dsn: 'https://41093ec1914f43dd8d546da00cdf1c21@o4505201073455104.ingest.sentry.io/4505201074765824',
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

root.render(
    <RecoilRoot>
        <RecoilNexus />
        <RecoilURLSyncJSON location={{ part: 'queryParams' }}>
            <App />
        </RecoilURLSyncJSON>
    </RecoilRoot>
);
