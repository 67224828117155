import { useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import {
    lnUserState,
    notesState,
    noteState,
    sendingState,
    loadingNotesState,
    loadingLabelsState,
    loadingLnUserState,
    enrichLnUserState,
} from '../_states/lnuser.states';
import { connectionsState, filterConnectionsState, boardState } from '../_states/lnusers.states';
import useBoardActions from './board.actions';
import { tr } from '../common/locale';
import { snackMessageState } from '../_states/alert.states';
import useFetchWrapper from '../_helpers/fetchWrapper.helpers';
import useError from '../_helpers/errorsWrapper.helpers';
import utils from '../common/utils';

export default function useLnUserActions() {
    const boardActions = useBoardActions();
    const setLnUser = useSetRecoilState(lnUserState);
    const setConnections = useSetRecoilState(connectionsState);
    const setNote = useSetRecoilState(noteState);
    const setSending = useSetRecoilState(sendingState);
    const setNotes = useSetRecoilState(notesState);
    const setBoard = useSetRecoilState(boardState);
    const setLoadingLabels = useSetRecoilState(loadingLabelsState);
    const setLoadingNotes = useSetRecoilState(loadingNotesState);
    const setLoadingLnUser = useSetRecoilState(loadingLnUserState);
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const setEnrichLnUser = useSetRecoilState(enrichLnUserState);
    const fetchWrapper = useFetchWrapper();
    const { fetchNetworkError } = useError();

    function updateInBoard(lnUser) {
        const board = getRecoil(boardState);
        if (board) {
            const newBoard = JSON.parse(JSON.stringify(board));
            newBoard.lanes?.forEach((lane) => {
                const index = lane.cards?.findIndex((item) => item.id === lnUser.id);
                if (index !== -1) {
                    // eslint-disable-next-line no-param-reassign
                    lane.cards[index] = boardActions.lnUserToCard(lnUser);
                    setBoard(newBoard);
                }
            });
        }
    }

    function updateInConnections(lnusers) {
        const connections = getRecoil(connectionsState);
        if (connections) {
            const newConnections = { count: connections.count, items: [...connections.items] };
            let modified = false;
            lnusers?.forEach((lnuser) => {
                const index = newConnections.items.findIndex((item) => item.id === lnuser.id);
                if (index !== -1 && !utils.deepEqual(newConnections.items[index], lnuser)) {
                    newConnections.items[index] = lnuser;
                    modified = true;
                }
            });
            if (modified) {
                setConnections(newConnections);
            }
        }
    }

    function get(id, forceOpen) {
        setLoadingLnUser(true);
        const lnUser = getRecoil(lnUserState);
        return fetchWrapper
            .get(`lnusers/${id || lnUser.id}`)
            .then((data) => {
                const currentLnUser = getRecoil(lnUserState);
                setLoadingLnUser(false);
                if ((data && currentLnUser && currentLnUser.linkedin_id === data.linkedin_id) || forceOpen) {
                    setLnUser(data);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function enrich(id) {
        setEnrichLnUser(true);
        const lnUser = getRecoil(lnUserState);
        return fetchWrapper
            .post('lnusers/enrich', { ids: [id || lnUser.id] })
            .then(() => {
                get(id);
                setEnrichLnUser(false);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function addLabel(userId, labels, updateLnUser) {
        setLoadingLabels(userId);
        return fetchWrapper
            .patch(`lnusers/${userId}`, { labels })
            .then((data) => {
                const board = getRecoil(boardState);
                if (updateLnUser) {
                    const currentLnUser = getRecoil(lnUserState);
                    if (data && currentLnUser && currentLnUser.linkedin_id === data.linkedin_id) {
                        setLnUser(data);
                    }
                }
                updateInConnections([data]);
                setLoadingLabels(false);
                if (board && board.id === data.board) {
                    updateInBoard(data);
                }
            })
            .catch((e) => {
                setLoadingLabels(false);
                fetchNetworkError(null, e);
            });
    }

    function deleteLabel(userId, labels, updateLnUser) {
        setLoadingLabels(userId);
        return fetchWrapper
            .patch(`lnusers/${userId}`, { labels })
            .then((data) => {
                setLoadingLabels(false);
                updateInConnections([data]);
                if (updateLnUser) {
                    const currentLnUser = getRecoil(lnUserState);
                    if (data && currentLnUser && currentLnUser.linkedin_id === data.linkedin_id) {
                        setLnUser(data);
                    }
                }
                const board = getRecoil(boardState);
                if (board && board.id === data.board) {
                    updateInBoard(data);
                }
            })
            .catch((e) => {
                setLoadingLabels(false);
                fetchNetworkError(null, e);
            });
    }

    function sendWebhook(lnuser, webhookId) {
        setSending(true);
        return fetchWrapper
            .post(`lnusers/${lnuser.id}/webhooksend?webhook_id=${webhookId}`)
            .then((data) => {
                setSending(false);
                setSnackMessage({ text: tr('Webhook sent'), type: 'info' });
                setLnUser(data);
            })
            .catch(() => {
                setSending(false);
                setSnackMessage({ text: tr('Could not send webhook'), type: 'error' });
            });
    }

    function listNotes(paramLnUser) {
        setLoadingNotes(true);
        const lnUser = getRecoil(lnUserState);
        return fetchWrapper
            .get(`notes/lnusers/${paramLnUser?.id || lnUser.id}`)
            .then((data) => {
                setNotes(data);
                setLoadingNotes(false);
            })
            .catch((e) => {
                setLoadingNotes(false);
                fetchNetworkError(null, e);
            });
    }

    function addNote() {
        setLoadingNotes(true);
        const lnUser = getRecoil(lnUserState);
        const note = getRecoil(noteState);
        return fetchWrapper
            .post(`notes/lnusers/${lnUser.id}`, { content: note })
            .then(() => {
                listNotes();
                setNote('');
                const board = getRecoil(boardState);
                if (board && board.id === lnUser.board) {
                    const newLnUser = { ...lnUser };
                    newLnUser.notes_count += 1;
                    updateInBoard(newLnUser);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function deleteNote(item) {
        setLoadingNotes(true);
        const lnUser = getRecoil(lnUserState);
        return fetchWrapper
            .delete(`notes/lnusers/${lnUser.id}/${item.id}`)
            .then(() => {
                listNotes();
                const board = getRecoil(boardState);
                if (board && board.id === lnUser.board) {
                    const newLnUser = { ...lnUser };
                    newLnUser.notes_count += 1;
                    updateInBoard(newLnUser);
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function archiveUser(userId) {
        return fetchWrapper
            .post(`lnusers/${userId}/archive`)
            .then((data) => {
                const currentLnUser = getRecoil(lnUserState);
                if (data && currentLnUser && currentLnUser.linkedin_id === data.linkedin_id) {
                    setLnUser(data);
                }
                const connections = getRecoil(connectionsState);
                const filterConnections = getRecoil(filterConnectionsState);
                if (connections && filterConnections !== 'archived') {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const index = newConnections.items.findIndex((item) => item.id === userId);
                    if (index !== -1) {
                        newConnections.items.splice(index, 1);
                        setConnections(newConnections);
                    }
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function unarchiveUser(userId) {
        return fetchWrapper
            .post(`lnusers/${userId}/unarchive`)
            .then((data) => {
                const currentLnUser = getRecoil(lnUserState);
                if (data && currentLnUser && currentLnUser.linkedin_id === data.linkedin_id) {
                    setLnUser(data);
                }
                const filterConnections = getRecoil(filterConnectionsState);
                const connections = getRecoil(connectionsState);
                if (connections && filterConnections === 'archived') {
                    const newConnections = { count: connections.count, items: [...connections.items] };
                    const index = newConnections.items.findIndex((item) => item.id === userId);
                    if (index !== -1) {
                        newConnections.items.splice(index, 1);
                        setConnections(newConnections);
                    }
                }
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function updateNote(id, content) {
        setLoadingNotes(true);
        const lnUser = getRecoil(lnUserState);
        return fetchWrapper
            .put(`notes/lnusers/${lnUser.id}/${id}`, { content })
            .then(() => {
                listNotes();
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    function updateCustom(email, phone, icebreaker, custom) {
        const lnUser = getRecoil(lnUserState);
        return fetchWrapper
            .patch(`lnusers/${lnUser.id}`, { email, phone, icebreaker, custom })
            .then((data) => {
                setLnUser(data);
            })
            .catch((e) => fetchNetworkError(null, e));
    }

    return {
        enrich,
        sendWebhook,
        updateCustom,
        archiveUser,
        unarchiveUser,
        deleteLabel,
        addLabel,
        addNote,
        deleteNote,
        updateNote,
        listNotes,
        get,
    };
}
