import React, { Component } from 'react';
import { useLayer, Arrow } from 'react-laag';
import PropTypes from 'prop-types';
import Animate, { AnimatePresence } from '../common/Animate';

class Popover extends Component {
    toggle = () => {
        const { setOpen, isOpen } = this.props;
        setOpen(!isOpen);
    };

    close = () => {
        const { setOpen } = this.props;
        setOpen(false);
    };

    open = () => {
        const { setOpen } = this.props;
        setOpen(true);
    };

    render() {
        const {
            children,
            content,
            bounce,
            renderLayer,
            isOpen,
            layerProps,
            triggerProps,
            arrowProps,
            contentClassName,
            childClassName,
        } = this.props;
        const popOverContentClassname = contentClassName ? `popover__content ${contentClassName}` : 'popover__content';
        return (
            <>
                <div className={`popover__child ${childClassName}`} {...triggerProps}>
                    {children}
                </div>
                {renderLayer(
                    <AnimatePresence>
                        {isOpen && (
                            <Animate
                                bounce={bounce}
                                data-testid="popover-content"
                                className={popOverContentClassname}
                                layerProps={layerProps}
                            >
                                {content}
                                <Arrow
                                    borderColor="rgba(27, 31, 35, 0.15)"
                                    borderWidth={1}
                                    roundness={0.5}
                                    {...arrowProps}
                                />
                            </Animate>
                        )}
                    </AnimatePresence>
                )}
            </>
        );
    }
}

Popover.propTypes = {
    setOpen: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    renderLayer: PropTypes.func.isRequired,
    layerProps: PropTypes.object.isRequired,
    triggerProps: PropTypes.object.isRequired,
    arrowProps: PropTypes.object.isRequired,
    contentClassName: PropTypes.string,
    childClassName: PropTypes.string,
};

Popover.defaultProps = {
    contentClassName: undefined,
    childClassName: '',
};

function withLayer(MyComponent) {
    function WrappedComponent(props) {
        const [isOpen, setOpen] = React.useState(false);

        const { forwardedRef, container, placement, disableClickOutside, closeCb, ...rest } = props;
        function close() {
            setOpen(false);
            if (closeCb) {
                closeCb();
            }
        }
        const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
            isOpen,
            container,
            onOutsideClick: disableClickOutside ? null : close, // close the menu when the user clicks outside
            onDisappear: close, // close the menu when the menu gets scrolled out of sight
            overflowContainer: true, // keep the menu positioned inside the container
            auto: true, // automatically find the best placement
            placement: placement || 'bottom-center', // we prefer to place the menu "top-end"
            triggerOffset: 20, // keep some distance to the trigger
            containerOffset: 16, // give the menu some room to breath relative to the container
            arrowOffset: 16, // let the arrow have some room to breath also
        });
        return (
            <MyComponent
                {...rest}
                ref={forwardedRef}
                setOpen={setOpen}
                isOpen={isOpen}
                renderLayer={renderLayer}
                triggerProps={triggerProps}
                layerProps={layerProps}
                arrowProps={arrowProps}
            />
        );
    }
    WrappedComponent.defaultProps = {
        container: null,
        placement: 'bottom-center',
        disableClickOutside: false,
        closeCb: undefined,
        bounce: true,
    };
    WrappedComponent.propTypes = {
        forwardedRef: PropTypes.object.isRequired,
        container: PropTypes.string,
        placement: PropTypes.string,
        disableClickOutside: PropTypes.bool,
        closeCb: PropTypes.func,
        bounce: PropTypes.bool,
    };
    return React.forwardRef((props, ref) => <WrappedComponent {...props} forwardedRef={ref} />);
}

export default withLayer(Popover);
