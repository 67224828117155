import { useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { tr } from '../../common/locale';
import Button from '../../components/Button';
import Popover from '../../components/Popover';
import { notesState } from '../../_states/lnusers.states';
import utils from '../../common/utils';
import useLnusersActions from '../../_actions/lnusers.actions';

function PopoverNote({ lnUser }) {
    const actions = useLnusersActions();
    const [notes, setNotes] = useRecoilState(notesState);
    const notesRef = useRef();
    const [showNote, setShowNote] = useState(false);
    const renderNoteContent = () =>
        notes && notes.length > 0 ? `${tr('Note:')} ${utils.nl2br(notes[0].content)}` : tr('Loading note...');
    useEffect(() => {
        if (showNote) {
            notesRef?.current?.open();
            actions.listNotes(showNote);
        } else {
            notesRef?.current?.close();
            setNotes(null);
        }
    }, [showNote]);
    return (
        <Popover childClassName="card-notes" ref={notesRef} content={renderNoteContent()}>
            <Button
                icon="sticker"
                className="card__note"
                iconSize={16}
                type="link"
                onMouseEnter={(e) => {
                    e.stopPropagation();
                    setShowNote(lnUser);
                }}
                onMouseLeave={(e) => {
                    e.stopPropagation();
                    setShowNote(false);
                }}
            />
        </Popover>
    );
}
export default PopoverNote;
