import React, { useRef, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { tr } from '../../common/locale';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import utils from '../../common/utils';
import Input from '../../components/Input';
import { lnUserState } from '../../_states/lnuser.states';
import useLnUserActions from '../../_actions/lnuser.actions';

function LnUserCustomInfo() {
    const lnUser = useRecoilValue(lnUserState);
    const [edit, setEdit] = useState(false);
    const [email, setEmail] = useState(lnUser?.email || '');
    const [phone, setPhone] = useState(lnUser?.phone || '');
    const [icebreaker, setIcebreaker] = useState(lnUser?.icebreaker || '');
    const [custom, setCustom] = useState(lnUser?.custom || '');
    const editRef = useRef();
    const actions = useLnUserActions();
    const hasCustom = lnUser?.email || lnUser?.icebreaker || lnUser?.phone || lnUser?.custom;
    useEffect(() => {
        if (edit) {
            editRef?.current.open();
        } else {
            editRef?.current.close();
        }
    }, [edit]);
    useEffect(() => {
        if (editRef?.current.state.isLoading && lnUser) {
            setEdit(false);
        }
        if (lnUser) {
            setEmail(lnUser?.email);
            setPhone(lnUser?.phone);
            setIcebreaker(lnUser?.icebreaker);
            setCustom(lnUser?.custom);
        }
    }, [lnUser]);
    return (
        <>
            <Modal
                className="lnuser__custom-info-modal"
                ref={editRef}
                validateCb={() => actions.updateCustom(email, phone, icebreaker, custom)}
                closeCb={() => setEdit(false)}
                title={hasCustom ? tr('Edit custom information') : tr('Add custom information')}
                labelValidate={tr('Save')}
            >
                <Input
                    label={tr('Email')}
                    placeholder={tr('Email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    label={tr('Phone')}
                    placeholder={tr('Phone')}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <Input
                    type="textarea"
                    placeholder={tr('Icebreaker')}
                    label={tr('Icebreaker')}
                    value={icebreaker}
                    maxlength="1000"
                    onChange={(e) => setIcebreaker(e.target.value)}
                />
                <Input
                    label={tr('Other information')}
                    placeholder={tr('Other information')}
                    type="textarea"
                    value={custom}
                    maxlength="1000"
                    onChange={(e) => setCustom(e.target.value)}
                />
            </Modal>
            {!hasCustom && (
                <div className="lnuser__user__section">
                    <Button
                        className="mx--auto"
                        icon="plus"
                        type="link-primary"
                        label={tr('Add custom information')}
                        onClick={() => setEdit(true)}
                    />
                </div>
            )}
            {hasCustom && (
                <div className="lnuser__user__custom-info">
                    <h5>{tr('Add info')}</h5>
                    {lnUser?.email && (
                        <div className="lnuser__user__email" title={tr('Email address')}>
                            <span>{lnUser?.email}</span>
                        </div>
                    )}
                    {lnUser?.phone && (
                        <div className="lnuser__user__phone" title={tr('Phone number')}>
                            <span>{lnUser?.phone}</span>
                        </div>
                    )}
                    {lnUser?.icebreaker && (
                        <div className="lnuser__user__icebreaker" title={tr('Icebreaker')}>
                            <span>{utils.nl2br(lnUser?.icebreaker)}</span>
                        </div>
                    )}
                    {lnUser?.custom && (
                        <div className="lnuser__user__custom" title={tr('Other info')}>
                            <span>{utils.nl2br(lnUser?.custom)}</span>
                        </div>
                    )}
                    {hasCustom && (
                        <div className="lnuser__user__section">
                            <Button
                                className="mx--auto"
                                icon="pen"
                                type="link-primary"
                                label={tr('Edit')}
                                onClick={() => setEdit(true)}
                            />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default LnUserCustomInfo;
