import React, { useRef } from 'react';
import ReactEmojiPicker from 'emoji-picker-react';
import Button from '../../components/Button';
import Popover from '../../components/Popover';

function EmojiPicker({
    onEmojiClick,
    btnIcon,
    btnClassName,
    popoverClassName,
    btnIconSize,
    btnSize,
    btnType,
    isRound,
    placement,
}) {
    const popoverEmojiRef = useRef();

    return (
        <Popover
            bounce={false}
            ref={popoverEmojiRef}
            placement={placement}
            contentClassName={popoverClassName || 'popover__content__emoji'}
            content={
                <ReactEmojiPicker
                    previewConfig={{ showPreview: false }}
                    onEmojiClick={(emojiData) => {
                        popoverEmojiRef.current.toggle();
                        onEmojiClick(emojiData);
                    }}
                    autoFocusSearch={false}
                    skinTonesDisabled
                    emojiStyle="twitter"
                />
            }
        >
            <Button
                className={btnClassName || 'emoji'}
                type={btnType || 'link'}
                size={btnSize || undefined}
                icon={btnIcon || 'emoji'}
                iconSize={btnIconSize || 24}
                onClick={() => popoverEmojiRef.current.toggle()}
                isRound={isRound}
            />
        </Popover>
    );
}
export default EmojiPicker;
