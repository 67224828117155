import { useRecoilState, useRecoilValue } from 'recoil';
import Select, { components } from 'react-select';
import { tr } from '../../common/locale';
import { campaignState } from '../../_states/campaign.states';
import Label from '../../components/Label';
import { Colors } from '../lnuser/LabelsList';
import Input from '../../components/Input';
import { labelsState } from '../../_states/label.states';
import Move from '../../images/automations/move-column.webp';
import LabelImg from '../../images/automations/add-label.webp';

const LabelOption = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <Label
                noBg
                key={data.id}
                label={data.name}
                color={Colors[data.color].light}
                iconColor={Colors[data.color].dark}
            />
        </components.Option>
    );
};

function CampaignPipeline({ getStepIndex }) {
    const [campaign, setCampaign] = useRecoilState(campaignState);
    const labels = useRecoilValue(labelsState);
    const getAction = (column) => {
        const result = [];
        if (column === 'recipient_from_step_name') {
            if (campaign.action_visit) {
                return [
                    {
                        label: tr('Visit profile'),
                        id: 'action_visit_step_name',
                        color: 'blue',
                        index: getStepIndex('action_visit'),
                    },
                ];
            }
        }
        if (column === 'recipient_from_step_name' || column === 'action_visit_step_name') {
            if (campaign.action_follow) {
                return [
                    {
                        label: tr('Follow member'),
                        id: 'action_follow_step_name',
                        color: 'blue',
                        index: getStepIndex('action_follow'),
                    },
                ];
            }
        }
        if (
            column === 'recipient_from_step_name' ||
            column === 'action_visit_step_name' ||
            column === 'action_follow_step_name'
        ) {
            if (campaign.action_like) {
                return [
                    {
                        label: tr("Like member's post"),
                        id: 'action_like_step_name',
                        color: 'blue',
                        index: getStepIndex('action_like'),
                    },
                ];
            }
        }
        if (
            column === 'recipient_from_step_name' ||
            column === 'action_visit_step_name' ||
            column === 'action_follow_step_name' ||
            column === 'action_like_step_name'
        ) {
            if (campaign.action_connect) {
                return [
                    {
                        label: tr('Connection requests'),
                        id: 'action_connect_step_requested_name',
                        color: 'blue',
                        index: getStepIndex('action_connect'),
                    },
                ];
            }
        }
        if (column === 'action_connect_step_requested_name') {
            result.push({
                label: tr('Request accepted'),
                id: 'action_connect_step_accepted_name',
                idLabels: 'action_connect_labels',
                color: 'purple',
                index: getStepIndex('action_connect') + 1,
            });
            result.push({
                label: tr('Request answered'),
                id: 'action_answered_step_name',
                idLabels: 'action_connect_answered_labels',
                color: 'green',
                index: getStepIndex('action_connect') + 2,
            });
            if (campaign.action_connect_ignore_delete) {
                result.push({
                    label: tr('Request ignored after {{count}} days', { count: campaign.action_connect_ignore_days }),
                    id: 'action_connect_step_ignored_name',
                    color: 'red',
                    index: getStepIndex('action_connect') + 3,
                });
            }

            return result;
        }
        if (
            column === 'recipient_from_step_name' ||
            column === 'action_visit_step_name' ||
            column === 'action_follow_step_name' ||
            column === 'action_connect_step_accepted_name'
        ) {
            if (campaign.action_message_1) {
                return [
                    {
                        label: tr('Send message'),
                        id: 'action_message_1_step_sent_name',
                        color: 'blue',
                        index: getStepIndex('action_message_1'),
                    },
                ];
            }
        }
        if (column === 'action_message_1_step_sent_name') {
            result.push({
                label: tr('Message answered'),
                id: 'action_answered_step_name',
                color: 'green',
                idLabels: 'action_message_1_labels',
                index: getStepIndex('action_message_1') + 1,
            });
            if (campaign.action_message_2) {
                result.push({
                    label: tr('Follow-up message 1 after {{count}} days', {
                        count: campaign.action_message_1_ignore_days,
                    }),
                    id: 'action_message_2_step_sent_name',
                    color: 'blue',
                    index: getStepIndex('action_message_2'),
                });
            }
            return result;
        }
        if (column === 'action_message_2_step_sent_name') {
            result.push({
                label: tr('Message answered'),
                id: 'action_answered_step_name',
                color: 'green',
                idLabels: 'action_message_2_labels',
                index: getStepIndex('action_message_2') + 1,
            });
            if (campaign.action_message_3) {
                result.push({
                    label: tr('Follow-up message 2 after {{count}} days', {
                        count: campaign.action_message_2_ignore_days,
                    }),
                    id: 'action_message_3_step_sent_name',
                    color: 'blue',
                    index: getStepIndex('action_message_3'),
                });
            }
            return result;
        }
        if (column === 'action_message_3_step_sent_name') {
            result.push({
                label: tr('Message answered'),
                id: 'action_answered_step_name',
                color: 'green',
                idLabels: 'action_message_3_labels',
                index: getStepIndex('action_message_3') + 1,
            });
            if (campaign.action_message_4) {
                result.push({
                    label: tr('Follow-up message 3 after {{count}} days', {
                        count: campaign.action_message_3_ignore_days,
                    }),
                    id: 'action_message_4_step_sent_name',
                    color: 'blue',
                    index: getStepIndex('action_message_4'),
                });
            }
            return result;
        }
        if (column === 'action_message_4_step_sent_name') {
            result.push({
                label: tr('Message answered'),
                id: 'action_answered_step_name',
                color: 'green',
                idLabels: 'action_message_4_labels',
                index: getStepIndex('action_message_4') + 1,
            });
            return result;
        }

        return [];
    };
    const getCard = (field, content) => {
        const actions = getAction(field);
        return (
            <div className="column">
                <h5 className="info">{tr('Column')}</h5>
                <Input
                    disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                    value={campaign[field]}
                    placeholder={tr('Type in the pipeline name')}
                    onChange={(e) => {
                        const newC = { ...campaign };
                        newC[field] = e.target.value;
                        setCampaign(newC);
                    }}
                />

                <div>{content}</div>
                {actions.map((action) => (
                    <div className={`action action-${action.color}`} key={action.id}>
                        <div className={`autom-step step-${action.color}`}>{action.index}</div>
                        <div className="type">{action.label}</div>

                        <div className="move">
                            <div className="move-header">
                                <img src={Move} alt={tr('And move to column')} />
                                <span>{tr('And move to column')}</span>
                            </div>
                            <Input
                                type="text"
                                disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                                value={campaign[action.id]}
                                onChange={(e) => {
                                    const newCamp = { ...campaign };
                                    newCamp[action.id] = e.target.value;
                                    setCampaign(newCamp);
                                }}
                            />
                        </div>

                        {action && campaign[action.idLabels] && (
                            <div className="lbl">
                                <div className="lbl-header">
                                    <img src={LabelImg} alt={tr('And add label')} />
                                    <span>{tr('And add label')}</span>
                                </div>

                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    isDisabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={(selected) => {
                                        const newCamp = { ...campaign };
                                        newCamp[action.idLabels] = selected.map((item) => item.value);
                                        setCampaign(newCamp);
                                    }}
                                    components={{ Option: LabelOption }}
                                    options={labels?.map((label) => ({
                                        value: label.id,
                                        name: label.name,
                                        color: label.color,
                                        label: label.name,
                                    }))}
                                    defaultValue={
                                        campaign &&
                                        campaign[action.idLabels].map((current) => {
                                            const label = labels.find((item) => item.id === current);
                                            return label
                                                ? {
                                                      value: label.id,
                                                      name: label.name,
                                                      color: label.color,
                                                      label: label.name,
                                                  }
                                                : null;
                                        })
                                    }
                                    styles={{
                                        multiValue: (styles, { data }) => ({
                                            ...styles,
                                            backgroundColor: Colors[data.color].light,
                                        }),
                                    }}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    };
    return (
        <div className="campaign__config pipeline">
            <h1 className="title">{tr('Auto-generated Pipeline')}</h1>
            <div className="autom">
                <div className="autom-line stepped">
                    <div className="autom-desc">
                        {tr('A new pipeline will be automatically created when the campaign is launched.')}
                    </div>
                </div>
                <div className="autom-line stepped">
                    <Input
                        label={tr('Give a name to the pipeline')}
                        value={campaign.board_name}
                        disabled={campaign?.status === 'ARCHIVED' || campaign?.status === 'FINISHED'}
                        placeholder={tr('Type in the pipeline name')}
                        onChange={(e) => setCampaign({ ...campaign, board_name: e.target.value })}
                    />
                </div>
            </div>
            <div className="campaign__config-wrapper for-pipelines">
                {(campaign.recipient_from_list_id ||
                    campaign.recipient_from === 'connections' ||
                    campaign.recipient_from === 'contacts') &&
                    getCard('recipient_from_step_name')}
                {(campaign.action_connect ||
                    campaign.action_message_1 ||
                    campaign.action_message_2 ||
                    campaign.action_message_3 ||
                    campaign.action_message_4) &&
                    getCard('action_answered_step_name')}
                {campaign.action_visit && getCard('action_visit_step_name')}
                {campaign.action_follow && getCard('action_follow_step_name')}
                {campaign.action_like && getCard('action_like_step_name')}
                {campaign.action_connect && getCard('action_connect_step_requested_name')}
                {campaign.action_connect && getCard('action_connect_step_accepted_name')}
                {campaign.action_connect &&
                    campaign.action_connect_ignore_delete &&
                    getCard('action_connect_step_ignored_name')}
                {campaign.action_message_1 && getCard('action_message_1_step_sent_name')}
                {campaign.action_message_2 && getCard('action_message_2_step_sent_name')}
                {campaign.action_message_3 && getCard('action_message_3_step_sent_name')}
                {campaign.action_message_4 && getCard('action_message_4_step_sent_name')}
                {(campaign.action_connect ||
                    campaign.action_message_1 ||
                    campaign.action_message_2 ||
                    campaign.action_message_3 ||
                    campaign.action_message_4) &&
                    getCard('action_error_step_name')}
            </div>
        </div>
    );
}
export default CampaignPipeline;
