import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

export default class Animate extends Component {
    render() {
        const { children, bounce, layerProps, ...rest } = this.props;
        return (
            <motion.div
                initial={{ opacity: 0, scale: bounce ? 0.3 : 0.3 }} // animate from
                animate={{ opacity: 1, scale: 1 }} // animate to
                exit={{ opacity: 0, scale: 0.85 }} // animate exit
                transition={{
                    type: 'spring',
                    stiffness: 800,
                    damping: 35,
                }}
                {...layerProps}
                {...rest}
            >
                {children}
            </motion.div>
        );
    }
}

Animate.defaultProps = {
    layerProps: {},
};

Animate.propTypes = {
    children: PropTypes.node.isRequired,
    layerProps: PropTypes.object,
};

export { AnimatePresence };
