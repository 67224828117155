import { atom } from 'recoil';

export const webhooksState = atom({
    key: 'webhooksState',
    default: null,
});

export const sendingState = atom({
    key: 'sendingWebhookState',
    default: false,
});

export const loadingState = atom({
    key: 'webhooksLoadingState',
    default: false,
});

export const creatingState = atom({
    key: 'creatingState',
    default: false,
});

export const addingboardState = atom({
    key: 'addingboardState',
    default: false,
});

export const removingboardState = atom({
    key: 'removingboardState',
    default: false,
});
