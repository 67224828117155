import { useSetRecoilState, useResetRecoilState } from 'recoil';

import { alertState } from '../_states/alert.states';

export default function useAlertActions() {
    const setAlert = useSetRecoilState(alertState);
    const resetAlert = useResetRecoilState(alertState);

    return {
        success: (message) => setAlert({ message, type: 'success' }),
        error: (message) => {
            setAlert({ message, type: 'error' });
        },
        warn: (message) => {
            setAlert({ message, type: 'warning' });
        },
        info: (message) => {
            setAlert({ message, type: 'info' });
        },
        clear: resetAlert,
    };
}
