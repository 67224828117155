import { useRef, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tr } from '../../common/locale';
import Button from '../../components/Button';

import Popover from '../../components/Popover';
import {
    bulkSendProgressState,
    bulkInviteProgressState,
    bulkAcceptInviteProgressState,
    bulkSyncProgressState,
    bulkLabelProgressState,
    bulkBoardProgressState,
    bulkArchiveProgressState,
    bulkProcessLeadsProgressState,
    bulkDisconnectProgressState,
    bulkUnreadProgressState,
    bulkHideProgressState,
} from '../../_states/lnusers.states';
import { snackMessageState } from '../../_states/alert.states';

function BulkStatus() {
    const ref = useRef();
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const bulkSendProgress = useRecoilValue(bulkSendProgressState);
    const bulkInviteProgress = useRecoilValue(bulkInviteProgressState);
    const bulkAcceptInviteProgress = useRecoilValue(bulkAcceptInviteProgressState);
    const bulkHideProgress = useRecoilValue(bulkHideProgressState);
    const bulkSyncProgress = useRecoilValue(bulkSyncProgressState);
    const bulkLabelProgress = useRecoilValue(bulkLabelProgressState);
    const bulkBoardProgress = useRecoilValue(bulkBoardProgressState);
    const bulkProcessLeadsProgress = useRecoilValue(bulkProcessLeadsProgressState);
    const bulkArchiveProgress = useRecoilValue(bulkArchiveProgressState);
    const bulkUnreadProgress = useRecoilValue(bulkUnreadProgressState);
    const bulkDisconnectProgress = useRecoilValue(bulkDisconnectProgressState);
    useEffect(() => {
        if (bulkSendProgress === 'done') {
            setSnackMessage({ text: tr('Messages sent!'), type: 'info' });
        }
    }, [bulkSendProgress]);
    useEffect(() => {
        if (bulkInviteProgress === 'done') {
            setSnackMessage({ text: tr('Connection requests sent!'), type: 'info' });
        }
    }, [bulkInviteProgress]);
    useEffect(() => {
        if (bulkSyncProgress === 'done') {
            setSnackMessage({ text: tr('Profiles synchronized!'), type: 'info' });
        }
    }, [bulkSyncProgress]);
    useEffect(() => {
        if (bulkAcceptInviteProgress === 'done') {
            setSnackMessage({ text: tr('Connection requests updated!'), type: 'info' });
        }
    }, [bulkSyncProgress]);
    useEffect(() => {
        if (bulkLabelProgress === 'done') {
            setSnackMessage({ text: tr('Labels updated!'), type: 'info' });
        }
    }, [bulkLabelProgress]);
    useEffect(() => {
        if (bulkBoardProgress === 'done') {
            setSnackMessage({ text: tr('Members processed!'), type: 'info' });
        }
    }, [bulkBoardProgress]);
    useEffect(() => {
        if (bulkArchiveProgress === 'done') {
            setSnackMessage({ text: tr('Conversations moved!'), type: 'info' });
        }
    }, [bulkArchiveProgress]);
    useEffect(() => {
        if (bulkUnreadProgress === 'done') {
            setSnackMessage({ text: tr('Conversations mark as unread!'), type: 'info' });
        }
    }, [bulkUnreadProgress]);
    useEffect(() => {
        if (bulkDisconnectProgress === 'done') {
            setSnackMessage({ text: tr('Connections removed!'), type: 'info' });
        }
    }, [bulkDisconnectProgress]);
    useEffect(() => {
        if (bulkProcessLeadsProgress === 'done') {
            setSnackMessage({ text: tr('Members archived!'), type: 'info' });
        }
    }, [bulkArchiveProgress]);
    useEffect(() => {
        if (bulkHideProgress === 'done') {
            setSnackMessage({ text: tr('Members visibility updated!'), type: 'info' });
        }
    }, [bulkArchiveProgress]);
    const show =
        bulkSendProgress ||
        bulkInviteProgress ||
        bulkArchiveProgress ||
        bulkUnreadProgress ||
        bulkDisconnectProgress ||
        bulkSyncProgress ||
        bulkLabelProgress ||
        bulkBoardProgress;
    const renderContent = () => (
        <ul>
            {bulkSendProgress && bulkSendProgress !== 'done' && (
                <li>
                    {tr('Sending messages in progress: ')}
                    <strong>
                        {tr('{{count}} message sent', {
                            count: bulkSendProgress,
                        })}
                    </strong>
                </li>
            )}
            {bulkInviteProgress && bulkInviteProgress !== 'done' && (
                <li>
                    {tr('Sending connection requests in progress: ')}
                    <strong>
                        {tr('{{count}} connection request sent', {
                            count: bulkInviteProgress,
                        })}
                    </strong>
                </li>
            )}
            {bulkAcceptInviteProgress && bulkAcceptInviteProgress !== 'done' && (
                <li>
                    {tr('Updating connection requests in progress: ')}
                    <strong>
                        {tr('{{count}} connection request sent', {
                            count: bulkInviteProgress,
                        })}
                    </strong>
                </li>
            )}
            {bulkSyncProgress && bulkSyncProgress !== 'done' && (
                <li>
                    {tr('Synchronizing profiles in progress: ')}
                    <strong>
                        {tr('{{count}} profile synchronized', {
                            count: bulkSyncProgress,
                        })}
                    </strong>
                </li>
            )}
            {bulkLabelProgress && bulkLabelProgress !== 'done' && (
                <li>
                    {tr('Updating labels in progress: ')}
                    <strong>
                        {tr('{{count}} members updated', {
                            count: bulkLabelProgress,
                        })}
                    </strong>
                </li>
            )}
            {bulkBoardProgress && bulkBoardProgress !== 'done' && (
                <li>
                    {tr('Adding members to Pipeline in progress: ')}
                    <strong>
                        {tr('{{count}} members updated', {
                            count: bulkBoardProgress,
                        })}
                    </strong>
                </li>
            )}
            {bulkArchiveProgress && bulkArchiveProgress !== 'done' && (
                <li>
                    {tr('Archiving conversations: ')}
                    <strong>
                        {tr('{{count}} conversations moved', {
                            count: bulkArchiveProgress,
                        })}
                    </strong>
                </li>
            )}
            {bulkUnreadProgress && bulkUnreadProgress !== 'done' && (
                <li>
                    {tr('Marking as read / unread: ')}
                    <strong>
                        {tr('{{count}} conversations marked', {
                            count: bulkUnreadProgress,
                        })}
                    </strong>
                </li>
            )}
            {bulkDisconnectProgress && bulkDisconnectProgress !== 'done' && (
                <li>
                    {tr('Removing connections: ')}
                    <strong>
                        {tr('{{count}} connections removed', {
                            count: bulkDisconnectProgress,
                        })}
                    </strong>
                </li>
            )}
        </ul>
    );
    if (!show) {
        return null;
    }
    return (
        <div className="list__filter--actions__btn">
            <Popover ref={ref} content={renderContent()}>
                <Button
                    className="btn--processing"
                    type="bulk"
                    iconSize={20}
                    icon="cog-alt"
                    onClick={(e) => {
                        e.stopPropagation();
                        ref?.current?.toggle();
                    }}
                />
            </Popover>
        </div>
    );
}
export default BulkStatus;
