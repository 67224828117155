import { useSetRecoilState } from 'recoil';
import { authState } from '../_states/user.states';
import history from './history.helpers';
import ServiceErrors from '../common/ServiceErrors';
import useError from './errorsWrapper.helpers';
import { tr } from '../common/locale';

const baseUrl = `${process.env.REACT_APP_API_URL}/`;

export default function useFetchWrapper() {
    const { fetchNetworkError, clearNetworkError } = useError();
    const setAuth = useSetRecoilState(authState);
    function handleResponse(response, data) {
        if (!response.ok) {
            let error = (data && data.detail) || response.statusText;
            if (response.status === 429) {
                error = 'Too Many Requests';
            }
            if (response.status >= 500 && response.status < 600) {
                fetchNetworkError(null, null, ServiceErrors.backend());
            }
            return Promise.reject(error);
        }

        return data;
    }

    function sendExtensionMessage(req, signal) {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-undef
            chrome.runtime.sendMessage(`${process.env.REACT_APP_CHROME_EXTENSION_ID}`, req, (response) => {
                if (response) {
                    resolve(response);
                } else {
                    reject(response);
                }
            });
            if (signal) {
                signal.addEventListener('abort', () => {
                    reject(new Error('Promise aborted'));
                });
            }
        });
    }

    function getAuth() {
        if (localStorage.getItem('auth')) {
            return JSON.parse(localStorage.getItem('auth'));
        }
        return {};
    }
    function authHeader() {
        const { access } = getAuth();
        const isLoggedIn = !!access;
        if (isLoggedIn) {
            return { Authorization: `Bearer ${access}` };
        }
        return {};
    }
    function request(method) {
        return (url, body, options) => {
            let requestOptions = {
                method,
                headers: authHeader(),
            };
            if (options) {
                requestOptions = { ...requestOptions, ...options };
            } else if (body) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }

            clearNetworkError();
            return fetch(`${baseUrl}${url}`, requestOptions).then((response) =>
                response.text().then((text) => {
                    let data = tr('An error has occured');
                    try {
                        data = text && JSON.parse(text);
                    } catch (e) {
                        // do nothing
                    }
                    if (!response.ok && [401].includes(response.status) && url.indexOf('auth/') === -1) {
                        if (getAuth().refresh) {
                            return fetch(`${baseUrl}auth/refreshtoken`, {
                                method: 'POST',
                                body: JSON.stringify({ refresh: getAuth().refresh }),
                            }).then((resp) => {
                                if (resp.ok) {
                                    return resp.json().then((refreshResp) => {
                                        localStorage.setItem('auth', JSON.stringify(refreshResp));
                                        setAuth(refreshResp);
                                        return request(method)(url, body);
                                    });
                                }
                                const error = (data && data.detail) || resp.statusText;
                                localStorage.removeItem('auth');
                                setAuth(null);
                                return Promise.reject(error);
                            });
                        }
                        const errorResp = (data && data.detail) || response.statusText;
                        localStorage.removeItem('auth');
                        setAuth(null);
                        history.push('/login');
                        return Promise.reject(errorResp);
                    }
                    return handleResponse(response, data);
                })
            );
        };
    }

    function upload(file) {
        const formData = new FormData();
        return fetch(file)
            .then((res) => res.blob())
            .then((blob) => {
                formData.append('file', blob, 'avatar.png');
                const requestOptions = {
                    body: formData,
                };
                return request('POST')('upload', null, requestOptions);
            });
    }
    function download(url, name) {
        const requestOptions = {
            headers: authHeader(),
        };
        return fetch(`${baseUrl}${url}`, requestOptions)
            .then((res) => res.blob())
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = file;
                a.download = name;
                a.click();
            });
    }
    return {
        sendExtensionMessage,
        getAuth,
        upload,
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        patch: request('PATCH'),
        delete: request('DELETE'),
        download,
    };
}
