import { atom } from 'recoil';

export const labelsState = atom({
    key: 'labelsState',
    default: null,
});

export const loadingState = atom({
    key: 'labelsLoadingState',
    default: false,
});

export const toggleIconState = atom({
    key: 'toggleIconState',
    default: false,
});
