import { useSetRecoilState } from 'recoil';
import React from 'react';
import { tr } from '../../common/locale';

import Icon from '../../components/Icon';

import { lnUserState } from '../../_states/lnuser.states';
import TooltipPopover from '../../components/TooltipPopover';
import Input from '../../components/Input';
import { upgradeState } from '../../_states/user.states';

function LeadLine({ disableActions, lead, index, setSelectedLead, selectLeadCb, isSelected, isFull }) {
    const setLnUser = useSetRecoilState(lnUserState);
    const setUpgrade = useSetRecoilState(upgradeState);
    const renderCheckbox = () => {
        const div = (
            <div
                className="list__col list__col--checkbox"
                onClick={(e) => selectLeadCb(e, lead)}
                onMouseDown={(e) => e.preventDefault()}
                onKeyDown={() => null}
                role="button"
                tabIndex={0}
                title={index === 0 ? '' : tr('Use Shift key to select multiple members at once')}
            >
                <Input type="checkbox" stopPropagation checked={isSelected} onChange={(e) => selectLeadCb(e, lead)} />
            </div>
        );

        return div;
    };

    return (
        <div className="list__item" key={lead.id}>
            <div
                className={`list__row ${lead?.lnuser ? 'list__row--leadnetwork' : ''} ${
                    lead?.is_used_team ? 'list__row--already-used' : ''
                } ${lead?.is_used_anothersearch ? 'list__row--duplicated' : ''}`}
                tabIndex={index}
                role="button"
                onClick={() => {
                    if (disableActions) {
                        setUpgrade(true);
                    } else if (lead.lnuser) {
                        setLnUser(lead.lnuser);
                    } else {
                        setSelectedLead(lead);
                    }
                }}
                onKeyDown={() => null}
            >
                <div className="list__cols">
                    {!isFull && <div className="list__col list__col--spacer" />}
                    {!isFull && renderCheckbox()}

                    {!isFull && (
                        <div
                            className="list__col list__col--large name bold flex"
                            title={`${lead.firstname_cleaned} ${lead.lastname_cleaned}`}
                        >
                            {lead?.is_used_anothersearch && (
                                <TooltipPopover
                                    text={tr('Duplicated member, already existing in one or multiple lists.')}
                                >
                                    <Icon icon="copy" size={16} className="mr--5" />
                                </TooltipPopover>
                            )}
                            {lead?.lnuser?.is_lead && (
                                <TooltipPopover text={tr('Added to my Inbox / Pipeline')}>
                                    <Icon icon="inbox" size={16} className="mr--5" />
                                </TooltipPopover>
                            )}
                            {lead?.lnuser && !lead?.lnuser?.is_lead && (
                                <TooltipPopover text={tr('Already in my Inbox / Pipeline')}>
                                    <Icon icon="inbox" size={16} className="mr--5" />
                                </TooltipPopover>
                            )}
                            {lead?.is_used_team && (
                                <TooltipPopover text={tr('Already in an Inbox / Pipeline of my teammates')}>
                                    <Icon icon="company" size={16} className="mr--5" />
                                </TooltipPopover>
                            )}

                            <span className="ellipsis">
                                {lead.firstname_cleaned} {lead.lastname_cleaned}
                            </span>
                        </div>
                    )}
                    {isFull && (
                        <>
                            <div className="list__col list__col--large col-job target" title={lead.job}>
                                {lead.job}
                            </div>
                            <div
                                className={`list__col list__col--large col-email ${
                                    lead.is_search_enrich && lead.dropcontact_request_completed
                                        ? 'enriched-email-col'
                                        : ''
                                }`}
                                title={
                                    (((lead.is_search_enrich && lead.dropcontact_request_completed) ||
                                        !lead.dropcontact_request_completed) &&
                                        lead.email_enrich) ||
                                    (lead.is_search_enrich ? tr('Not found') : tr('Not provided'))
                                }
                            >
                                {(lead.email_enrich &&
                                    ((lead.is_search_enrich && lead.dropcontact_request_completed) ||
                                        !lead.dropcontact_request_completed) && (
                                        <>
                                            {lead.dropcontact_request_completed && (
                                                <span className="enriched-profile">
                                                    <TooltipPopover text={tr('Email enriched')}>
                                                        <Icon size={9} title="Email enriched" icon="check" />
                                                    </TooltipPopover>
                                                </span>
                                            )}
                                            <span className="ellipsis">{lead.email_enrich}</span>
                                        </>
                                    )) || (
                                    <span
                                        className={
                                            // eslint-disable-next-line no-nested-ternary
                                            lead.is_search_enrich
                                                ? !lead.dropcontact_request || lead.dropcontact_request_completed
                                                    ? 'not-found'
                                                    : 'searching'
                                                : 'not-provided'
                                        }
                                    >
                                        {
                                            // eslint-disable-next-line no-nested-ternary
                                            lead.is_search_enrich
                                                ? !lead.dropcontact_request || lead.dropcontact_request_completed
                                                    ? tr('Not found')
                                                    : tr('Searching...')
                                                : tr('Not provided')
                                        }
                                    </span>
                                )}
                            </div>
                            <div className="list__col list__col--large col-email target" title={lead.email}>
                                {lead.email || lead.uploaded_email || '-'}
                            </div>
                            <div className="list__col list__col--fill normal col-headline" title={lead.headline}>
                                {lead.headline}
                            </div>
                            <div className="list__col list__col--large col-company" title={lead.company}>
                                {lead.company}
                            </div>
                            <div className="list__col list__col--large col-company" title={lead.company_industry}>
                                {lead.company_industry}
                            </div>
                            <div className="list__col list__col--small list__col--center">{lead.connections}</div>
                            <div className="list__col list__col--small list__col--center">
                                {lead.is_open_profile ? (
                                    <span className="badge badge--round badge--nano badge--blue-dark" />
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="list__col list__col--mini list__col--center">
                                {lead?.is_match ? (
                                    <Icon className="match" size={9} icon="check" />
                                ) : (
                                    <Icon className="not-match" size={9} icon="close" />
                                )}
                            </div>
                            <div className="list__col list__col--hidden-action" />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

function areEqual(prevProps, nextProps) {
    if (prevProps.isSelected !== nextProps.isSelected) {
        return false;
    }
    if (prevProps.lead.email !== nextProps.lead.email) {
        return false;
    }
    if (prevProps.lead.is_used_anothersearch !== nextProps.lead.is_used_anothersearch) {
        return false;
    }
    if (prevProps.lead.is_used_team !== nextProps.lead.is_used_team) {
        return false;
    }
    if (prevProps.lead.is_match !== nextProps.lead.is_match) {
        return false;
    }
    if (prevProps.lead?.lnuser?.is_lead !== nextProps.lead?.lnuser?.is_lead) {
        return false;
    }
    if (prevProps.lead.is_search_enrich !== nextProps.lead.is_search_enrich) {
        return false;
    }
    if (prevProps.lead.dropcontact_request !== nextProps.lead.dropcontact_request) {
        return false;
    }
    if (prevProps.lead.dropcontact_request_completed !== nextProps.lead.dropcontact_request_completed) {
        return false;
    }
    if (prevProps.lead.lnuser?.id !== nextProps.lead.lnuser?.id) {
        return false;
    }
    if (prevProps.lead.id !== nextProps.lead.id) {
        return false;
    }

    return true;
}
export default React.memo(LeadLine, areEqual);
