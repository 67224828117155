import { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { tr } from '../../common/locale';
import Modal from '../../components/Modal';
import { userState } from '../../_states/user.states';
import useUserActions from '../../_actions/user.actions';
import AppsumoTacos from '../../images/appsumo/appsumo-tacos.webp';

function Welcome() {
    const modalRef = useRef();
    const user = useRecoilValue(userState);
    const userActions = useUserActions();
    if (!user || !user.appsumo_code1) {
        return null;
    }
    const started = 1 - moment().diff(user.created_at, 'day');
    if (started > 0) {
        return null;
    }
    if (user.tours && user.tours.indexOf('appsumo') !== -1) {
        return null;
    }

    if (user.email === 'ricardo@innovia.com.br') {
        return null;
    }

    return (
        <Modal
            ref={modalRef}
            className="welcome-message appsumo-review"
            closeCb={() => {
                userActions.updateTour('appsumo');
            }}
            labelValidate={tr('Close')}
            defaultIsOpen
            title={tr('How about some tacos?')}
            mainButtonSize="large"
            mainButtonType="link"
        >
            <p className="welcome-message__main">
                Hey {user.firstname},
                <br />
                How do you like Kanbox so far?
            </p>
            <p className="welcome-message__secondary">
                Here is a way to show us your care.
                <br />
                Give us some tacos on AppSumo...
            </p>
            <div className="welcome-message__image">
                <a
                    href="https://appsumo.com/products/kanbox/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => modalRef.current.close()}
                >
                    <img src={AppsumoTacos} alt="How do you like Kanbox so far?" />
                </a>
            </div>
            <p className="welcome-message__secondary">
                <strong>We would love to know what do you think of our service.</strong>
            </p>
            <a
                className="btn btn--large btn--primary mb--30"
                href="https://appsumo.com/products/kanbox/"
                target="_blank"
                rel="noreferrer"
                onClick={() => modalRef.current.close()}
            >
                Review us on AppSumo
            </a>
        </Modal>
    );
}
export default Welcome;
