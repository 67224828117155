import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useUserActions from '../../_actions/user.actions';
import { tr } from '../../common/locale';
import { shareSearchesLoadingState, userState } from '../../_states/user.states';
import Icon from '../../components/Icon';
import Switch from '../../components/Switch';
import Button from '../../components/Button';
import Flash from '../../components/Flash';
import { snackMessageState } from '../../_states/alert.states';

function TeamOptions() {
    const actions = useUserActions();
    const user = useRecoilValue(userState);
    const shareSearchesLoading = useRecoilValue(shareSearchesLoadingState);
    const teamlink = `https://app.kanbox.io/login?team=${user?.team?.code}`;
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const [shareSearches, setShareSearches] = useState(user?.team?.is_shared_leads);
    const copy = () => {
        navigator.clipboard.writeText(teamlink);
        setSnackMessage({ text: tr('Your Team link is copied! '), type: 'success' });
    };

    return (
        <div className="content__body team-options">
            <div className="settings__header">
                <h2 className="title">{tr('Team link & options')}</h2>
            </div>
            <div className="settings__content">
                <ul className="team-options__menu">
                    <li>
                        <Switch
                            id="shareSearches"
                            text={tr('Share lead lists between teammates')}
                            checked={shareSearches}
                            onChange={(val) => setShareSearches(val)}
                        />
                        <Button
                            size="mini"
                            label={tr('Save')}
                            isLoading={shareSearchesLoading}
                            onClick={() => {
                                actions.updateShareSearches(shareSearches);
                            }}
                        />
                        {user?.team?.is_agency && (
                            <Flash color="blue">{tr('Agency mode - team members cannot see the team.')}</Flash>
                        )}
                    </li>
                </ul>
                <div className="team__link">
                    <Icon size={80} icon="link" />
                    <h1 className="title">{tr('Share this link with your team members.')}</h1>
                    <p>{tr('Each user who clicks this link will be added to the team.')}</p>
                    <div className="team__link__container" tabIndex="-1" role="button" onKeyDown={copy} onClick={copy}>
                        <span className="link">{teamlink}</span>
                        <span className="copy">Copy</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TeamOptions;
