import { useEffect, useState, useRef } from 'react';
import Slider from 'rc-slider';
import { useRecoilValue, useRecoilState } from 'recoil';
import 'rc-slider/assets/index.css';
import { tr } from '../../common/locale';
import useUserActions from '../../_actions/user.actions';
import { previewSubscriptionUpdateLoadingState, previewSubscriptionUpdateState } from '../../_states/user.states';
import Switch from '../../components/Switch';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Flash from '../../components/Flash';
import utils from '../../common/utils';

function ModalSubscribe({
    closeCb,
    open,
    pricing,
    selectedPlan,
    currency,
    currencyIcon,
    defaultSubscriptions,
    defaultSliderPlanValue,
    defaultIsMonth,
}) {
    const actions = useUserActions();
    const plans = ['connect', 'scrape', 'fusion'];
    const [isMonth, setIsMonth] = useState(false);
    const [quantity, setQuantity] = useState({});
    const previewSubscriptionUpdateLoading = useRecoilValue(previewSubscriptionUpdateLoadingState);
    const [previewSubscriptionUpdate, setPreviewSubscriptionUpdate] = useRecoilState(previewSubscriptionUpdateState);

    const [sliderPlanValue, setSliderPlanValue] = useState(0);
    const marks = {
        0: '0',
        25: '1,000',
        50: '2,000',
        75: '5,000',
        100: '10,000',
    };
    const modal = useRef();
    useEffect(() => {
        if (selectedPlan && selectedPlan !== 'TRIAL') {
            if (defaultSliderPlanValue) {
                setSliderPlanValue(defaultSliderPlanValue);
            }
            if (defaultIsMonth !== undefined) {
                setIsMonth(defaultIsMonth);
            }
            const newQt = {};
            plans.forEach((plan) => {
                newQt[plan] = plan === selectedPlan ? 1 : 0;
            });
            setQuantity(newQt);
            modal.current.open();
        }
    }, [selectedPlan]);

    useEffect(() => {
        if (open) {
            modal.current.open();
        }
    }, [open]);
    const getSliderDefaultVal = (subs) => {
        const emails = subs?.find((item) => item.product === 'EMAIL_CREDITS');
        if (emails) {
            const defaultVal = Object.keys(marks).find(
                (idx) => parseInt(marks[idx].replace(',', ''), 10) === emails.quantity
            );
            return defaultVal;
        }
        return 0;
    };
    const getDefaultQt = (subs) => {
        const newQt = {};
        plans.forEach((plan) => {
            const sub = subs.find((item) => item.product.toLowerCase() === plan);
            newQt[plan] = sub ? sub.quantity : 0;
        });
        return newQt;
    };
    useEffect(() => {
        if (defaultSubscriptions) {
            if (defaultIsMonth !== undefined) {
                setIsMonth(defaultIsMonth);
            }
            console.log('defaultSubscriptions', getSliderDefaultVal(defaultSubscriptions));
            setSliderPlanValue(getSliderDefaultVal(defaultSubscriptions));
            const newQt = {};
            plans.forEach((plan) => {
                const sub = defaultSubscriptions.find((item) => item.product.toLowerCase() === plan);
                newQt[plan] = sub ? sub.quantity : 0;
            });
            setQuantity(getDefaultQt(defaultSubscriptions));
        }
    }, [defaultSubscriptions]);
    console.log('isMonth', isMonth, defaultIsMonth);
    const isSubsriptionModified =
        !defaultSubscriptions ||
        (defaultSubscriptions &&
            Object.keys(quantity).length > 0 &&
            (isMonth !== defaultIsMonth ||
                getSliderDefaultVal(defaultSubscriptions) !== sliderPlanValue ||
                !utils.deepEqual(getDefaultQt(defaultSubscriptions), quantity)));
    useEffect(() => {
        if (isSubsriptionModified && defaultSubscriptions) {
            actions.checkout(
                isMonth,
                parseInt(marks[sliderPlanValue].replace(',', ''), 10),
                quantity,
                defaultSubscriptions,
                true
            );
        } else {
            setPreviewSubscriptionUpdate(null);
        }
    }, [sliderPlanValue, quantity, isMonth]);

    const changeQuantity = (current, inVal) => {
        const val = parseInt(inVal, 10) || 0;
        const newQt = { ...quantity };
        plans.forEach((plan) => {
            newQt[plan] = plan === current ? val : quantity[plan];
        });
        setQuantity(newQt);
    };

    let pricePerMonth = isMonth
        ? pricing?.emails.currency[currency].monthly[marks[sliderPlanValue]]
        : pricing?.emails.currency[currency].yearly[marks[sliderPlanValue]];
    if (pricing) {
        plans.forEach((plan) => {
            const unit = isMonth ? pricing[plan].currency[currency].monthly : pricing[plan].currency[currency].yearly;
            pricePerMonth += unit * quantity[plan];
        });
    }
    const price = isMonth ? pricePerMonth : pricePerMonth * 12;
    if (!pricing) {
        return null;
    }

    let noQuantity = true;
    plans.forEach((plan) => {
        if (quantity[plan] > 0) {
            noQuantity = false;
        }
    });
    return (
        <Modal
            className="subscribe"
            labelValidate={defaultSubscriptions ? tr('Validate') : tr('Checkout')}
            ref={modal}
            title={defaultSubscriptions ? tr('Modify your current subscription') : tr('Define your subscription')}
            disabled={previewSubscriptionUpdateLoading || !isSubsriptionModified || noQuantity}
            validateCb={() => {
                actions.checkout(
                    isMonth,
                    parseInt(marks[sliderPlanValue].replace(',', ''), 10),
                    quantity,
                    defaultSubscriptions
                );
            }}
            closeCb={closeCb}
        >
            <div className="subscribe__wrapper">
                <div className="billing-switcher">
                    <div className="billing-choice">
                        <span>{tr('Annualy')}</span>
                        <Switch
                            id="monthCheckSubscr"
                            disabled={!defaultIsMonth && !!defaultSubscriptions}
                            checked={isMonth}
                            onChange={(val) => setIsMonth(val)}
                        />
                        <span>{tr('Monthly')}</span>
                    </div>
                </div>
                <div className="subscribe__content">
                    <div className="subscribe__conditions">
                        <h2 className="title">
                            {isMonth
                                ? 'No commitment, pay monthly, and cancel anytime'
                                : 'Get advantageous rates with an annual subscription'}
                        </h2>
                    </div>
                    <div className="subscribe__header">
                        <div className="subscribe__title">{tr('Plans')}</div>
                        <div className="subscribe__qty">{tr('Quantity')}</div>
                    </div>
                    <div className="subscribe__plans">
                        {plans.map((plan) => (
                            <div
                                key={plan}
                                className={`subscribe__plan subscribe__plan--${pricing[plan].name.toLowerCase()}`}
                            >
                                <div className="subscribe__name">
                                    {pricing[plan].name}
                                    <span>
                                        &nbsp;(
                                        {currencyIcon}
                                        {isMonth
                                            ? pricing[plan].currency[currency].monthly
                                            : pricing[plan].currency[currency].yearly}
                                        {!isMonth ? ' x 12' : ' / month'})
                                    </span>
                                </div>
                                <div className="subscribe__qty-choose">
                                    <Button
                                        isBordered
                                        icon="minus"
                                        type="white"
                                        disabled={quantity[plan] === 0}
                                        onClick={() => changeQuantity(plan, quantity[plan] - 1)}
                                    />
                                    <Input
                                        type="number"
                                        value={`${quantity[plan]}`}
                                        onChange={(e) => changeQuantity(plan, parseInt(e.target.value, 10))}
                                    />
                                    <Button
                                        icon="plus"
                                        isBordered
                                        type="white"
                                        onClick={() => changeQuantity(plan, quantity[plan] + 1)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <h3 className="mr--30 ml--30 mb--20">
                        {tr('Choose the number of email credits you want every month in your plan:')}
                    </h3>

                    <div className="subscribe__plan-enrich">
                        <h4 className="mb--10">{tr('Email Credits')}</h4>
                        <Slider
                            marks={marks}
                            step={null}
                            value={sliderPlanValue}
                            onChange={(v) => setSliderPlanValue(v)}
                        />
                    </div>
                    <div className="subscribe__additionnal-enrich">
                        <Flash wrap>
                            {tr(
                                'If you need email credits occasionally, you will be able to purchase packs after subscribing to a plan.'
                            )}
                        </Flash>
                    </div>
                </div>
            </div>
            {noQuantity && (
                <div className="subscribe__amount alert">
                    <div className="subscribe__month-amount">{tr('Please, select at least one plan.')}</div>
                </div>
            )}
            {!previewSubscriptionUpdateLoading &&
                !noQuantity &&
                !!previewSubscriptionUpdate?.email_credits_prorated && (
                    <div
                        className={`subscribe__amount ${
                            previewSubscriptionUpdate?.email_credits_prorated > 0 ? 'credit' : 'prorata'
                        }`}
                    >
                        <div className="subscribe__month-amount">
                            <div className="prorata__text">
                                {tr('Your account will be immediatly updated with {{count}} email credits.', {
                                    count: previewSubscriptionUpdate?.email_credits_prorated,
                                })}
                            </div>

                            <div className="subscribe__total-amount">
                                {previewSubscriptionUpdate?.email_credits_prorated} credits
                            </div>
                        </div>
                    </div>
                )}
            {!previewSubscriptionUpdateLoading && !noQuantity && !!previewSubscriptionUpdate?.amount_due && (
                <div className="subscribe__amount prorata">
                    <div className="subscribe__month-amount">
                        <div className="prorata__text">
                            {tr('An invoice with the following prorated amount is going to be issued.')}
                        </div>
                        <div className="subscribe__total-amount">
                            {currencyIcon} {previewSubscriptionUpdate?.amount_due / 100}
                        </div>
                    </div>
                </div>
            )}

            {!previewSubscriptionUpdateLoading && !noQuantity && previewSubscriptionUpdate?.ending_balance < 0 && (
                <div className="subscribe__amount credit">
                    <div className="subscribe__month-amount">
                        <div className="prorata__text">
                            {tr(
                                'The difference will be credited to your account and deducted from your upcoming invoices.'
                            )}
                        </div>
                        <div className="subscribe__total-amount">
                            {currencyIcon} {previewSubscriptionUpdate?.ending_balance / 100}
                        </div>
                    </div>
                </div>
            )}
            <div className={`subscribe__amount ${previewSubscriptionUpdate?.amount_due && 'small'}`}>
                <div className="subscribe__month-amount">
                    <div>{tr('Total price: ')}</div>
                    <div className="subscribe__total-amount">
                        {currencyIcon} {price}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default ModalSubscribe;
