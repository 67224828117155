import { useEffect, useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import moment from 'moment';
import { useSearchParams, Link } from 'react-router-dom';
import { tr, getLocale } from '../../common/locale';
import { userState, plansState, subscriptionsState, stripeState } from '../../_states/user.states';
import useUserActions from '../../_actions/user.actions';
import Switch from '../../components/Switch';
import Flash from '../../components/Flash';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import Sticky from '../../components/Sticky';
import utils from '../../common/utils';
import ModalSubscribe from './modalsubscribe';
import AppsumoTacos from '../../images/appsumo/appsumo-tacos.webp';
import AppsumoImg from '../../images/appsumo/appsumo.webp';
import AppsumoImgDark from '../../images/appsumo/appsumo-dark.webp';

function Subscription() {
    const actions = useUserActions();
    const user = useRecoilValue(userState);
    const pricing = useRecoilValue(plansState);
    const [selectedPlan, setSelectedPlan] = useState('TRIAL');
    const [sliderPlanValue, setSliderPlanValue] = useState(0);
    const [packValue, setPackValue] = useState(0);
    const [modalChangeSubscriptionOpen, setModalChangeSubscriptionOpen] = useState(false);
    const subscriptions = useRecoilValue(subscriptionsState);
    const stripe = useRecoilValue(stripeState);
    const [isMonth, setIsMonth] = useState(false);
    const modalSubscribe = useRef();
    const scrollContainerRef = useRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const subscriptionParam = searchParams.get('subscription');
    let currency = 'euro';
    let currencyIcon = '€';
    if (user?.ipinfo_country === 'US' || user?.ipinfo_country === 'CA') {
        currency = 'dollar';
        currencyIcon = '$';
    } else if (user?.ipinfo_country === 'IN') {
        currency = 'roupie';
        currencyIcon = '₹';
    } else if (user?.ipinfo_country === 'PK') {
        currency = 'proupie';
        currencyIcon = 'Rs';
    }
    useEffect(() => {
        actions.getPlans();
        actions.getSubscriptions();
        actions.getStripe();
        actions.updateBrowsing();
    }, []);
    useEffect(() => {
        if (subscriptionParam && modalSubscribe.current) {
            modalSubscribe.current.open();
        }
    }, [user]);
    const locale = getLocale();
    const marks = {
        0: '0',
        25: '1,000',
        50: '2,000',
        75: '5,000',
        100: '10,000',
    };
    const marksPack = {
        0: '1,000',
        20: '5,000',
        40: '10,000',
        60: '20,000',
        80: '30,000',
        100: '50,000',
    };
    const plans = ['connect', 'scrape', 'fusion'];
    const renderFeatures = (plan, cat) =>
        pricing[plan].features.map((feat) => {
            const feature = pricing.features.find((current) => current.id === feat);
            if (feature.cat !== cat) {
                return null;
            }
            return (
                <div
                    className={`subscription-detail ${feature.price ? 'pack-detail' : ''} ${
                        feature.nocheck ? 'not-checked' : ''
                    }`}
                    key={feature.id}
                >
                    {feature.title && (
                        <div className="pack">
                            <div className="pack-info">
                                <div className="pack-title">{feature.title[locale]}</div>
                                <div className="pack-desc">{feature[locale]}</div>
                            </div>
                            <div className="pack-price">{feature.price}</div>
                        </div>
                    )}
                    {!feature.title && (
                        <span>
                            {feature.strong && <strong>{feature[locale]}</strong>}
                            {!feature.strong && feature[locale]}
                        </span>
                    )}
                    {!feature.nocheck && (
                        <>
                            {pricing[plan].unavailable_features.indexOf(feature.id) === -1 && (
                                <Icon
                                    icon="check"
                                    green
                                    size={18}
                                    isSubOne={plan === 'connect'}
                                    isSubTwo={plan === 'scrape'}
                                />
                            )}
                            {pricing[plan].unavailable_features.indexOf(feature.id) !== -1 && (
                                <Icon
                                    icon="close"
                                    red
                                    size={18}
                                    isSubOne={plan === 'connect'}
                                    isSubTwo={plan === 'scrape'}
                                />
                            )}
                        </>
                    )}
                </div>
            );
        });
    if (!user || (user.plan?.product === 'TRIAL' && !pricing)) {
        return null;
    }

    const hasSeats = subscriptions?.find(
        (sub) => ['connect', 'scrape', 'fusion', 'admin'].indexOf(sub.product.toLowerCase()) !== -1
    );

    const diffDayEndTrial = utils.trialEnd(user);
    if (!stripe || !subscriptions) {
        return (
            <div className="flex-axis fullwh">
                <Loader isChat text="Loading subscription..." />
            </div>
        );
    }

    if (!hasSeats && pricing) {
        return (
            <div ref={scrollContainerRef} className="subscription subscription--choose">
                <ModalSubscribe
                    pricing={pricing}
                    defaultIsMonth={isMonth}
                    currency={currency}
                    currencyIcon={currencyIcon}
                    defaultSliderPlanValue={sliderPlanValue}
                    selectedPlan={selectedPlan}
                    closeCb={() => setSelectedPlan('TRIAL')}
                />
                <div className="subscription__header">
                    <h1>{tr('Subscription')}</h1>
                    {diffDayEndTrial > 0 && (
                        <Flash color="blue">{tr('{{count}} days free trial left.', { count: diffDayEndTrial })}</Flash>
                    )}
                    {diffDayEndTrial <= 0 && <Flash color="red">{tr('Your Trial has expired')}</Flash>}
                </div>
                <div className="billing-switcher">
                    <div className="billing-choice">
                        <span>{tr('Annual Subscription')}</span>
                        <Switch id="monthCheck" checked={isMonth} onChange={(val) => setIsMonth(val)} />
                        <span>{tr('Monthly Subscription')}</span>
                    </div>
                    <h2 className={`title mt--20 ${isMonth && 'underline'}`}>
                        {isMonth
                            ? tr('No commitment, pay monthly, and cancel anytime')
                            : tr('Get advantageous rates with an annual subscription')}
                    </h2>
                </div>
                <div className="subscription__content">
                    <div className="subscription-wrapper">
                        {plans.map((plan) => (
                            <div
                                className={`subscription subscription-${pricing[plan].name.toLowerCase()}`}
                                key={pricing[plan].name}
                            >
                                {!isMonth && (
                                    <div className={`subscription-save ${isMonth && 'display'}`}>
                                        {tr('Save {{currency}} {{price}}', {
                                            price: pricing[plan].save,
                                            currency: currencyIcon,
                                        })}
                                    </div>
                                )}
                                <Sticky
                                    top={105}
                                    scrollContainer={scrollContainerRef.current}
                                    stuckClasses="subscription-header stuck"
                                    unstuckClasses="subscription-header unstuck"
                                >
                                    <div className="subscription-header-info">
                                        <div className="subscription-name">{pricing[plan].name}</div>
                                        <div className="subscription-price">
                                            <span className="subscription-amount ">
                                                <span className="subscription-currency">{currencyIcon}</span>{' '}
                                                <span className="pricing-desktop">
                                                    {isMonth
                                                        ? pricing[plan].currency[currency].monthly +
                                                          pricing.emails.currency[currency].monthly[
                                                              marks[sliderPlanValue]
                                                          ]
                                                        : pricing[plan].currency[currency].yearly +
                                                          pricing.emails.currency[currency].yearly[
                                                              marks[sliderPlanValue]
                                                          ]}
                                                </span>
                                                <span className="pricing-mobile">
                                                    {!isMonth
                                                        ? pricing[plan].currency[currency].monthly
                                                        : pricing[plan].currency[currency].yearly}
                                                </span>
                                            </span>
                                            <span className="subscription-amount-detail">{tr('/ month')}</span>
                                        </div>
                                    </div>
                                    <div className="subscription-desc">{pricing[plan][locale]}</div>
                                </Sticky>
                                {pricing.categories.map((cat) => (
                                    <div key={cat.id}>
                                        <div className="subscription-cat">{cat[locale]}</div>
                                        <div className="subscription-cat-features">{renderFeatures(plan, cat.id)}</div>
                                    </div>
                                ))}
                                <div className="subscription-header bottom">
                                    <div className="subscription-header-info">
                                        <div className="subscription-name">{pricing[plan].name}</div>
                                        <div className="subscription-price">
                                            <span className="subscription-amount">
                                                <span className="subscription-currency">{currencyIcon}</span>{' '}
                                                {!isMonth
                                                    ? pricing[plan].currency[currency].monthly +
                                                      pricing.emails.currency[currency].monthly[marks[sliderPlanValue]]
                                                    : pricing[plan].currency[currency].yearly +
                                                      pricing.emails.currency[currency].yearly[marks[sliderPlanValue]]}
                                            </span>
                                            <span className="subscription-amount-detail">{tr('/ month')}</span>
                                        </div>
                                    </div>
                                    <div className="subscription-option">
                                        <div className="subscription-option-name">{tr('+ Monthly Email Pack')}</div>
                                        <div className="subscription-option-container">
                                            <div className="subscription-option-info">
                                                {tr('Email Credits per month')}
                                            </div>
                                            <div className="subscription-option-picker">
                                                <Slider
                                                    marks={marks}
                                                    step={null}
                                                    value={sliderPlanValue}
                                                    onChange={(v) => setSliderPlanValue(v)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="subscription-cta">
                                        <Button label={tr('Subscribe')} onClick={() => setSelectedPlan(plan)} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    const subscription = subscriptions?.length > 0 ? subscriptions[0] : null;
    const getQuantity = (plan) => {
        let quantity = 0;
        if (subscriptions) {
            subscriptions.forEach((sub) => {
                if (sub.product.toLowerCase() === plan) {
                    quantity = sub.quantity;
                }
            });
        }
        return quantity;
    };
    const getSeatsUsed = (plan) => {
        let quantity = 0;
        if (subscriptions) {
            subscriptions.forEach((sub) => {
                if (sub.product.toLowerCase() === plan) {
                    quantity = sub.users_count;
                }
            });
        }
        return quantity;
    };

    const isTeamOwner = user && user.team && user.team.owner === user.id;
    const isPlanOwner = user && user.plan && user.plan.owner === user.id;
    const isAppsumo = !!user?.appsumo_code1;

    return (
        <div className="content account">
            <ModalSubscribe
                open={modalChangeSubscriptionOpen}
                pricing={pricing}
                currency={currency}
                currencyIcon={currencyIcon}
                defaultIsMonth={stripe?.is_month}
                defaultSubscriptions={subscriptions}
                closeCb={() => setModalChangeSubscriptionOpen(false)}
            />
            <Modal
                className="welcome-message"
                labelValidate={tr('Close')}
                ref={modalSubscribe}
                closeCb={() => {
                    if (searchParams.has('subscription')) {
                        searchParams.delete('subscription');
                        setSearchParams(searchParams);
                    }
                }}
                title={tr('Your subscription is updated!')}
            >
                <div className="welcome-message__image">
                    <Icon isPlan size={340} icon={`plan-${subscriptionParam}`} />
                </div>
                <p className="welcome-message__main">{tr('Thanks for your subscription!')}</p>
            </Modal>

            <div className="content__body">
                <div className="account__wrapper">
                    {(isAppsumo || isTeamOwner) && (
                        <div className="account__right">
                            {!isTeamOwner && (
                                <>
                                    <div className="account__quotas">
                                        <h2 className="title">{tr('Subscribed Email Credits')}</h2>
                                        <p className="account__quotas__desc">
                                            {tr(
                                                'Email enrichment credits allow you to retrieve members email addresses.'
                                            )}
                                        </p>
                                        {!isAppsumo && (
                                            <p className="account__quotas__info">
                                                {tr('1 Email found = 1 Credit spent')}
                                            </p>
                                        )}
                                        <div className="account__quotas__item">
                                            <p className="account__quotas__legend">{tr('Email Credits Renew Date')}</p>
                                            <h3 className="account__quotas__box">
                                                {moment(stripe.renew_credits_date).format('LL')}
                                            </h3>
                                        </div>
                                        <div className="account__quotas__item">
                                            <p className="account__quotas__legend">
                                                {tr('Recurring credits every month')}
                                            </p>
                                            <h3 className="account__quotas__box">{getQuantity('email_credits')}</h3>
                                        </div>
                                        <div className="account__quotas__item">
                                            <p className="account__quotas__legend">{tr('Available credits')}</p>
                                            <h3 className="account__quotas__box">{user?.team?.email_credits}</h3>
                                        </div>
                                    </div>
                                </>
                            )}
                            {isAppsumo && (
                                <div className="appsumo-review">
                                    <h1 className="appsumo-review__title">Hey {user.firstname},</h1>
                                    <p className="appsumo-review__subtitle">We need your review!</p>
                                    <div className="appsumo-review__image">
                                        <img src={AppsumoTacos} alt="How do you like Kanbox so far?" />
                                    </div>
                                    <p className="appsumo-review__message">
                                        How do you like Kanbox so far?
                                        <br />
                                        <strong>We need your help to gain visibility.</strong>
                                    </p>
                                    <a
                                        className="btn btn--primary"
                                        href="https://appsumo.com/products/kanbox/#reviews"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Review us on AppSumo
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="account__left">
                        <div className="account__current">
                            <h5 className="title">{tr('Current subscription')}</h5>
                            <h1 className="title flex mbi">
                                <span>
                                    {isAppsumo && user.plan?.product === 'FUSION'
                                        ? 'CONNECT + SCRAPE'
                                        : user.plan?.product}
                                </span>
                            </h1>
                            <div className="account__info">
                                {subscription && user.plan?.product !== 'FREE' && (
                                    <>
                                        {stripe && stripe.renew_date && (
                                            <div className="account__date">
                                                <p>{tr('Subscription Renew date:')}</p>
                                                <div className="account__date__box">
                                                    {moment(stripe.renew_date).format('LL')}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {isAppsumo && (
                            <div className="account__appsumo">
                                <div className="account__appsumo__top">
                                    <div>
                                        <img
                                            className="appsumo-img-white"
                                            src={AppsumoImg}
                                            alt={tr('AppSumo code subscription')}
                                        />
                                        <img
                                            className="appsumo-img-dark"
                                            src={AppsumoImgDark}
                                            alt={tr('AppSumo code subscription')}
                                        />
                                    </div>
                                    <h2 className="title">You have subscribed to an Appsumo Life-Time Deal</h2>
                                    {user.plan?.product === 'CONNECT' && (
                                        <div>
                                            <Link className="btn btn--primary" to="/appsumo">
                                                <span className="btn__label">{tr('+ Use another Appsumo Code')}</span>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                <div className="account__appsumo__bottom">
                                    {isAppsumo && isPlanOwner && (
                                        <>
                                            <div className="add-on">
                                                {!stripe?.id && (
                                                    <>
                                                        <div className="add-on__intro">
                                                            <div>
                                                                <Icon isEmpty icon="empty-automations" size={200} />
                                                            </div>
                                                            <div>
                                                                <h2 className="title">
                                                                    {tr('Subscribe to Automations')}
                                                                </h2>
                                                                <p className="fs-large mb--10">
                                                                    {tr(
                                                                        'Automatically trigger profile visits, connection requests, messages and follow-ups. Customize messages using templates and variables. Track campaigns in real-time with automated CRM Pipelines, seamlessly transitioning leads across columns.'
                                                                    )}
                                                                </p>
                                                                <p className="fs-large blue">
                                                                    {tr(
                                                                        'Automations are an add-on that you subscribe to on a monthly basis.'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="add-on__price">
                                                            <div className="flex-grow">
                                                                <div className="add-on__price__info">
                                                                    <span className="add-on__price__amount">
                                                                        {currencyIcon} {isMonth ? '25' : '20'}
                                                                    </span>
                                                                    <span className="add-on__price__period">
                                                                        per month
                                                                    </span>
                                                                </div>
                                                                <div className="add-on__price__switch">
                                                                    <span>{tr('Yearly')}</span>
                                                                    <Switch
                                                                        id="appsumoPricing"
                                                                        checked={isMonth}
                                                                        onChange={(val) => setIsMonth(val)}
                                                                    />
                                                                    <span>{tr('Monthly')}</span>
                                                                </div>
                                                            </div>
                                                            <Button
                                                                label={tr('Subscribe')}
                                                                onClick={() => actions.checkoutAutomations(isMonth)}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                                {stripe?.id && (
                                                    <>
                                                        <div className="add-on__intro">
                                                            <div>
                                                                <Icon isEmpty icon="empty-automations" size={200} />
                                                            </div>
                                                            <div>
                                                                <h2 className="title mb--30">
                                                                    {tr('You are subscribed to the automations addon.')}
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div className="add-on__price">
                                                            <div className="flex-grow" />
                                                            <Button
                                                                label={tr('Manage subscription and invoices')}
                                                                onClick={() => actions.portal()}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="add-on">
                                                <div className="add-on__intro pb--0">
                                                    <div>
                                                        <Icon isEmpty icon="empty-email-pack" size={200} />
                                                    </div>
                                                    <div>
                                                        <h2 className="title">{tr('Buy Email Credits Pack')}</h2>
                                                        <p className="fs-large mb--10">
                                                            {tr(
                                                                'Email Credit Packs do not have a time limit and can be used throughout your subscription to Kanbox.'
                                                            )}
                                                        </p>
                                                        <p className="fs-large blue mb--10">
                                                            {tr('1 Email found = 1 Credit spent')}
                                                        </p>
                                                        <p className="fs-large blue">{tr('No time limit')}</p>
                                                    </div>
                                                </div>
                                                <div className="add-on__slider">
                                                    <Slider
                                                        marks={marksPack}
                                                        step={null}
                                                        value={packValue}
                                                        onChange={(v) => setPackValue(v)}
                                                    />
                                                </div>
                                                <div className="add-on__price">
                                                    <div className="flex-grow">
                                                        <div className="add-on__price__info">
                                                            <span className="add-on__price__amount">
                                                                {currencyIcon}{' '}
                                                                {pricing?.emails?.onetime[marksPack[packValue]]}
                                                            </span>
                                                        </div>
                                                        <div className="add-on__price__details">
                                                            {tr('Pack of')} {[marksPack[packValue]]} {tr('emails')}
                                                        </div>
                                                    </div>
                                                    <Button
                                                        label={tr('Buy this Pack')}
                                                        onClick={() =>
                                                            actions.checkoutPack(
                                                                parseInt(marksPack[packValue].replace(',', ''), 10)
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {!user?.appsumo_newplan && (
                                        <div className="account__appsumo__changes">
                                            <h3 className="red">Important information regarding your subscription</h3>
                                            {!user?.appsumo_code2 && (
                                                <div className="account__appsumo__plans-compair">
                                                    <div className="account__appsumo__plan">
                                                        You have a <strong>CONNECT + SCRAPE</strong> plan with:
                                                        <ul>
                                                            <li>
                                                                <strong>up to 2500 Export Credits / day*</strong>
                                                            </li>
                                                            <li>2,000 Email Credits / month**</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                            {user?.appsumo_code2 && (
                                                <div className="account__appsumo__plans-compair mb--30">
                                                    <div className="account__appsumo__plan">
                                                        You have a <strong>CONNECT + SCRAPE</strong> plan with:
                                                        <ul>
                                                            <li>
                                                                <strong>up to 2500 Export Credits / day*</strong>
                                                            </li>
                                                            <li>10,000 Email Credits / month**</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}

                                            <p className="mb--20">
                                                * The exports are limited to 2500 users / day if you have Sales
                                                Navigator and 80 users / day if you have a free Linkedin Account. This
                                                quota is necessary to prevent your account from being banned.
                                            </p>
                                            <p>
                                                <strong className="underline">
                                                    ** Credits are reset every month. 1 credit per one email searched.
                                                </strong>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {!isAppsumo && stripe && stripe.is_stripe && (
                            <>
                                <div className="account__actions">
                                    {stripe.is_new_subscription && (
                                        <Button
                                            label={tr('Upgrade subscription')}
                                            onClick={() => setModalChangeSubscriptionOpen(true)}
                                        />
                                    )}
                                    <Button
                                        isBordered
                                        type="transparent"
                                        label={tr('See Payment details and invoices')}
                                        onClick={() => actions.portal()}
                                    />
                                </div>
                                <div className="account__seats">
                                    <h2 className="title">{tr('Subscribed seats')}</h2>
                                    <div className="account__seats__wrapper">
                                        {['connect', 'scrape', 'fusion'].map((item) => (
                                            <div className={`account__seat ${item}`} key={item}>
                                                <div className="account__seat__title">{item}</div>
                                                <div className="account__seat__count">
                                                    {getSeatsUsed(item)} / {getQuantity(item)}
                                                </div>
                                                <div className="account__seat__info">{tr('Used Seats')}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Subscription;
