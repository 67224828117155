import { useEffect, useRef, useState, useCallback } from 'react';
import { useRecoilValue, useResetRecoilState, useRecoilState } from 'recoil';
import Board, { createTranslate } from 'react-trello';
import Select, { components } from 'react-select';
import debounce from 'lodash.debounce';
import { tr } from '../../common/locale';
import { webhooksState, removingboardState, addingboardState } from '../../_states/webhook.states';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Popover from '../../components/Popover';
import TooltipPopover from '../../components/TooltipPopover';
import Button from '../../components/Button';
import ConfirmButton from '../../components/ConfirmButton';
import Icon from '../../components/Icon';
import Label from '../../components/Label';
import Dropdown from '../../components/Dropdown';
import Loader from '../../components/Loader';
import useBoardActions from '../../_actions/board.actions';
import useUserActions from '../../_actions/user.actions';
import Card from './Card';
import AddCardLink from './AddCardLink';
import LabelsList, { Colors } from '../lnuser/LabelsList';
import BulkStatus from '../connections/BulkStatus';
import AddBoard from './AddBoard';
import Labels from '../lnuser/Labels';
import {
    bulkSendProgressState,
    bulkInviteProgressState,
    bulkSyncProgressState,
    boardState,
    filterNameState,
    filterLabelsState,
    boardsLoadingState,
    boardsState,
    selectedBoardIdState,
    initBoardLanesState,
    enrichLoadingState,
    moveLaneLoadingState,
    csvExportBoardState,
} from '../../_states/lnusers.states';
import { labelsState } from '../../_states/label.states';
import BulkSendMessageAction from '../connections/BulkSendMessageAction';
import BulkInviteAction from '../connections/BulkInviteAction';
import BulkMoveLaneAction from '../connections/BulkMoveOrLabelLaneAction';
import BulkRemoveBoardAction from '../connections/BulkRemoveBoardAction';
import BulkSyncAction from '../connections/BulkSyncAction';
import BulkEnrichAction from '../connections/BulkEnrichAction';
import useWebhooksActions from '../../_actions/webhook.actions';
import CampaignHeader from '../campaigns/CampaignHeader';

const LabelOption = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <Label
                noBg
                key={data.id}
                label={data.name}
                color={Colors[data.color].light}
                iconColor={Colors[data.color].dark}
            />
        </components.Option>
    );
};

const NewLaneForm = (props) => {
    const { onAdd, onCancel } = props;
    const [name, setName] = useState('');
    const handleAdd = () => onAdd({ title: name });
    return (
        <div className="react-trello-lane__header-opened">
            <div className="react-trello-lane__new">
                <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={tr('Type in a column name')}
                />
                <div className="react-trello-lane__new__actions">
                    <Button type="link" label={tr('Cancel')} onClick={onCancel} />
                    <Button size="small" label={tr('+ Add')} onClick={handleAdd} />
                </div>
            </div>
        </div>
    );
};

const LaneHeader = ({ updateTitle, label, title, onDelete }) => {
    const [edit, setEdit] = useState(false);
    const addwebhookRef = useRef();
    const [addingboard, setAddingboard] = useRecoilState(addingboardState);
    const actions = useBoardActions();
    const board = useRecoilValue(boardState);
    const [titleEdit, setTitleEdit] = useState(title);
    const webhooks = useRecoilValue(webhooksState);
    const webhooksActions = useWebhooksActions();
    const [selectedWebhook, setSelectedWebhook] = useState(null);
    const [removingboard, setRemovingboard] = useRecoilState(removingboardState);
    const [sending, setSending] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [openSendMessageAction, setOpenSendMessageAction] = useState(false);
    const [openInviteAction, setInviteAction] = useState(false);
    const [openMoveLaneAction, setMoveLaneAction] = useState(false);
    const [openMoveBoardAction, setMoveBoardAction] = useState(false);
    const [openRemoveBoardAction, setRemoveBoardAction] = useState(false);
    const [openAddLabelsAction, setAddLabelsAction] = useState(false);
    const [openEnrichAction, setEnrichAction] = useState(false);
    const [openSyncAction, setOpenSyncAction] = useState(false);
    const bulkSendProgress = useRecoilValue(bulkSendProgressState);
    const bulkSyncProgress = useRecoilValue(bulkSyncProgressState);
    const bulkInviteProgress = useRecoilValue(bulkInviteProgressState);
    const csvExport = useRecoilValue(csvExportBoardState);
    const enrichLoading = useRecoilValue(enrichLoadingState);
    const moveLaneLoading = useRecoilValue(moveLaneLoadingState);
    const actionsRef = useRef();
    let webhookLane = null;
    if (webhooks?.items?.length > 0) {
        webhookLane = webhooks?.items.find((item) => item.step_set.find((step) => step.id === parseInt(label, 10)));
    }
    useEffect(() => {
        if (bulkSendProgress) {
            setOpenSendMessageAction(false);
        }
        if (sending && bulkSendProgress === undefined) {
            setSending(false);
        }
    }, [bulkSendProgress]);
    useEffect(() => {
        if (bulkInviteProgress) {
            setInviteAction(false);
        }
    }, [bulkInviteProgress]);
    useEffect(() => {
        if (!addingboard && addwebhookRef.current) {
            addwebhookRef.current.close();
        }
    }, [addingboard]);
    useEffect(() => {
        if (!enrichLoading) {
            setEnrichAction(false);
        }
    }, [enrichLoading]);
    useEffect(() => {
        console.log('moveLaneLoading', moveLaneLoading);
        if (!moveLaneLoading) {
            setMoveLaneAction(false);
            setMoveBoardAction(false);
            setRemoveBoardAction(false);
            setAddLabelsAction(false);
        }
    }, [moveLaneLoading]);
    useEffect(() => {
        if (bulkSyncProgress) {
            setOpenSyncAction(false);
        }
        if (syncing && bulkSyncProgress === undefined) {
            setSyncing(false);
        }
    }, [bulkSyncProgress]);

    const lane = board?.lanes?.find((current) => current.id === label);
    const isAutomatedLane =
        label === `${board?.campaign?.action_answered_step}` ||
        label === `${board?.campaign?.recipient_from_step}` ||
        label === `${board?.campaign?.action_follow_step}` ||
        label === `${board?.campaign?.action_visit_step}` ||
        label === `${board?.campaign?.action_answered_step}` ||
        label === `${board?.campaign?.action_connect_step_requested}` ||
        label === `${board?.campaign?.action_connect_step_accepted}` ||
        label === `${board?.campaign?.action_connect_step_ignored}` ||
        label === `${board?.campaign?.action_message_1_step_sent}` ||
        label === `${board?.campaign?.action_message_2_step_sent}` ||
        label === `${board?.campaign?.action_message_3_step_sent}` ||
        label === `${board?.campaign?.action_message_4_step_sent}` ||
        label === `${board?.campaign?.action_error_step}`;

    const hideActionsCampaign =
        isAutomatedLane &&
        board?.campaign &&
        board?.campaign.status !== 'ARCHIVED' &&
        board?.campaign.status !== 'FINISHED';
    const renderContent = () => (
        <>
            <Dropdown.MenuSection title={tr('Actions for all column members')}>
                {!hideActionsCampaign && (
                    <Dropdown.ButtonItem
                        label={tr('Send a message')}
                        placement="left-center"
                        isLoading={bulkSendProgress}
                        onClick={() => {
                            setOpenSendMessageAction(true);
                            actionsRef.current.toggle();
                        }}
                    />
                )}
                {!hideActionsCampaign && (
                    <Dropdown.ButtonItem
                        label={tr('Request a connection')}
                        placement="left-center"
                        isLoading={bulkInviteProgress}
                        onClick={() => {
                            setInviteAction(true);
                            actionsRef.current.toggle();
                        }}
                    />
                )}
                <Dropdown.Separator />
                <Dropdown.ButtonItem
                    label={tr('Move to another column')}
                    placement="left-center"
                    isLoading={moveLaneLoading}
                    onClick={() => {
                        setMoveLaneAction(true);
                        actionsRef.current.toggle();
                    }}
                />
                <Dropdown.ButtonItem
                    label={tr('Move to another pipeline')}
                    placement="left-center"
                    isLoading={moveLaneLoading}
                    onClick={() => {
                        setMoveBoardAction(true);
                        actionsRef.current.toggle();
                    }}
                />
                <Dropdown.ButtonItem
                    label={tr('Remove from pipeline')}
                    placement="left-center"
                    isLoading={moveLaneLoading}
                    onClick={() => {
                        setRemoveBoardAction(true);
                        actionsRef.current.toggle();
                    }}
                />
                <Dropdown.ButtonItem
                    label={tr('Add labels')}
                    placement="left-center"
                    isLoading={moveLaneLoading}
                    onClick={() => {
                        setAddLabelsAction(true);
                        actionsRef.current.toggle();
                    }}
                />

                <Dropdown.Separator />
                <Dropdown.ButtonItem
                    label={tr('Update profiles')}
                    placement="left-center"
                    isLoading={bulkSyncProgress}
                    onClick={() => {
                        setOpenSyncAction(true);
                        actionsRef.current.toggle();
                    }}
                />
                <Dropdown.ButtonItem
                    label={tr('Find email addresses')}
                    placement="left-center"
                    isLoading={enrichLoading}
                    onClick={() => {
                        setEnrichAction(true);
                        actionsRef.current.toggle();
                    }}
                />
                <Dropdown.Separator />
                <Dropdown.ButtonItem
                    label={tr('CSV Export')}
                    placement="left-center"
                    isLoading={csvExport}
                    onClick={() => {
                        actions.csv(board.id, label);
                    }}
                />
                {/* <Dropdown.ConfirmButtonItem
                label={tr('Hide all from Kanbox')}
                placement="left-center"
                confirmLabel={tr('Do you confirm you want to Archive this lead?')}
            /> */}
                <Dropdown.Separator />

                <Dropdown.ConfirmButtonItem
                    label={tr('Delete column')}
                    placement="left-center"
                    confirmLabel={tr('Do you confirm you want to delete this column?')}
                    onConfirm={onDelete}
                />

                {webhooks?.items?.length > 0 && (
                    <>
                        <Dropdown.Separator />
                        {webhookLane && (
                            <Dropdown.ConfirmButtonItem
                                label={tr('Remove Webhook trigger to {{name}}', { name: webhookLane.name })}
                                placement="left-center"
                                isLoading={removingboard}
                                confirmLabel={tr('Do you confirm you want to delete this webhook trigger?')}
                                onConfirm={() => {
                                    setRemovingboard(true);
                                    webhooksActions.removeBoard(webhookLane, lane);
                                }}
                            />
                        )}
                        {!webhookLane && (
                            <Dropdown.ButtonItem
                                label={tr('Add webhook trigger')}
                                placement="left-center"
                                onClick={() => {
                                    addwebhookRef.current.open();
                                    actionsRef.current.toggle();
                                }}
                            />
                        )}
                    </>
                )}
            </Dropdown.MenuSection>
        </>
    );
    const hasCardsInit = lane && lane.cards !== undefined && lane.cards !== null;
    return (
        <>
            <div className="react-trello-lane__header">
                <Modal
                    ref={addwebhookRef}
                    closeCb={() => {
                        setRemovingboard(false);
                    }}
                    title={tr('Add a webhook trigger')}
                    labelValidate={tr('Add')}
                    validateCb={() => {
                        setAddingboard(true);
                        webhooksActions.addBoard({ id: selectedWebhook.value }, board, lane);
                    }}
                >
                    <div>
                        {tr('Select the webhook you want to trigger each time a member is added to this column.')}
                    </div>
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder={tr('Choose a webhook')}
                        onChange={(selected) => {
                            setSelectedWebhook(selected);
                        }}
                        isDisabled={webhooks?.items.length === 0}
                        options={webhooks?.items?.map((current) => ({
                            value: current.id,
                            name: current.name,
                            label: current.name,
                        }))}
                    />
                </Modal>
                {edit && (
                    <div className="react-trello-lane__new">
                        <Input
                            isFocusedDefault
                            value={titleEdit}
                            onBlur={() => {
                                updateTitle(titleEdit);
                                setEdit(false);
                            }}
                            onPressEnter={(e) => {
                                e.preventDefault();
                                updateTitle(titleEdit);
                                setEdit(false);
                            }}
                            onChange={(e) => {
                                setTitleEdit(e.target.value);
                            }}
                            onFocus={(e) => e.target.select()}
                            onMouseDown={(e) => {
                                if (document.activeElement !== e.target) {
                                    e.preventDefault();
                                }
                            }}
                            placeholder={tr('Type in a column name')}
                        />
                    </div>
                )}
                {!edit && (
                    <div
                        role="button"
                        tabIndex={0}
                        className="react-trello-lane__title"
                        onClick={(e) => {
                            e.stopPropagation();
                            setEdit(true);
                        }}
                        onKeyDown={() => null}
                    >
                        <span>{`${title} ${hasCardsInit ? `(${lane.count})` : ''}`}</span>
                    </div>
                )}
                {!hasCardsInit && <Icon isLoad icon="load" />}
                <Popover contentClassName="lane-actions" ref={actionsRef} content={renderContent()}>
                    <Button
                        icon="menu-horizontal"
                        type="link"
                        onClick={() => {
                            actionsRef.current.toggle();
                        }}
                    />
                </Popover>
                {openSendMessageAction && (
                    <BulkSendMessageAction
                        boardId={board.id}
                        laneId={label}
                        closeCb={() => setOpenSendMessageAction(false)}
                        actionCb={() => {
                            setSending(true);
                        }}
                    />
                )}
                {openSyncAction && (
                    <BulkSyncAction
                        boardId={board.id}
                        laneId={label}
                        closeCb={() => setOpenSyncAction(false)}
                        actionCb={() => {
                            setSyncing(true);
                        }}
                    />
                )}
                {openInviteAction && (
                    <BulkInviteAction boardId={board.id} laneId={label} closeCb={() => setInviteAction(false)} />
                )}
                {openMoveLaneAction && (
                    <BulkMoveLaneAction boardId={board.id} laneId={label} closeCb={() => setMoveLaneAction(false)} />
                )}
                {openMoveBoardAction && (
                    <BulkMoveLaneAction
                        moveBoard
                        boardId={board.id}
                        laneId={label}
                        closeCb={() => setMoveBoardAction(false)}
                    />
                )}
                {openRemoveBoardAction && (
                    <BulkRemoveBoardAction
                        boardId={board.id}
                        laneId={label}
                        closeCb={() => setRemoveBoardAction(false)}
                    />
                )}
                {openAddLabelsAction && (
                    <BulkMoveLaneAction
                        addLabel
                        boardId={board.id}
                        laneId={label}
                        closeCb={() => setMoveBoardAction(false)}
                    />
                )}
                {openEnrichAction && (
                    <BulkEnrichAction boardId={board.id} laneId={label} closeCb={() => setEnrichAction(false)} />
                )}
            </div>
            <AddCardLink laneId={label} />
        </>
    );
};

function BoardPage() {
    const actions = useBoardActions();
    const userActions = useUserActions();
    const webhooksActions = useWebhooksActions();
    const webhooks = useRecoilValue(webhooksState);
    const [board, setBoard] = useRecoilState(boardState);
    const [initBoardLanes, setInitBoardLanes] = useRecoilState(initBoardLanesState);
    const labels = useRecoilValue(labelsState);
    const editBoardModalRef = useRef();
    const [searchText, setSearchText] = useState('');
    const recruiterTemplateBoardModalRef = useRef();
    const salesTemplateBoardModalRef = useRef();
    const boards = useRecoilValue(boardsState);
    const [boardsLoading, setBoardsLoading] = useRecoilState(boardsLoadingState);
    const [editName, setEditName] = useState('');
    const [boardName, setBoardName] = useState('');
    const [hideSidebar, setHideSidebar] = useState(localStorage.getItem('hideSidebarBoards') === 'true');
    const [selectedBoardId, setSelectedBoardId] = useRecoilState(selectedBoardIdState);
    const [filterName, setFilterName] = useRecoilState(filterNameState);
    const [filterLabels, setFilterLabels] = useRecoilState(filterLabelsState);
    const resetBoard = useResetRecoilState(boardState);
    const searchFunc = (value) => {
        setFilterName(value);
    };
    const debouncedSearch = useCallback(debounce(searchFunc, 300), []);
    const hideActionsCampaign =
        board?.campaign && board?.campaign.status !== 'ARCHIVED' && board?.campaign.status !== 'FINISHED';
    const selectBoardById = (boardId) => {
        setSelectedBoardId(boardId);
        if (boardId) {
            localStorage.setItem('selectedBoard', boardId);
        } else {
            localStorage.removeItem('selectedBoard');
        }
    };
    useEffect(() => {
        if (!webhooks) {
            webhooksActions.get();
        }
    }, []);
    useEffect(() => {
        actions.getBoards();
        userActions.updateBrowsing();
    }, []);
    useEffect(() => {
        if (!initBoardLanes && board) {
            actions.getBoardData();
        }
    }, [board]);
    useEffect(() => {
        setEditName(board?.name || '');
        if (editBoardModalRef) {
            editBoardModalRef.current.close();
            setEditName('');
        }
        if (recruiterTemplateBoardModalRef) {
            recruiterTemplateBoardModalRef.current.close();
            setBoardName('');
        }
        if (salesTemplateBoardModalRef) {
            salesTemplateBoardModalRef.current.close();
            setBoardName('');
        }
    }, [board]);
    useEffect(() => {
        if (boards?.length > 0) {
            if (!boards.find((elem) => elem.id === selectedBoardId)) {
                selectBoardById(boards[0].id);
            }
        }
    }, [boards]);
    useEffect(() => {
        if (selectedBoardId && selectedBoardId !== board?.id) {
            setInitBoardLanes(false);
            actions.getBoard(selectedBoardId);
        }
    }, [selectedBoardId]);
    useEffect(() => {
        if (selectedBoardId && selectedBoardId === board?.id) {
            setInitBoardLanes(false);
            actions.getBoardData();
        }
    }, [filterName, filterLabels]);
    useEffect(() => {
        localStorage.setItem('hideSidebarBoards', hideSidebar);
    }, [hideSidebar]);
    useEffect(
        () => () => {
            resetBoard();
            setFilterName('');
            if (filterLabels.length > 0) {
                setFilterLabels([]);
            }
        },
        []
    );
    const onChangeLabels = (selected) => {
        setFilterLabels(selected.map((item) => item.value));
    };
    const TEXTS = {
        'Add another lane': '+ New column',
        'Click to add card': 'Click to add card',
        'Delete lane': 'Delete column',
        'Lane actions': 'Column actions',
        button: {
            'Add lane': 'Add column',
            'Add card': 'Add card',
            Cancel: 'Cancel',
        },
        placeholder: {
            title: 'title',
            description: 'description',
            label: 'label',
        },
    };
    const PER_PAGE = 20;
    const getBoardData = () => {
        const data = { ...board };
        const lanes = [];
        board?.lanes?.forEach((current) => {
            const lane = { ...current };
            const cards = current.cards !== undefined ? [] : undefined;
            current.cards?.every((card) => {
                cards.push({ ...card });
                return cards.length <= PER_PAGE;
            });
            lane.cards = cards;
            lanes.push(lane);
        });
        data.lanes = lanes;

        return data;
    };
    const paginateLane = (requestedPage, laneId) => {
        const result = [];
        const current = board.lanes.find((lane) => lane.id === laneId);
        if (current && current.count > (requestedPage - 1) * PER_PAGE) {
            return actions.getLaneData(board.id, current.id, requestedPage);
        }
        return new Promise((resolve) => resolve(result));
    };

    const liBoard = (current) => (
        <li key={current.id}>
            <Button
                className="board__sidebar__longname"
                icon="board"
                iconSize={20}
                active={board?.id === current.id}
                type="secondary"
                label={current.name}
                onClick={(e) => {
                    if (selectedBoardId !== current.id) {
                        e.stopPropagation();
                        setBoard(null);
                        setBoardsLoading(true);
                        selectBoardById(current.id);
                    }
                }}
            />
            <TooltipPopover className="board__sidebar__badge-item" direction="e" text={current.name}>
                <Button
                    className="board__sidebar__badge"
                    active={board?.id === current.id}
                    type="link"
                    label={current.name.slice(0, 3)}
                    onClick={(e) => {
                        if (selectedBoardId !== current.id) {
                            e.stopPropagation();
                            setBoard(null);
                            setBoardsLoading(true);
                            selectBoardById(current.id);
                        }
                    }}
                />
            </TooltipPopover>
        </li>
    );
    const playingBoardCampaigns = boards?.filter((current) => current.campaign?.status === 'PLAYING');
    const queuedBoardCampaigns = boards?.filter((current) => current.campaign?.status === 'QUEUED');
    const draftBoardCampaigns = boards?.filter((current) => current.campaign?.status === 'DRAFT');
    const finishedBoardCampaigns = boards?.filter((current) => current.campaign?.status === 'FINISHED');
    const archivedBoardCampaigns = boards?.filter((current) => current.campaign?.status === 'ARCHIVED');
    return (
        <div className={`content board ${board?.campaign ? 'campaign' : ''}`}>
            <div className={`content__sidebar content__sidebar--large ${hideSidebar && 'badges'}`}>
                <div className="content__sidebar__header">
                    <Button
                        className="menu"
                        icon="sandwich"
                        type="link"
                        onClick={() => {
                            setHideSidebar(!hideSidebar);
                        }}
                    />
                    <div className="content__sidebar__header__title">
                        <h1 className="title">{tr('Pipelines')}</h1>
                        <div className="btn--group-menu">
                            <AddBoard context="header" />
                            <Dropdown
                                btnFace="primary"
                                btnSize="small"
                                onClick={(e) => e.stopPropagation()}
                                onDoubleClick={(e) => e.stopPropagation()}
                            >
                                <Dropdown.Menu direction="sw">
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('+ New Recruiter Pipleine')}
                                        onClick={() => recruiterTemplateBoardModalRef.current.open()}
                                    />
                                    <Dropdown.ButtonItem
                                        btnLabel={tr('+ New Sales Pipleine')}
                                        onClick={() => salesTemplateBoardModalRef.current.open()}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className="content__sidebar__body">
                    <div className="content__sidebar__body__section">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">{tr('Pipelines')}</h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            {boards?.filter((current) => !current.campaign).map((current) => liBoard(current))}
                        </ul>
                    </div>

                    {playingBoardCampaigns?.filter((current) => !!current.campaign)?.length > 0 && (
                        <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                            <div className="content__sidebar__body__section__header">
                                <h5 className="title">{tr('Playing Campaign pipelines')}</h5>
                                <h5 className="sort-title">{tr('Playing')}</h5>
                            </div>

                            <ul className="content__sidebar__body__section__filters campaign-boards--processing">
                                {playingBoardCampaigns
                                    ?.filter((current) => !!current.campaign)
                                    .map((current) => liBoard(current))}
                            </ul>
                        </div>
                    )}
                    {queuedBoardCampaigns?.filter((current) => !!current.campaign)?.length > 0 && (
                        <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                            <div className="content__sidebar__body__section__header">
                                <h5 className="title">{tr('Queued Campaign pipelines')}</h5>
                                <h5 className="sort-title">{tr('Queued')}</h5>
                            </div>

                            <ul className="content__sidebar__body__section__filters campaign-boards--queued">
                                {queuedBoardCampaigns
                                    ?.filter((current) => !!current.campaign)
                                    .map((current) => liBoard(current))}
                            </ul>
                        </div>
                    )}
                    {draftBoardCampaigns?.filter((current) => !!current.campaign)?.length > 0 && (
                        <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                            <div className="content__sidebar__body__section__header">
                                <h5 className="title">{tr('Draft Campaign pipelines')}</h5>
                                <h5 className="sort-title">{tr('Draft')}</h5>
                            </div>

                            <ul className="content__sidebar__body__section__filters campaign-boards--draft">
                                {draftBoardCampaigns
                                    ?.filter((current) => !!current.campaign)
                                    .map((current) => liBoard(current))}
                            </ul>
                        </div>
                    )}
                    {finishedBoardCampaigns?.filter((current) => !!current.campaign)?.length > 0 && (
                        <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                            <div className="content__sidebar__body__section__header">
                                <h5 className="title">{tr('Finished Campaign pipelines')}</h5>
                                <h5 className="sort-title">{tr('Finished')}</h5>
                            </div>

                            <ul className="content__sidebar__body__section__filters campaign-boards--finished">
                                {finishedBoardCampaigns
                                    ?.filter((current) => !!current.campaign)
                                    .map((current) => liBoard(current))}
                            </ul>
                        </div>
                    )}
                    {archivedBoardCampaigns?.filter((current) => !!current.campaign)?.length > 0 && (
                        <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                            <div className="content__sidebar__body__section__header">
                                <h5 className="title">{tr('Archived Campaign pipelines')}</h5>
                                <h5 className="sort-title">{tr('Archived')}</h5>
                            </div>

                            <ul className="content__sidebar__body__section__filters campaign-boards--archived">
                                {archivedBoardCampaigns
                                    ?.filter((current) => !!current.campaign)
                                    .map((current) => liBoard(current))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div className="content__body">
                <div className="board__header">
                    {boards?.length >= 1 && (
                        <>
                            <div className="board__header__section name">
                                <h2 className="board__name">{board?.name}</h2>

                                <div className="board__actions">
                                    <Button
                                        iconLight
                                        type="link"
                                        icon="pen"
                                        onClick={() => {
                                            setEditName(board?.name || '');
                                            editBoardModalRef.current.open();
                                        }}
                                    />
                                    {!hideActionsCampaign && (
                                        <Button
                                            iconLight
                                            type="link"
                                            icon="copy"
                                            onClick={() => actions.duplicateBoard()}
                                        />
                                    )}
                                    {!hideActionsCampaign && (
                                        <ConfirmButton
                                            iconLight
                                            type="link"
                                            icon="trash-can"
                                            confirmLabel={tr('Do you confirm you want to delete this Pipeline?')}
                                            onConfirm={() => actions.deleteBoard(board.id)}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    <Modal
                        ref={editBoardModalRef}
                        validateCb={() => actions.updateBoard(editName)}
                        title={tr('Edit Pipeline name')}
                        labelValidate={tr('Save')}
                    >
                        <Input
                            placeholder={tr('Pipeline name')}
                            value={editName}
                            onChange={(e) => setEditName(e.target.value)}
                        />
                    </Modal>
                    <Modal
                        ref={recruiterTemplateBoardModalRef}
                        validateCb={() => actions.createRecruiterBoard(boardName)}
                        title={tr('New Pipeline')}
                        labelValidate={tr('Create')}
                    >
                        <Input
                            label={tr('Name the Pipeline')}
                            placeholder={tr('Pipeline name')}
                            value={boardName}
                            onChange={(e) => setBoardName(e.target.value)}
                        />
                    </Modal>
                    <Modal
                        ref={salesTemplateBoardModalRef}
                        validateCb={() => actions.createSalesBoard(boardName)}
                        title={tr('Create a Pipeline')}
                        labelValidate={tr('Create')}
                    >
                        <Input
                            label={tr('Name the Pipeline')}
                            placeholder={tr('Pipeline name')}
                            value={boardName}
                            onChange={(e) => setBoardName(e.target.value)}
                        />
                    </Modal>
                    {boards?.length >= 1 && (
                        <>
                            <div className="board__header__section search">
                                <Input
                                    icon="search"
                                    isFilter
                                    className="board__search"
                                    isNoBorder
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                        debouncedSearch(e.target.value);
                                    }}
                                    value={searchText}
                                    placeholder={tr('Search by name or headline')}
                                    type="search"
                                    isFocusedDefault={false}
                                />
                            </div>
                            <div className="board__header__section fill labels">
                                <Icon light icon="label" />
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    placeholder={tr('Select labels')}
                                    className="board__label"
                                    classNamePrefix="react-select"
                                    onChange={onChangeLabels}
                                    components={{ Option: LabelOption }}
                                    options={labels?.map((label) => ({
                                        value: label.id,
                                        name: label.name,
                                        color: label.color,
                                        label: label.name,
                                    }))}
                                    styles={{
                                        multiValue: (styles, { data }) => ({
                                            ...styles,
                                            backgroundColor: Colors[data.color].light,
                                        }),
                                    }}
                                />
                                <LabelsList disableSelect />
                            </div>
                        </>
                    )}
                    <div className="board__header__section labels-manage">
                        <Labels context="topbar" disableSelect />
                    </div>
                    <BulkStatus />
                </div>
                <CampaignHeader campaign={board?.campaign} board={board} isFromBoard />
                {board ? (
                    <Board
                        t={createTranslate(TEXTS)}
                        editable
                        draggable
                        components={{ Card, AddCardLink: () => null, NewLaneForm, LaneHeader, Loader: () => null }}
                        data={getBoardData()}
                        canAddLanes
                        handleLaneDragEnd={(removedIndex, addedIndex, lane) => {
                            actions.updateLanePosition(board.id, lane.id, removedIndex, addedIndex);
                        }}
                        onLaneAdd={(newData) => {
                            actions.addLane(board.id, newData);
                        }}
                        onLaneDelete={(laneId) => {
                            actions.deleteLane(board.id, laneId);
                        }}
                        onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId, index) => {
                            actions.moveCard(board.id, fromLaneId, toLaneId, cardId, index);
                        }}
                        onLaneUpdate={(laneId, newData) => {
                            // eslint-disable-next-line no-param-reassign
                            newData.id = board.id;
                            actions.updateLane(board.id, laneId, newData);
                        }}
                        onLaneScroll={paginateLane}
                    />
                ) : (
                    <>
                        {!boardsLoading && (
                            <div className="board__empty">
                                <div className="empty">
                                    <Icon isEmpty icon="empty-board" size={360} />
                                    <h1>{tr('Create your first Pipeline')}</h1>
                                    <div className="btn--group-menu">
                                        <AddBoard context="header" />
                                        <Dropdown
                                            btnFace="primary"
                                            btnSize="small"
                                            onClick={(e) => e.stopPropagation()}
                                            onDoubleClick={(e) => e.stopPropagation()}
                                        >
                                            <Dropdown.Menu direction="s">
                                                <Dropdown.ButtonItem
                                                    btnLabel={tr('+ New Recruiter Pipeline')}
                                                    onClick={() => recruiterTemplateBoardModalRef.current.open()}
                                                />
                                                <Dropdown.ButtonItem
                                                    btnLabel={tr('+ New Sales Pipeline')}
                                                    onClick={() => salesTemplateBoardModalRef.current.open()}
                                                />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        )}
                        {boardsLoading && (
                            <div className="board__empty">
                                <Loader isChat text="Loading Pipeline..." />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
export default BoardPage;
