import { useRecoilValue, useSetRecoilState } from 'recoil';
import { tr } from '../../common/locale';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import useLnusersActions from '../../_actions/lnusers.actions';
import useLeadsActions from '../../_actions/leads.actions';
import { boardsState } from '../../_states/lnusers.states';
import { userState, upgradeState } from '../../_states/user.states';

function MoveBoard({ search, lnUser, lead, dir, originBtnType, originBtnIcon }) {
    const boards = useRecoilValue(boardsState);
    const currentLnUser = lnUser || lead?.lnuser;
    const actions = useLnusersActions();
    const leadsActions = useLeadsActions();
    const user = useRecoilValue(userState);
    const setUpgrade = useSetRecoilState(upgradeState);

    const addBoard = (board, step) => {
        if (lead) {
            leadsActions.toLnUser(search, lead, board, step);
        } else if (currentLnUser) {
            actions.addboard(currentLnUser, board?.id, step);
        }
    };
    let currentBoard = null;
    let currentStep = null;
    if (boards && currentLnUser) {
        currentBoard = boards.find((item) => item.id === currentLnUser.board);
        if (currentBoard) {
            currentStep = currentBoard?.lanes.find((item) => parseInt(item.id, 10) === currentLnUser.step);
        }
    }
    return (
        <>
            {!currentLnUser?.board && boards?.length <= 1 && (
                <Button
                    className="btn--board"
                    type={!originBtnType ? 'link' : originBtnType}
                    label={originBtnIcon ? tr('Add to Pipeline') : tr('+ Add to Pipeline')}
                    icon={originBtnIcon ? 'board-plus' : null}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (user?.plan.product === 'SCRAPE') {
                            setUpgrade(true);
                        } else {
                            addBoard(boards?.length === 1 ? boards[0] : undefined);
                        }
                    }}
                />
            )}
            {boards?.filter((elem) => !elem.campaign).length >= 1 && currentLnUser?.board && (
                <div className="btn--group">
                    <Dropdown
                        iconRight
                        btnFaceIconSize={16}
                        btnFace="secondary-dark"
                        btnClassName="btn--group-item"
                        label={currentBoard?.name}
                        onClick={(e) => e.stopPropagation()}
                        onDoubleClick={(e) => e.stopPropagation()}
                    >
                        <Dropdown.Menu direction={dir}>
                            {boards
                                ?.filter((elem) => !elem.campaign)
                                .map((element) => (
                                    <Dropdown.ButtonItem
                                        key={element?.id}
                                        btnLabel={element.name || tr('+ Add to Pipeline')}
                                        onClick={() => {
                                            if (user?.plan.product === 'SCRAPE') {
                                                setUpgrade(true);
                                            } else {
                                                addBoard(element);
                                            }
                                        }}
                                    />
                                ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown
                        iconRight
                        btnFaceIconSize={16}
                        btnFace="secondary-dark"
                        btnClassName="btn--group-item"
                        label={currentStep?.title}
                        onClick={(e) => e.stopPropagation()}
                        onDoubleClick={(e) => e.stopPropagation()}
                    >
                        <Dropdown.Menu direction={dir}>
                            {currentBoard?.lanes
                                ?.map((element) => element)
                                .sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))
                                .map((element) => (
                                    <Dropdown.ButtonItem
                                        key={element?.id}
                                        btnLabel={element.title}
                                        onClick={() => {
                                            if (user?.plan.product === 'SCRAPE') {
                                                setUpgrade(true);
                                            } else {
                                                addBoard(currentBoard, element.id);
                                            }
                                        }}
                                    />
                                ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )}

            {boards?.length > 1 && !currentLnUser?.board && (
                <Dropdown
                    className="btn--board"
                    btnFace={!originBtnType ? 'link' : originBtnType}
                    label={originBtnIcon ? tr('Add to Pipeline') : tr('+ Add to Pipeline')}
                    btnFaceIcon={originBtnIcon ? 'board-plus' : null}
                    onClick={(e) => e.stopPropagation()}
                    onDoubleClick={(e) => e.stopPropagation()}
                >
                    <Dropdown.Menu direction={dir}>
                        {boards
                            ?.filter((elem) => !elem.campaign)
                            .map((element) => (
                                <Dropdown.ButtonItem
                                    key={element?.id}
                                    btnLabel={element.name || tr('+ Add to Pipeline')}
                                    onClick={() => {
                                        if (user?.plan.product === 'SCRAPE') {
                                            setUpgrade(true);
                                        } else {
                                            addBoard(element);
                                        }
                                    }}
                                />
                            ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
}
export default MoveBoard;
