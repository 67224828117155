import React, { Component } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import cl from 'classnames';
import CreatableSelect from 'react-select/creatable';

/**
 * https://react-select.com/styles#styles
 *
 */
export default class Select extends Component {
    renderInput = (props) => {
        const { error, isCreatable, color, ...rest } = props;

        return isCreatable ? (
            <CreatableSelect
                className={cl('select__item', color ? `select__item--${color}` : null, {
                    error,
                })}
                classNamePrefix="select"
                {...rest}
            />
        ) : (
            <ReactSelect
                className={cl('select__item', {
                    error,
                })}
                classNamePrefix="select"
                {...rest}
            />
        );
    };

    render() {
        const { size, className, error, label, hideLabel, icon, ...rest } = this.props;
        const errorComponent = error ? <span className="select__error">{error}</span> : null;
        const classesContainer = cl('select', { 'select--iconed': icon }, `select--${size}`, className);
        return (
            <div className={classesContainer}>
                <div className="select__line">
                    {hideLabel ? (
                        this.renderInput({ error, size, ...rest })
                    ) : (
                        <>
                            {label && (
                                <label className="select__label" htmlFor={this.inputId}>
                                    {label}
                                </label>
                            )}
                            {this.renderInput({ error, size, ...rest })}
                        </>
                    )}
                    {icon}
                    {errorComponent}
                </div>
            </div>
        );
    }
}

Select.defaultProps = {
    size: 'normal',
    className: null,
    hideLabel: false,
    icon: null,
    label: null,
    error: null,
    isCreatable: false,
    color: undefined,
};
Select.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['large', 'normal', 'small', 'mini']),
    label: PropTypes.string,
    error: PropTypes.string,
    hideLabel: PropTypes.bool,
    icon: PropTypes.string,
    isCreatable: PropTypes.bool,
    color: PropTypes.oneOf(['bleu', 'mauve', 'green', 'grey', 'orange', 'red', 'white', undefined, '']),
};
