import avatar from '../images/avatar.svg';

const Avatar = ({ src, alt, className }) => {
    const handleImgLoadingError = (e) => {
        // default image add, if associated image is not available...
        e.target.src = avatar;
    };

    return <img src={src || avatar} className={className} alt={alt} onError={(e) => handleImgLoadingError(e)} />;
};

export default Avatar;
