import { useRecoilValue } from 'recoil';
import { Outlet, NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { userState, notifsState, affiliateAccountState } from '../../_states/user.states';
import Avatar from '../../components/Avatar';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import { tr, getLocale } from '../../common/locale';
import useUserActions from '../../_actions/user.actions';
import utils from '../../common/utils';
import 'rc-slider/assets/index.css';

function Settings({ children }) {
    const deleteRef = useRef();
    const user = useRecoilValue(userState);
    const [deleteVal, setDeleteVal] = useState('');
    const notifs = useRecoilValue(notifsState);
    const actions = useUserActions();
    const affiliateAccount = useRecoilValue(affiliateAccountState);
    const isTeamOwner = user && user.team && user.team.owner === user.id;
    const isAgencyClient = !isTeamOwner && user && user.team && user.team.is_agency;
    useEffect(() => {
        actions.refreshMe();
        actions.affiliateAccount();
    }, []);

    return (
        <div className="content settings">
            <div className="content__sidebar content__sidebar--large">
                <div className="content__sidebar__header">
                    <div className="content__sidebar__header__title">
                        <h1 className="title">{tr('Settings')}</h1>
                    </div>
                </div>
                <div className="content__sidebar__body">
                    <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">{tr('General')}</h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            <li>
                                <NavLink
                                    className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                    to="/settings/consumption"
                                >
                                    <Icon size={20} icon="chart" />
                                    <span>{tr('Consumption')}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                    to="/settings/quotas"
                                >
                                    <Icon size={20} icon="equalizer" />
                                    <span>{tr('Imports and automations limits')}</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    {!isAgencyClient && (
                        <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                            <div className="content__sidebar__body__section__header">
                                <h5 className="title">{tr('Team')}</h5>
                            </div>
                            <ul className="content__sidebar__body__section__filters">
                                <li>
                                    <NavLink
                                        className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                        to="/settings/team"
                                    >
                                        <Icon size={20} icon="company" />
                                        <span>{tr('Team members')}</span>
                                    </NavLink>
                                </li>
                                {isTeamOwner && (
                                    <li>
                                        <NavLink
                                            className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                            to="/settings/teamoptions"
                                        >
                                            <Icon size={20} icon="link" />
                                            <span>{tr('Link & options')}</span>
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                    {utils.automationsHasAccess(user) && (
                        <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                            <div className="content__sidebar__body__section__header">
                                <h5 className="title">{tr('Automations')}</h5>
                            </div>
                            <ul className="content__sidebar__body__section__filters">
                                <li>
                                    <NavLink
                                        className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                        to="/settings/schedule"
                                    >
                                        <Icon size={20} icon="clock" />
                                        <span>{tr('Scheduler')}</span>
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                        to="/settings/automation"
                                    >
                                        <Icon size={20} icon="cloud" />
                                        <span>{tr('Cloud-based / Browser')}</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">{tr('Affiliate Program')}</h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            <li>
                                <NavLink
                                    className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                    to="/settings/affiliate"
                                >
                                    <Icon size={20} icon="affiliate" />
                                    <span>{tr('Affiliates')}</span>
                                </NavLink>
                            </li>
                            {(affiliateAccount?.url || user?.stripe_connected_account === 'noaccount') && (
                                <li>
                                    <NavLink
                                        className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                        to="/settings/affiliateoptions"
                                    >
                                        <Icon size={20} icon="link" />
                                        <span>{tr('Link & options')}</span>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">{tr('Integrations')}</h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            <li>
                                <NavLink
                                    className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                    to="/settings/webhooks"
                                >
                                    <Icon size={20} icon="webhook" />
                                    <span>{tr('Webhooks')}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                    to="/settings/api"
                                >
                                    <Icon size={20} icon="sync" />
                                    <span>{tr('API')}</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="content__sidebar__body__section content__sidebar__body__section--no-border">
                        <div className="content__sidebar__body__section__header">
                            <h5 className="title">{tr('Misc')}</h5>
                        </div>
                        <ul className="content__sidebar__body__section__filters">
                            <li>
                                <NavLink
                                    target="_blank"
                                    className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                    to={
                                        getLocale() === 'fr' ? 'https://help.kanbox.io/fr' : 'https://help.kanbox.io/en'
                                    }
                                >
                                    <Icon size={20} icon="book" />
                                    <span>{tr('Knowledge base')}</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="btn btn--has-icon btn--secondary btn--custom-icon-size"
                                    to="/logout"
                                >
                                    <Icon size={20} icon="logout" />
                                    <span>{tr('Log out')}</span>
                                </NavLink>
                            </li>
                            <li>
                                <Button
                                    type="secondary"
                                    icon="delete"
                                    label={tr('Delete my account')}
                                    onClick={() => deleteRef.current.open()}
                                />
                                <Modal
                                    closesWithEscape
                                    ref={deleteRef}
                                    title={tr('Are you sure you want to delete your Kanbox account?')}
                                    mainButtonType="important"
                                    disabled={deleteVal !== 'DELETE'}
                                    validateCb={() => actions.deleteAccount()}
                                    labelValidate={tr('Delete my account')}
                                >
                                    <p className="fs-large mb--10">
                                        {tr(
                                            'It will definitely delete all your Kanbox data (labels, pipelines, leads...).'
                                        )}
                                    </p>
                                    <p className="bold fs-large mb--20">
                                        {tr('Type in "DELETE" and validate to delete your account:')}
                                    </p>
                                    <Input
                                        placeholder={tr('Type in "DELETE"')}
                                        value={deleteVal}
                                        onChange={(e) => setDeleteVal(e.target.value)}
                                    />
                                </Modal>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="settings__card">
                    <Avatar className="settings__avatar" src={user?.picture} />
                    <div className="settings__detail">
                        <div className="settings__name">
                            {user?.firstname}&nbsp;
                            {user?.lastname}
                        </div>
                    </div>
                    <div className="settings__detail">
                        <span className="settings__position" title={user?.occupation}>
                            {user?.occupation}
                        </span>
                    </div>
                    <div className="settings__detail">
                        <span className="ellipsis" title={user?.email}>
                            {user?.email}
                        </span>
                    </div>
                    <div className="settings__detail network">
                        <div className="box box--relation">
                            <span className="box__value" title={notifs?.relations}>
                                {notifs?.relations}
                            </span>
                            <h5 className="box__label">{tr('Connections')}</h5>
                        </div>
                        <div className="box box--relation">
                            <span className="box__value" title={notifs?.contacts}>
                                {notifs?.contacts}
                            </span>
                            <h5 className="box__label">{tr('Contacts')}</h5>
                        </div>
                    </div>
                </div>
            </div>
            {children || <Outlet />}
        </div>
    );
}
export default Settings;
