import React, { Component } from 'react';
import cl from 'classnames';
import PropTypes from 'prop-types';
import Button from './Button';

export default class ServiceMessage extends Component {
    render() {
        const { closeCb, type, text, testId, ...rest } = this.props;
        const classes = cl('service-message', type ? `service-message--${type}` : null);
        return (
            <div data-testid={testId} className={classes} {...rest}>
                <div className="service-message__text" data-testid={`${testId}-text`}>
                    {text}
                </div>
                <Button icon="close" type="link" onClick={closeCb} testId={`${testId}-close-button`} />
            </div>
        );
    }
}

ServiceMessage.defaultProps = {
    type: 'error',
    text: '',
    testId: 'service-message',
};

ServiceMessage.propTypes = {
    type: PropTypes.oneOf([undefined, 'success', 'error', 'warning', 'info', 'version']),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    closeCb: PropTypes.func.isRequired,
    testId: PropTypes.string,
};
