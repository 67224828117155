/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import cl from 'classnames';
import useLabelActions from '../../_actions/label.actions';
import useLnUserActions from '../../_actions/lnuser.actions';
import Label from '../../components/Label';
import Button from '../../components/Button';
import ConfirmButton from '../../components/ConfirmButton';
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import Popover from '../../components/Popover';
import Tooltip from '../../components/Tooltip';
import ConfirmationPopover from '../../components/ConfirmationPopover';
import LabelsList, { Colors } from './LabelsList';
import { tr } from '../../common/locale';
import { labelsState, loadingState } from '../../_states/label.states';
import { userState, upgradeState } from '../../_states/user.states';
import { loadingLabelsState } from '../../_states/lnuser.states';

function Labels({
    lnUser,
    context,
    disableSelect,
    placement,
    updateLnUser,
    isNoLabel,
    isNoBg,
    isLabelTooltip,
    isClickable,
    isClosable,
}) {
    const actions = useLabelActions();
    const lnUserActions = useLnUserActions();
    const selectedLabels = lnUser?.labels ? [...lnUser.labels] : [];
    const labels = useRecoilValue(labelsState);
    const [search, setSearch] = useState('');
    const user = useRecoilValue(userState);
    const setUpgrade = useSetRecoilState(upgradeState);
    const loading = useRecoilValue(loadingState);
    const loadingUpdate = useRecoilValue(loadingLabelsState);
    const [color, setColor] = useState('color1');
    const [editLabel, setEditLabel] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const [title, setTitle] = useState('');
    const ref = useRef();
    const confirmDeleteRef = useRef();
    useEffect(() => {
        if (!labels) {
            actions.get();
        }
    }, []);

    useEffect(() => {
        if (editLabel) {
            setTitle(editLabel.name);
            setColor(editLabel.color);
        }
    }, [editLabel]);
    const resetEdit = () => {
        setEditLabel(null);
        setIsCreate(false);
    };
    const toggleAddLabel = (add, label) => {
        const currentLabels = [];
        lnUser?.labels?.forEach((item) => {
            if (labels?.find((lab) => lab.id === item.id)) {
                currentLabels.push(item.id);
            }
        });
        if (add) {
            if (currentLabels.indexOf(label.id) === -1) {
                currentLabels.push(label.id);
            }
            if (lnUser) {
                lnUserActions.addLabel(lnUser.id, currentLabels, updateLnUser);
            }
        } else {
            if (currentLabels.indexOf(label.id) !== -1) {
                currentLabels.splice(currentLabels.indexOf(label.id), 1);
            }
            lnUserActions.deleteLabel(lnUser.id, currentLabels, updateLnUser);
        }
    };
    const removeAllLabels = () => {
        lnUserActions.deleteLabel(lnUser.id, [], updateLnUser);
    };
    const renderContent = () => (
        <>
            {!isCreate && !editLabel && (
                <div className="labels-popover__wrapper" onKeyDown={() => null} onClick={(e) => e.stopPropagation()}>
                    <div className="labels-popover__header">
                        <h1 className="labels-popover__header__title">{tr('Labels')}</h1>
                        {labels?.length > 7 && (
                            <Input
                                type="search"
                                isFilter
                                placeholder={tr('Search a label')}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        )}
                    </div>
                    <div className="labels-popover__body">
                        {loading && <Icon className="loading-icon" fill icon="load" />}
                        {labels
                            ?.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                            .map((label) => (
                                <div key={label.id} className="labels-popover__label">
                                    {!disableSelect && (
                                        <Input
                                            type="checkbox"
                                            onChange={(e) => toggleAddLabel(e.target.checked, label)}
                                            checked={!!selectedLabels.find((selected) => selected.id === label.id)}
                                        />
                                    )}
                                    <Label
                                        label={label.name}
                                        color={Colors[label.color].light}
                                        iconColor={Colors[label.color].dark}
                                        closeCb={() =>
                                            toggleAddLabel(
                                                !selectedLabels.find((selected) => selected.id === label.id),
                                                label
                                            )
                                        }
                                        onClick={() =>
                                            !disableSelect
                                                ? toggleAddLabel(
                                                      !selectedLabels.find((selected) => selected.id === label.id),
                                                      label
                                                  )
                                                : setEditLabel(label)
                                        }
                                    />
                                    <Button
                                        icon="pen"
                                        iconSize={16}
                                        type="link"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setEditLabel(label);
                                        }}
                                    />
                                </div>
                            ))}
                    </div>
                    <div className="labels-popover__actions">
                        <Button
                            type="white"
                            isBordered
                            icon="plus"
                            iconSize={16}
                            label={tr('New Label')}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsCreate(true);
                            }}
                        />
                    </div>
                    <div className="labels-popover__footer">
                        <Button
                            iconSize={16}
                            label={tr('Close')}
                            icon={loadingUpdate === lnUser?.id ? 'load' : 'close'}
                            onClick={(e) => {
                                e.stopPropagation();
                                ref?.current?.toggle();
                            }}
                        />
                    </div>
                </div>
            )}

            {(isCreate || editLabel) && (
                <div className="labels-popover__form" onKeyDown={() => null} onClick={(e) => e.stopPropagation()}>
                    <div className="labels-popover__form__item">
                        <Input value={title} label={tr('Type in a title')} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className="labels-popover__form__item">
                        <p>{tr('Select a color')}</p>
                        <div className="labels__color-piker">
                            {Object.keys(Colors).map((currentColor, index) => (
                                <div
                                    role="button"
                                    tabIndex={index}
                                    key={currentColor}
                                    className={cl('labels__color', {
                                        'labels__color--selected': currentColor === color,
                                    })}
                                    style={{ backgroundColor: Colors[currentColor].dark }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setColor(currentColor);
                                    }}
                                    onKeyDown={() => null}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="labels-popover__delete">
                        {editLabel && (
                            <ConfirmationPopover
                                ref={confirmDeleteRef}
                                text={tr('Are you sure you want to delete this label?')}
                                confirmCb={() => {
                                    actions.deleteLabel(editLabel.id);
                                    setEditLabel(null);
                                    setTitle('');
                                    setColor('color1');
                                    setIsCreate(false);
                                }}
                            >
                                <Button
                                    icon="trash-can"
                                    label={tr('Delete ') + title}
                                    iconSize={16}
                                    type="secondary-dark"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        confirmDeleteRef.current.toggle();
                                    }}
                                />
                            </ConfirmationPopover>
                        )}
                    </div>
                    <div className="labels-popover__footer">
                        <Button
                            type="secondary-dark"
                            label={tr('Cancel')}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (editLabel) {
                                    setEditLabel(null);
                                }
                                setTitle('');
                                setColor('color1');
                                setIsCreate(false);
                            }}
                        />
                        <Button
                            type="primary"
                            disabled={title === ''}
                            label={editLabel ? tr('Update') : tr('Create')}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (editLabel) {
                                    actions.update(editLabel.id, title, color);
                                    setEditLabel(null);
                                } else {
                                    actions.add(title, color).then((data) => toggleAddLabel(true, data));
                                }
                                setTitle('');
                                setColor('color1');
                                setIsCreate(false);
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    );

    return (
        <Popover
            ref={ref}
            placement={placement}
            contentClassName="labels-popover"
            content={renderContent()}
            closeCb={resetEdit}
        >
            <div
                className="labels__wrapper"
                role="button"
                tabIndex={0}
                onKeyDown={() => null}
                onClick={
                    isClickable
                        ? (e) => {
                              e.stopPropagation();
                              ref?.current?.toggle();
                          }
                        : null
                }
            >
                {context === 'list-nolabel' && (
                    <Button
                        className="btn--label"
                        type="link"
                        label={tr('+ Labels')}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (user?.plan.product === 'SCRAPE') {
                                setUpgrade(true);
                            } else {
                                ref?.current?.toggle();
                            }
                        }}
                    />
                )}
                {context === 'modal-nolabel' && (
                    <div className="lnuser__labels__header lnuser__labels__header--special">
                        <h5>{tr('Labels')}</h5>
                        <Button
                            className="btn--label"
                            type="secondary-dark"
                            icon="label-add"
                            label={tr('Add labels')}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (user?.plan.product === 'SCRAPE') {
                                    setUpgrade(true);
                                } else {
                                    ref?.current?.toggle();
                                }
                            }}
                        />
                    </div>
                )}
                {context === 'btn-addlabel' && (
                    <Button
                        type="link-primary"
                        label={tr('Manage labels')}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (user?.plan.product === 'SCRAPE') {
                                setUpgrade(true);
                            } else {
                                ref?.current?.toggle();
                            }
                        }}
                    />
                )}
                {context === 'modal' && (
                    <div className="lnuser__labels__header">
                        <h5>{tr('Labels')}</h5>
                        <span>
                            <Tooltip direction="nw" text={tr('Add labels')}>
                                <Button
                                    icon="plus"
                                    size="small"
                                    type="transparent"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (user?.plan.product === 'SCRAPE') {
                                            setUpgrade(true);
                                        } else {
                                            ref?.current?.toggle();
                                        }
                                    }}
                                />
                            </Tooltip>
                            <Tooltip direction="nw" text={tr('Remove all labels')}>
                                <ConfirmButton
                                    icon="trash-can"
                                    size="small"
                                    type="transparent"
                                    confirmLabel={tr('Are you sure you want to delete all the labels?')}
                                    onConfirm={() => removeAllLabels()}
                                />
                            </Tooltip>
                        </span>
                    </div>
                )}
                {context === 'filter' && (
                    <Button
                        icon="label-add"
                        iconSize={20}
                        type="link"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (user?.plan.product === 'SCRAPE') {
                                setUpgrade(true);
                            } else {
                                ref?.current?.toggle();
                            }
                        }}
                    />
                )}
                {context === 'topbar' && (
                    <Tooltip direction="w" text={tr('Manage labels')}>
                        <Button
                            iconLight
                            type="link"
                            icon="label-manage"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (user?.plan.product === 'SCRAPE') {
                                    setUpgrade(true);
                                } else {
                                    ref?.current?.toggle();
                                }
                            }}
                        />
                    </Tooltip>
                )}
                <LabelsList
                    isClosable={isClosable}
                    isNoBg={isNoBg}
                    isNoLabel={isNoLabel}
                    isLabelTooltip={isLabelTooltip}
                    closeLabel={(label) => toggleAddLabel(false, label)}
                    selectedLabels={lnUser?.labels || []}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (user?.plan.product === 'SCRAPE') {
                            setUpgrade(true);
                        } else {
                            ref?.current?.toggle();
                        }
                    }}
                />
            </div>
        </Popover>
    );
}

export default Labels;
