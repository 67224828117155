import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from './Popover';
import Button from './Button';
import { tr } from '../common/locale';

export default class ConfirmationPopover extends Component {
    constructor(props) {
        super(props);
        this.popoverRef = React.createRef();
    }

    confirm = (e) => {
        if (e) {
            e.stopPropagation();
        }
        const { confirmCb } = this.props;
        confirmCb(e);
        this.popoverRef.current.close();
    };

    close = (e) => {
        if (e) {
            e.stopPropagation();
        }
        this.popoverRef.current.close();
    };

    toggle = () => {
        this.popoverRef.current.toggle();
    };

    renderContent = () => {
        const { text, testId } = this.props;
        return (
            <div className="popover__content-box">
                <div className="popover__message">{text}</div>
                <div className="popover__footer ignore-click-outside">
                    <Button label={tr('Cancel')} testId={`${testId}-cancel-button`} type="link" onClick={this.close} />
                    <Button
                        label={tr('Confirm')}
                        type="important"
                        testId={`${testId}-confirm-button`}
                        onClick={this.confirm}
                    />
                </div>
            </div>
        );
    };

    render() {
        const { children, container, placement } = this.props;
        return (
            <Popover ref={this.popoverRef} content={this.renderContent()} container={container} placement={placement}>
                {children}
            </Popover>
        );
    }
}

ConfirmationPopover.defaultProps = {
    testId: 'confirm-popover',
    container: null,
    placement: 'bottom-center',
};

ConfirmationPopover.propTypes = {
    text: PropTypes.node.isRequired,
    testId: PropTypes.string,
    placement: PropTypes.string,
    children: PropTypes.node.isRequired,
    container: PropTypes.string,
    confirmCb: PropTypes.func.isRequired,
};
