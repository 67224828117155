import { atom } from 'recoil';

export const userState = atom({
    key: 'userState',
    default: null,
});

export const plansState = atom({
    key: 'plansState',
    default: null,
});

export const upgradeState = atom({
    key: 'upgradeState',
    default: false,
});

export const saveEmailLoadingState = atom({
    key: 'saveEmailLoadingState',
    default: false,
});

export const saveEmailDoneState = atom({
    key: 'saveEmailDoneState',
    default: false,
});

export const savingQuotasState = atom({
    key: 'savingQuotasState',
    default: false,
});

export const quotasState = atom({
    key: 'quotasState',
    default: {},
});

export const teamUsersState = atom({
    key: 'teamUsersState',
    default: null,
});

export const teamUsersLoadingState = atom({
    key: 'teamUsersLoadingState',
    default: false,
});

export const subscriptionsState = atom({
    key: 'subscriptionsState',
    default: null,
});

export const creditsState = atom({
    key: 'creditsState',
    default: null,
});

export const stripeState = atom({
    key: 'stripeState',
    default: null,
});

export const previewSubscriptionUpdateState = atom({
    key: 'previewSubscriptionUpdateState',
    default: null,
});

export const previewSubscriptionUpdateLoadingState = atom({
    key: 'previewSubscriptionUpdateLoadingState',
    default: false,
});

export const changeSubscriptionUserState = atom({
    key: 'changeSubscriptionUserState',
    default: null,
});

export const affiliateUsersState = atom({
    key: 'affiliateUsersState',
    default: null,
});

export const affiliateProgramState = atom({
    key: 'affiliateProgramState',
    default: null,
});

export const shareSearchesLoadingState = atom({
    key: 'shareSearchesLoadingState',
    default: false,
});

export const affiliateUsersLoadingState = atom({
    key: 'affiliateUsersLoadingState',
    default: false,
});

export const invoicesState = atom({
    key: 'invoicesState',
    default: null,
});

export const affiliateLinkLoadingState = atom({
    key: 'affiliateLinkLoadingState',
    default: false,
});

export const affiliateLinkState = atom({
    key: 'affiliateLinkState',
    default: null,
});

export const affiliateAccountLoadingState = atom({
    key: 'affiliateAccountLoadingState',
    default: false,
});

export const affiliateAccountState = atom({
    key: 'affiliateAccountState',
    default: null,
});

export const firstSyncState = atom({
    key: 'firstSyncState',
    default: false,
});

export const errorAppsumoState = atom({
    key: 'errorAppsumoState',
    default: false,
});

export const successAppsumoState = atom({
    key: 'successAppsumoState',
    default: false,
});

export const usersState = atom({
    key: 'usersState',
    default: null,
});

export const loadingUsersState = atom({
    key: 'loadingUsersState',
    default: true,
});

export const filterUsersState = atom({
    key: 'filterUsersState',
    default: '',
});

export const filterTypesState = atom({
    key: 'filterTypesState',
    default: 'all',
});

export const extensionState = atom({
    key: 'extensionState',
    default: undefined,
});

export const authState = atom({
    key: 'authState',
    default: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : '',
});

export const notifsState = atom({
    key: 'notifsState',
    default: {},
});

export const initialConvSyncState = atom({
    key: 'initialConvSyncState',
    default: false,
});
