import React, { Component } from 'react';
import cl from 'classnames';
import PropTypes from 'prop-types';
import Icon from './Icon';

export default class Flash extends Component {
    render() {
        const { children, icon, fill, wrap, color, testId, className, inverse, ...rest } = this.props;
        const iconComponent = icon ? <Icon size={22} icon={icon} /> : null;
        const classes = cl(
            'flash',
            `flash--${color}`,
            { 'flash--iconed': iconComponent },
            { 'flash--fill': fill },
            { 'flash--wrap': wrap },
            className
        );
        return (
            <div className={classes} data-testid={testId} {...rest}>
                {iconComponent}
                <span className="flash-content">{children}</span>
            </div>
        );
    }
}

Flash.defaultProps = {
    color: 'blue',
    icon: '',
    inverse: false,
    fill: false,
    testId: 'flash',
    className: '',
    wrap: true,
};

Flash.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.oneOf(['blue', 'green', 'orange', 'red', 'yellow', 'light']),
    icon: PropTypes.string,
    inverse: PropTypes.bool,
    fill: PropTypes.bool,
    wrap: PropTypes.bool,
    testId: PropTypes.string,
    className: PropTypes.string,
};
