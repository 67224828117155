import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const handlerNameByEvent = {
    'ps-scroll-y': 'onScrollY',
    'ps-scroll-x': 'onScrollX',
    'ps-scroll-up': 'onScrollUp',
    'ps-scroll-down': 'onScrollDown',
    'ps-scroll-left': 'onScrollLeft',
    'ps-scroll-right': 'onScrollRight',
    'ps-y-reach-start': 'onYReachStart',
    'ps-y-reach-end': 'onYReachEnd',
    'ps-x-reach-start': 'onXReachStart',
    'ps-x-reach-end': 'onXReachEnd',
};

export default class ScrollBar extends Component {
    constructor(props) {
        super(props);
        this.handlerByEvent = {};
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        const { options } = this.props;
        this.ps = new PerfectScrollbar(this.containerRef.current, {
            wheelPropagation: true,
            ...options,
        });

        // hook up events
        this.updateEventHook();
        this.updateClassName();
    }

    componentDidUpdate(prevProps) {
        this.updateEventHook(prevProps);

        this.ps.update();
        const { className } = this.props;
        if (prevProps.className !== className) {
            this.updateClassName();
        }
    }

    componentWillUnmount() {
        Object.keys(this.handlerByEvent).forEach((key) => {
            const value = this.handlerByEvent[key];

            if (value) {
                this.containerRef.current.removeEventListener(key, value, false);
            }
        });
        this.handlerByEvent = {};
        this.ps.destroy();
        this.ps = null;
    }

    updateEventHook = (prevProps = {}) => {
        Object.keys(handlerNameByEvent).forEach((key) => {
            // eslint-disable-next-line react/destructuring-assignment
            const callback = this.props[handlerNameByEvent[key]];
            const prevCallback = prevProps[handlerNameByEvent[key]];
            if (callback !== prevCallback) {
                if (prevCallback) {
                    const prevHandler = this.handlerByEvent[key];
                    this.containerRef.current.removeEventListener(key, prevHandler, false);
                    this.handlerByEvent[key] = null;
                }
                if (callback) {
                    const handler = () => callback(this.containerRef.current);
                    this.containerRef.current.addEventListener(key, handler, false);
                    this.handlerByEvent[key] = handler;
                }
            }
        });
    };

    updateClassName = () => {
        const { className } = this.props;

        let psClassNames = this.containerRef.current.className
            .split(' ')
            .filter((name) => name.match(/^ps([-_].+|)$/))
            .join(' ');
        psClassNames = psClassNames ? ` ${psClassNames}` : '';

        if (this.containerRef.current) {
            const strClassName = className ? ` ${className}` : '';
            this.containerRef.current.className = `scrollbar-container${strClassName}${psClassNames}`;
        }
    };

    render() {
        const {
            className,
            style,
            onScrollY,
            onScrollX,
            onScrollUp,
            onScrollDown,
            onScrollLeft,
            onScrollRight,
            onYReachStart,
            onYReachEnd,
            onXReachStart,
            onXReachEnd,
            component,
            onSync,
            children,
            testId,
            ...rest
        } = this.props;
        const Comp = component;

        return (
            <Comp className={className} style={style} ref={this.containerRef} data-testid={testId} {...rest}>
                {children}
            </Comp>
        );
    }
}

ScrollBar.defaultProps = {
    testId: 'scrollbar',
    className: '',
    style: {},
    options: undefined,
    onScrollY: undefined,
    onScrollX: undefined,
    onScrollUp: undefined,
    onScrollDown: undefined,
    onScrollLeft: undefined,
    onScrollRight: undefined,
    onYReachStart: undefined,
    onYReachEnd: undefined,
    onXReachStart: undefined,
    onXReachEnd: undefined,
    onSync: (ps) => ps.update(),
    component: 'div',
};

ScrollBar.propTypes = {
    component: PropTypes.string,
    testId: PropTypes.string,
    style: PropTypes.object,
    options: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    onScrollY: PropTypes.func,
    onScrollX: PropTypes.func,
    onScrollUp: PropTypes.func,
    onScrollDown: PropTypes.func,
    onScrollLeft: PropTypes.func,
    onScrollRight: PropTypes.func,
    onYReachStart: PropTypes.func,
    onYReachEnd: PropTypes.func,
    onXReachStart: PropTypes.func,
    onXReachEnd: PropTypes.func,
    onSync: PropTypes.func,
};
