import { useRecoilValue, useRecoilState } from 'recoil';
import { useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { userState, quotasState } from '../../_states/user.states';
import { tr } from '../../common/locale';
import Switch from '../../components/Switch';
import Flash from '../../components/Flash';
import useUserActions from '../../_actions/user.actions';

function Settings() {
    const user = useRecoilValue(userState);
    const actions = useUserActions();
    const [quotas, setQuotas] = useRecoilState(quotasState);
    const saveQuotas = () => {
        actions.updateQuotas(false, tr('Cloud setting saved'), tr('Could not save cloud setting'));
    };
    const debouncedSaveQuotas = useCallback(debounce(saveQuotas, 300), []);
    useEffect(() => {
        actions.refreshMe();
    }, []);
    useEffect(() => {
        if (user) {
            setQuotas(user.quotas);
        }
    }, [user]);
    return (
        <div className="content__body automation-mode">
            <div className="settings__header">
                <h2 className="title">{tr('Automations modes')}</h2>
            </div>
            {!quotas.allow_cloud && (
                <div className="settings__content">
                    <p className="mb--30">{tr('Cloud based automations will be available soon.')}</p>

                    <Switch id="automationMode" disabled text={tr('Switch from cloud-based to browser mode')} />
                </div>
            )}
            {quotas.allow_cloud && (
                <div className="settings__content">
                    <p className="mb--30">
                        {tr('Cloud based automations is in beta. Do not hesitate to give us feedback.')}
                    </p>
                    <Flash color="blue" className="mb--30">
                        {tr(
                            "Once enabled, campaigns will continue to run in the browser when you're online, and will switch to cloud if not."
                        )}
                    </Flash>
                    <Switch
                        id="cloud"
                        text={tr('Cloud-based automations')}
                        checked={quotas.enable_cloud}
                        onChange={() => {
                            setQuotas({ ...quotas, enable_cloud: !quotas.enable_cloud });
                            debouncedSaveQuotas();
                        }}
                    />
                </div>
            )}
        </div>
    );
}
export default Settings;
