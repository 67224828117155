import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import moment from 'moment';
import 'moment/locale/fr';
import PrivateRoute from './common/PrivateRoute';
import Main from './common/Main';

import './styles/style.sass';
import 'cropperjs/dist/cropper.css';

import Connections from './pages/connections/connections';
import Templates from './pages/templates/templates';
import Subscription from './pages/subscription/subscription';
import Appsumo from './pages/subscription/appsumo';
import Settings from './pages/settings/Settings';
import Schedule from './pages/settings/Schedule';
import Api from './pages/settings/Api';
import TeamOptions from './pages/settings/TeamOptions';
import Automation from './pages/settings/Automation';
import Quotas from './pages/settings/Quotas';
import Webhooks from './pages/settings/Webhooks';
import Consumption from './pages/settings/Consumption';
import Team from './pages/settings/Team';
import Searches from './pages/leads/searches';
import Affiliate from './pages/settings/affiliate';
import AffiliateOptions from './pages/settings/AffiliateOptions';
import Leads from './pages/leads/leads';
import Admin from './pages/admin/Admin';
import Campaigns from './pages/campaigns/campaigns';
import Board from './pages/board/Board';
import LoginLinkedin from './pages/LoginLinkedIn';
import ConfirmEmail from './pages/ConfirmEmail';
import Logout from './pages/Logout';
import Delete from './pages/Delete';
import history from './_helpers/history.helpers';
import { changeLanguage } from './common/locale';

function App() {
    useEffect(() => {
        if (navigator.language === 'fr' || navigator.language.indexOf('fr-') === 0) {
            changeLanguage('fr');
            moment.locale('fr');
        } else {
            moment.locale('en');
        }
    }, []);
    return (
        <Router location={history.location} navigator={history}>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <Routes>
                    <Route element={<Main />}>
                        <Route element={<PrivateRoute />}>
                            <Route path="/" element={<Connections />} />
                            <Route path="/settings" element={<Settings />}>
                                <Route index element={<Navigate to="/settings/consumption" replace />} />
                                <Route path="/settings/consumption" element={<Consumption />} />
                                <Route path="/settings/quotas" element={<Quotas />} />
                                <Route path="/settings/webhooks" element={<Webhooks />} />
                                <Route path="/settings/team" element={<Team />} />
                                <Route path="/settings/teamoptions" element={<TeamOptions />} />
                                <Route path="/settings/schedule" element={<Schedule />} />
                                <Route path="/settings/api" element={<Api />} />
                                <Route path="/settings/automation" element={<Automation />} />
                                <Route path="/settings/affiliate" element={<Affiliate />} />
                                <Route path="/settings/affiliateoptions" element={<AffiliateOptions />} />
                            </Route>
                            <Route path="/searches" element={<Searches />} />
                            <Route path="/searches/:searchId" element={<Leads />} />
                            <Route path="/board" element={<Board />} />
                            <Route path="/templates" element={<Templates />} />
                            <Route path="/campaigns" element={<Campaigns />} />
                            <Route path="/campaigns/:campaignId" element={<Campaigns />} />
                            <Route path="/admin" element={<Admin />} />
                            <Route path="/subscription" element={<Subscription />} />
                            <Route path="/team" element={<Team />} />
                            <Route path="/appsumo" element={<Appsumo />} />
                            <Route path="/affiliate" element={<Affiliate />} />
                        </Route>
                        <Route path="/login" element={<LoginLinkedin />} />
                        <Route path="/confirmemail" element={<ConfirmEmail />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/delete" element={<Delete />} />
                    </Route>
                </Routes>
            </QueryParamProvider>
        </Router>
    );
}

export default App;
