import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ScrollBar from '../components/ScrollBar';
import useUserActions from '../_actions/user.actions';
import { tr } from '../common/locale';
import Logo from '../images/logo.svg';
import Icon from '../components/Icon';
import LogoDark from '../images/logo-dark.svg';
import KanboxHero from '../images/scrape-linkedin-sales-navigator.webp';
import KanboxHeroDark from '../images/scrape-linkedin-sales-navigator-dark.webp';

export default function Logout() {
    const { logout } = useUserActions();
    useEffect(() => {
        logout();
    }, []);

    return (
        <ScrollBar>
            <div className="logout">
                <img
                    className="login__logo"
                    src={Logo}
                    alt={tr('Scraping Linkedin, Overpowered Linkedin Messaging Inbox and CRM Pipelines')}
                />
                <img
                    className="login__logo--dark"
                    src={LogoDark}
                    alt={tr('Scraping Linkedin, Overpowered Linkedin Messaging Inbox and CRM Pipelines')}
                />
                <h1>{tr("You're logged out")}</h1>
                <NavLink className="btn btn--has-icon btn--link-primary" to="/login">
                    <Icon size={20} icon="login" />
                    <span className="btn__label">{tr('Log back in')}</span>
                </NavLink>
                <img className="login__hero" src={KanboxHero} alt={tr('Welcome in Kanbox App')} />
                <img className="login__hero--dark" src={KanboxHeroDark} alt={tr('Welcome in Kanbox App')} />
            </div>
        </ScrollBar>
    );
}
