import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { tr } from '../../common/locale';
import { userState, errorAppsumoState, successAppsumoState } from '../../_states/user.states';
import useUserActions from '../../_actions/user.actions';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Flash from '../../components/Flash';
import AppsumoImg from '../../images/appsumo/appsumo.webp';
import AppsumoImgDark from '../../images/appsumo/appsumo-dark.webp';

function Appsumo() {
    const actions = useUserActions();
    const successRef = useRef();
    const navigate = useNavigate();
    const user = useRecoilValue(userState);
    const errorAppsumo = useRecoilValue(errorAppsumoState);
    const successAppsumo = useRecoilValue(successAppsumoState);
    const [code, setCode] = useState('');
    useEffect(() => {
        if (successAppsumo) {
            successRef.current.open();
        }
    }, [successAppsumo]);

    return (
        <div className="content subscription subscription--program">
            <Modal
                className="welcome-message"
                labelValidate={tr('Close')}
                ref={successRef}
                closeCb={() => navigate('/subscription')}
                title={tr('Your subscription is updated!')}
            >
                <p className="welcome-message__main">
                    {user?.plan?.product === 'FUSION' && tr('Thanks for your subscription')}
                </p>
            </Modal>
            <div className="content__body">
                <div className="subscription__content appsumo">
                    <img className="appsumo-img-white" src={AppsumoImg} alt={tr('Your AppSumo code')} />
                    <img className="appsumo-img-dark" src={AppsumoImgDark} alt={tr('Your AppSumo code')} />
                    <h1 className="title">{tr('Hey Sumo-lings!')}</h1>
                    <p>{tr('Paste your AppSumo code in the field below:')}</p>
                    <Input
                        value={code}
                        placeholder={tr('Your AppSumo code')}
                        onChange={(e) => setCode(e.target.value)}
                    />
                    {errorAppsumo && <Flash color="red">{tr('The code is invalid')}</Flash>}
                    <Button label={tr('Apply code')} onClick={() => actions.redeem(code)} />
                </div>
            </div>
        </div>
    );
}
export default Appsumo;
