import { useRecoilValue } from 'recoil';
import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { tr, getLocale } from '../../common/locale';
import {
    userState,
    affiliateLinkState,
    affiliateLinkLoadingState,
    affiliateAccountState,
    affiliateAccountLoadingState,
    affiliateUsersLoadingState,
    affiliateProgramState,
    affiliateUsersState,
} from '../../_states/user.states';
import useUserActions from '../../_actions/user.actions';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Icon from '../../components/Icon';
import carotsImg from '../../images/affiliate/carots.webp';
import TermsFr from '../../legal/kanbox-partnership-fr.pdf';
import TermsEn from '../../legal/kanbox-partnership-en.pdf';

function Affiliate() {
    const actions = useUserActions();
    const user = useRecoilValue(userState);
    const scrollRef = useRef();
    const affiliateLink = useRecoilValue(affiliateLinkState);
    const affiliateProgram = useRecoilValue(affiliateProgramState);
    const affiliateLinkLoading = useRecoilValue(affiliateLinkLoadingState);
    const affiliateAccount = useRecoilValue(affiliateAccountState);
    const affiliateAccountLoading = useRecoilValue(affiliateAccountLoadingState);
    const affiliateUsers = useRecoilValue(affiliateUsersState);
    const affiliateUsersLoading = useRecoilValue(affiliateUsersLoadingState);
    useEffect(() => {
        actions.affiliateAccount();
        actions.getAffiliateProgram();
        actions.updateBrowsing();
    }, []);
    useEffect(() => {
        actions.listAffiliates(true);
        if (scrollRef?.current) {
            scrollRef.current.pageLoaded = 0;
            scrollRef.current.el.scrollTop = 0;
        }
    }, []);

    if (!user) {
        return null;
    }

    const hasMore = !affiliateUsers || affiliateUsers.count > affiliateUsers.items.length;

    if (affiliateAccountLoading) {
        return (
            <div className="fullwh flex-axis">
                <Loader isChat text="Loading affiliate account..." />
            </div>
        );
    }
    const locale = getLocale();
    if (!affiliateAccount?.code) {
        return (
            <div className="content affiliate">
                <div className={`content__body affiliate__funnel ${affiliateLink ? 'step2' : ''}`}>
                    <img src={carotsImg} alt="Kanbox Affiliate Program" />
                    <h1 className="title-discover">{tr('Kanbox Affiliate Program')}</h1>
                    {!affiliateLink && (
                        <>
                            <h2 className="subtitle-discover">{affiliateProgram?.description[locale]}</h2>
                            <Button
                                size="large"
                                className="mb--70"
                                label={tr('Start my Affiliate Program')}
                                isLoading={affiliateLinkLoading}
                                onClick={() => actions.affiliateLink()}
                            />
                            <div className="affiliate__features">
                                {affiliateProgram?.features.map((feature) => (
                                    <div className="affiliate__features__item" key={feature.title[locale]}>
                                        <Icon size={140} icon={feature.icon} />
                                        <h2>{feature.title[locale]}</h2>
                                        <p>{feature.description[locale]}</p>
                                        {feature['*'] && <span>{feature['*'][locale]}</span>}
                                    </div>
                                ))}
                            </div>
                            <h1 className="subtitle-discover">{tr('Frequently Asked Questions')}</h1>
                            <div className="affiliate__faq">
                                {affiliateProgram?.faq.map((faq) => (
                                    <React.Fragment key={faq.title[locale]}>
                                        <dt>{faq.title[locale]}</dt>
                                        <dd>{faq.description[locale]}</dd>
                                    </React.Fragment>
                                ))}
                            </div>
                            <Button
                                size="large"
                                className="mb--70"
                                label={tr('Start my Affiliate Program')}
                                isLoading={affiliateLinkLoading}
                                onClick={() => actions.affiliateLink()}
                            />
                        </>
                    )}
                    {affiliateLink && (
                        <>
                            <h2 className="subtitle">{tr('Here we are!')}</h2>
                            <ul className="affiliate__funnel__steps">
                                <li>
                                    {tr(
                                        'First, you will be redirected to create your Stripe Affiliate account so that you can get paid.'
                                    )}
                                </li>
                                <li>
                                    {tr(
                                        'Once your Stripe account is setup, you will get here a Kanbox link you will be able to share:'
                                    )}
                                    <ul>
                                        <li>
                                            {tr(
                                                'Each user signing up or clicking this link before having subscribed to Kanbox will become your Affiliate'
                                            )}
                                        </li>
                                        <li>{tr('And you will earn 30% of its subscription payments.')}</li>
                                    </ul>
                                </li>
                            </ul>
                            <p className="affiliate__funnel__legal">
                                {tr(
                                    'By clicking the "Create my Stripe Affiliate Account" button below, I acknowledge that I have read and accept the Kanbox'
                                )}{' '}
                                <a
                                    href={locale === 'fr' ? TermsFr : TermsEn}
                                    title={tr('Affiliate Agreement Terms')}
                                    target="blank"
                                >
                                    {tr('Affiliate Agreement Terms')}.
                                </a>
                            </p>
                            <a className="btn btn--primary btn--large mb--70" href={affiliateLink.url} rel="noreferrer">
                                <span className="btn__label">{tr('Create my Stripe Affiliate Account')}</span>
                            </a>
                        </>
                    )}
                </div>
            </div>
        );
    }

    const fakeLoader = [];
    for (let i = 0; i <= 20; i++) {
        fakeLoader.push(
            <div key={i} className="list__fake list__fake--affiliates">
                <div className="list__col list__col--spacer" />
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--fill">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>

                <div className="list__col list__col--large">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--action">
                    <div className="list__fake__item" />
                </div>
            </div>
        );
    }
    return (
        <div className="content__body affiliate">
            <div className="affiliate__list">
                <div className="list__filter">
                    <h3>{tr('Your affiliates')}</h3>
                </div>
                <div className="list__header">
                    <div className="list__cols">
                        <div className="list__col list__col--spacer" />
                        <div className="list__col list__col">{tr('Firstname')}</div>
                        <div className="list__col list__col--fill">{tr('Lastname')}</div>
                        <div className="list__col">{tr('Subscription')}</div>
                        <div className="list__col list__col--large">{tr('Created at')}</div>
                        <div className="list__col list__col--action">{tr('See profile on LinkedIn')}</div>
                    </div>
                </div>
                <div
                    id="connectionsContent"
                    className={`connections--list list--pattern ${
                        affiliateUsersLoading || (affiliateUsers && affiliateUsers?.items.length > 0)
                            ? ''
                            : 'list--empty'
                    }`}
                >
                    {affiliateUsersLoading && (!affiliateUsers || affiliateUsers?.items.length === 0) && fakeLoader}
                    {affiliateUsers && affiliateUsers?.items.length > 0 ? (
                        <InfiniteScroll
                            dataLength={affiliateUsers?.items.length || 0}
                            next={actions.listAffiliates}
                            className="list"
                            hasMore={hasMore}
                            loader={fakeLoader}
                            ref={scrollRef}
                            scrollableTarget="connectionsContent"
                        >
                            {affiliateUsers &&
                                affiliateUsers.items.map((currentUser) => (
                                    <div className="list__item" key={currentUser.id}>
                                        <div className="list__row">
                                            <div className="list__cols">
                                                <div className="list__col list__col--spacer" />
                                                <div className="list__col">{currentUser.firstname}</div>
                                                <div className="list__col list__col--fill">{currentUser.lastname}</div>
                                                <div className="list__col">{currentUser.plan?.product}</div>

                                                <div className="list__col list__col--large">
                                                    {moment(currentUser.created_at).format('LLL')}
                                                </div>

                                                <div className="list__col list__col--action">
                                                    <a
                                                        className="btn btn--link-primary"
                                                        href={`https://www.linkedin.com/in/${currentUser.linkedin_public_id}`}
                                                        type="secondary-blue"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {tr('See on LinkedIn')}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </InfiniteScroll>
                    ) : (
                        <>
                            {!affiliateAccountLoading && (
                                <div className="empty">
                                    <Icon isEmpty icon="empty-connection" size={400} />
                                    <h1>{tr('Nothing here')}</h1>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
export default Affiliate;
