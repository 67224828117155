import { atom } from 'recoil';

export const errorsState = atom({
    key: 'errors',
    default: {},
});

export const fieldsState = atom({
    key: 'fields',
    default: {},
});

export const submitButtonState = atom({
    key: 'submitButton',
    default: {},
});

export const formIsValidState = atom({
    key: 'formIsValid',
    default: {},
});
