import { useSetRecoilState } from 'recoil';
import useFetchWrapper from '../_helpers/fetchWrapper.helpers';
import {
    webhooksState,
    loadingState,
    creatingState,
    removingboardState,
    sendingState,
    addingboardState,
} from '../_states/webhook.states';
import { snackMessageState } from '../_states/alert.states';
import { tr } from '../common/locale';
import useError from '../_helpers/errorsWrapper.helpers';

export default function useLabelActions() {
    const fetchWrapper = useFetchWrapper();
    const setWebhooks = useSetRecoilState(webhooksState);
    const setLoading = useSetRecoilState(loadingState);
    const setSending = useSetRecoilState(sendingState);
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const setCreating = useSetRecoilState(creatingState);
    const setRemovingboard = useSetRecoilState(removingboardState);
    const setAddingboard = useSetRecoilState(addingboardState);
    const { fetchNetworkError } = useError();
    const manageError = (e) => {
        setLoading(false);
        fetchNetworkError(null, e);
    };

    function get() {
        setLoading(true);
        return fetchWrapper
            .get('webhooks?limit=1000&offset=0')
            .then((data) => {
                setWebhooks(data);
                setLoading(false);
            })
            .catch((e) => manageError(e));
    }

    function add(name, url) {
        return fetchWrapper
            .post('webhooks', { name, url })
            .then((data) => {
                setCreating(false);
                get();
                return data;
            })
            .catch((e) => manageError(e));
    }

    function testsend(url) {
        setSending(true);
        return fetchWrapper
            .post('webhooks/testsend', { name: 'test', url })
            .then(() => {
                setSending(false);
                setSnackMessage({ text: tr('Webhook sent'), type: 'info' });
            })
            .catch(() => {
                setSending(false);
                setSnackMessage({ text: tr('Could not send webhook'), type: 'error' });
            });
    }

    function addBoard(webhook, board, step) {
        return fetchWrapper
            .post(`webhooks/${webhook.id}/addstep?step_id=${step.id}&send_to_existing=false`)
            .then((data) => {
                setAddingboard(false);
                get();
                return data;
            })
            .catch((e) => manageError(e));
    }

    function removeBoard(webhook, step) {
        return fetchWrapper
            .post(`webhooks/${webhook.id}/removestep?step_id=${step.id}`)
            .then((data) => {
                setRemovingboard(false);
                get();
                return data;
            })
            .catch((e) => manageError(e));
    }

    function deleteWebhook(id) {
        return fetchWrapper
            .delete(`webhooks/${id}`)
            .then(() => {
                get();
            })
            .catch((e) => manageError(e));
    }

    function update(id, name, url) {
        setLoading(false);
        return fetchWrapper
            .put(`webhooks/${id}`, { name, url })
            .then(() => {
                get();
            })
            .catch((e) => manageError(e));
    }

    return {
        get,
        add,
        addBoard,
        testsend,
        removeBoard,
        deleteWebhook,
        update,
    };
}
