// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import cl from 'classnames';
import PropTypes from 'prop-types';
import Button from './Button';
import ConfirmationPopover from './ConfirmationPopover';
import SubscriptionPopover from './SubscriptionPopover';
import withClickOutside from '../common/withClickOutside';

function mapChildrenWithProps(children, childrenRefs, props) {
    if (children) {
        return React.Children.map(children, (child, index) => {
            if (React.isValidElement(child)) {
                // only add ref to non functional components
                return React.cloneElement(child, {
                    ...child.props,
                    ...props,
                    ref:
                        !child.type.prototype || (child.type.prototype && child.type.prototype.render)
                            ? (elem) => {
                                  // eslint-disable-next-line no-param-reassign
                                  childrenRefs[index] = elem;
                              }
                            : undefined,
                });
            }
            return child;
        });
    }
    return null;
}

function closeChildren(childrenRefs) {
    Object.keys(childrenRefs).forEach((key) => {
        const ref = childrenRefs[key];
        if (ref && ref.close) {
            ref.close();
        }
    });
}

class DropdownMenu extends Component {
    constructor(props) {
        super(props);
        this.childrenRefs = {};
    }

    close = () => {
        closeChildren(this.childrenRefs);
    };

    render() {
        const { direction, children, closeCb, onMouseEnter, onMouseLeave, testId, isSection, ...rest } = this.props;
        const childrenWithProps = mapChildrenWithProps(children, this.childrenRefs, { closeCb });
        if (isSection) {
            return (
                <div className="dropdown__container">
                    <div
                        role="menu"
                        className={cl('dropdown__menu', direction, 'dropdown__menu--section')}
                        data-testid={testId}
                        tabIndex={0}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        {...rest}
                    >
                        {childrenWithProps}
                    </div>
                </div>
            );
        }
        return (
            <ul
                role="menu"
                className={cl('dropdown__menu', direction)}
                data-testid={testId}
                {...rest}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {childrenWithProps}
            </ul>
        );
    }
}

DropdownMenu.defaultProps = {
    direction: 'sw',
    closeCb: null,
    onMouseEnter: null,
    onMouseLeave: null,
    testId: 'dropdown-menu',
    isSection: false,
};

DropdownMenu.propTypes = {
    children: PropTypes.node.isRequired,
    closeCb: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    testId: PropTypes.string,
    isSection: PropTypes.bool,
    direction: PropTypes.oneOf(['ne', 'e', 'se', 's', 'sw', 'w']),
};

class DropdownMenuSection extends Component {
    constructor(props) {
        super(props);
        this.childrenRefs = {};
    }

    close = () => {
        closeChildren(this.childrenRefs);
    };

    render() {
        const { title, children, closeCb, ...rest } = this.props;
        const childrenWithProps = mapChildrenWithProps(children, this.childrenRefs, { closeCb });
        return (
            <div className="dropdown__menu__section">
                {title && <h5 className="dropdown__menu__item dropdown__menu__item__title">{title}</h5>}
                <ul {...rest}>{childrenWithProps}</ul>
            </div>
        );
    }
}

DropdownMenuSection.defaultProps = {
    title: null,
    closeCb: null,
};

DropdownMenuSection.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    closeCb: PropTypes.func,
};

class DropdownSeparator extends Component {
    render() {
        const { testId } = this.props;
        return <li className={cl('dropdown__separator')} data-testid={testId} />;
    }
}

DropdownSeparator.defaultProps = {
    testId: 'dropdown-separator',
};

DropdownSeparator.propTypes = {
    testId: PropTypes.string,
};

class DropdownItem extends Component {
    render() {
        const { children, closeCb, testId, ...rest } = this.props;
        return (
            <li className={cl('dropdown__menu__item')} data-testid={testId} {...rest}>
                {children}
            </li>
        );
    }
}

DropdownItem.defaultProps = {
    closeCb: null,
    testId: 'dropdown-item',
};

DropdownItem.propTypes = {
    children: PropTypes.node.isRequired,
    closeCb: PropTypes.func,
    testId: PropTypes.string,
};

class DropdownButtonItem extends Component {
    onClick = (e) => {
        const { onClick, closeCb } = this.props;
        if (onClick) {
            onClick(e);
        }
        if (closeCb) {
            closeCb();
        }
    };

    render() {
        const {
            onClick,
            btnIcon,
            btnItemIconSize,
            isBtnItemNotification,
            btnLabel,
            isImportant,
            closeCb,
            isSubscriptionPopover,
            ...rest
        } = this.props;
        const btn = (
            <Button
                className={cl({ 'btn--dropdown-item--important': isImportant })}
                type="dropdown-item"
                isNotification={isBtnItemNotification}
                icon={btnIcon}
                iconSize={btnItemIconSize}
                label={btnLabel}
                onClick={this.onClick}
                {...rest}
            />
        );
        if (isSubscriptionPopover) {
            return (
                <li className={cl('dropdown__menu__item')}>
                    <SubscriptionPopover>{btn}</SubscriptionPopover>
                </li>
            );
        }
        return <li className={cl('dropdown__menu__item')}>{btn}</li>;
    }
}

DropdownButtonItem.defaultProps = {
    onClick: null,
    btnIcon: null,
    btnItemIconSize: null,
    isSubscriptionPopover: false,
    btnLabel: '',
    testId: 'dropdown-button-item',
    isImportant: false,
    closeCb: null,
    isBtnItemNotification: false,
};

DropdownButtonItem.propTypes = {
    onClick: PropTypes.func,
    btnIcon: PropTypes.string,
    btnLabel: PropTypes.string,
    testId: PropTypes.string,
    isImportant: PropTypes.bool,
    closeCb: PropTypes.func,
    btnItemIconSize: PropTypes.number,
    isBtnItemNotification: PropTypes.bool,
    isSubscriptionPopover: PropTypes.bool,
};

class DropdownConfirmButtonItem extends Component {
    constructor(props) {
        super(props);
        this.confirmRef = React.createRef();
    }

    close = (e) => {
        if (e) {
            e.stopPropagation();
        }
        if (this.confirmRef.current && this.confirmRef.current.close) {
            this.confirmRef.current.close();
        }
    };

    onConfirm = (e) => {
        e.stopPropagation();
        const { closeCb, onConfirm } = this.props;
        if (closeCb) closeCb();
        if (onConfirm) onConfirm(e);
    };

    render() {
        const { onConfirm, btnIcon, btnLabel, closeCb, confirmLabel, isImportant, placement, ...rest } = this.props;
        // onConfirm and closeCb are put in this list to remove them from ...rest.
        return (
            <li className={cl('dropdown__menu__item')}>
                <ConfirmationPopover
                    ref={this.confirmRef}
                    text={confirmLabel}
                    confirmCb={this.onConfirm}
                    placement={placement}
                >
                    <Button
                        className={cl({ 'ignore-click-outside': true, 'btn--dropdown-item--important': isImportant })}
                        type="dropdown-item"
                        label={btnLabel}
                        icon={btnIcon}
                        onClick={(e) => {
                            e.stopPropagation();
                            this.confirmRef.current.toggle();
                        }}
                        {...rest}
                    />
                </ConfirmationPopover>
            </li>
        );
    }
}

DropdownConfirmButtonItem.defaultProps = {
    testId: 'dropdown-confirm-button-item',
    onConfirm: null,
    btnIcon: null,
    closeCb: null,
    btnLabel: '',
    confirmLabel: '',
    isImportant: false,
    placement: 'bottom-center',
};

DropdownConfirmButtonItem.propTypes = {
    onConfirm: PropTypes.func,
    btnIcon: PropTypes.string,
    testId: PropTypes.string,
    confirmLabel: PropTypes.string,
    btnLabel: PropTypes.string,
    closeCb: PropTypes.func,
    isImportant: PropTypes.bool,
    placement: PropTypes.string,
};

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
        this.clickOutsideRef = React.createRef();
        this.childrenRefs = {};
    }

    setOpen = (openParam) => {
        const { closeCb, enableClickOutsideCb } = this.props;
        const { open } = this.state;
        if (!openParam && open) {
            if (closeCb) {
                closeCb();
            }
            closeChildren(this.childrenRefs);
        }
        if (openParam) {
            enableClickOutsideCb();
        }
        this.setState({ open: openParam });
    };

    handleClickOutside = () => {
        this.setOpen(false);
    };

    onMouseEnter = () => {
        const { openOnMouseEnter } = this.props;
        if (openOnMouseEnter) {
            if (this.mouseLeaveTimeout) {
                clearTimeout(this.mouseLeaveTimeout);
            }
            this.setOpen(true);
        }
    };

    onMouseLeave = () => {
        const { openOnMouseEnter } = this.props;
        if (openOnMouseEnter) {
            if (this.mouseLeaveTimeout) {
                clearTimeout(this.mouseLeaveTimeout);
            }
            this.mouseLeaveTimeout = setTimeout(() => {
                this.setOpen(false);
            }, 300);
        }
    };

    onClick = (e) => {
        const { onClick } = this.props;
        const { open } = this.state;
        this.setOpen(!open);
        if (onClick) {
            onClick(e);
        }
    };

    onDoubleClick = (e) => {
        const { onDoubleClick } = this.props;
        if (onDoubleClick) {
            onDoubleClick(e);
        }
    };

    render() {
        const {
            children,
            size,
            disabled,
            className,
            btnClassName,
            label,
            isBtnNotification,
            btnFace,
            btnIsLoading,
            btnSize,
            iconRight,
            btnFaceIcon,
            noIcon,
            btnFaceIconSize,
            closeCb,
            disableCloseOutside,
            enableClickOutsideCb,
            testId,
            openOnMouseEnter,
            isIconLight,
            isBordered,
            ...rest
        } = this.props;
        const { open } = this.state;
        const childrenWithProps = mapChildrenWithProps(children, this.childrenRefs, {
            closeCb: this.handleClickOutside,
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave,
        });

        return (
            <div
                ref={openOnMouseEnter ? null : this.clickOutsideRef}
                className={cl('dropdown', { open }, `dropdown--${size}`, className)}
                {...rest}
            >
                <Button
                    isLoading={btnIsLoading}
                    iconLight={isIconLight}
                    disabled={disabled}
                    className={btnClassName}
                    isBordered={isBordered}
                    type={btnFace}
                    isNotification={isBtnNotification}
                    onClick={this.onClick}
                    onDoubleClick={this.onDoubleClick}
                    icon={noIcon ? undefined : btnFaceIcon}
                    size={btnSize}
                    iconRight={iconRight}
                    iconSize={noIcon ? undefined : btnFaceIconSize}
                    label={label}
                    testId={testId}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                />
                {childrenWithProps}
            </div>
        );
    }
}

Dropdown.defaultProps = {
    size: 'normal',
    btnSize: null,
    onClick: null,
    disabled: false,
    disableCloseOutside: false,
    iconRight: false,
    onDoubleClick: null,
    label: null,
    className: null,
    closeCb: null,
    openOnMouseEnter: false,
    btnFace: 'link',
    btnIsLoading: false,
    btnFaceIcon: 'caret-down',
    btnFaceIconSize: 22,
    noIcon: false,
    testId: 'dropdown',
    children: null,
    btnClassName: null,
    isBtnNotification: false,
    isIconLight: false,
    isBordered: false,
};

Dropdown.propTypes = {
    onClick: PropTypes.func,
    closeCb: PropTypes.func,
    onDoubleClick: PropTypes.func,
    disabled: PropTypes.bool,
    disableCloseOutside: PropTypes.bool,
    iconRight: PropTypes.bool,
    openOnMouseEnter: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    testId: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    btnClassName: PropTypes.string,
    btnFace: PropTypes.oneOf([
        'secondary-dark',
        'secondary-blue',
        'link',
        'primary',
        'secondary',
        'important',
        'transparent',
        'link',
        'bulk',
    ]),
    btnFaceIcon: PropTypes.string,
    btnIsLoading: PropTypes.bool,
    size: PropTypes.oneOf(['normal', 'small', 'mini']),
    btnSize: PropTypes.oneOf(['large', 'normal', 'small', 'mini']),
    noIcon: PropTypes.bool,
    btnFaceIconSize: PropTypes.number,
    isBtnNotification: PropTypes.bool,
    isIconLight: PropTypes.bool,
    isBordered: PropTypes.bool,
};

export default Object.assign(withClickOutside(Dropdown, '.popover__content', true), {
    Menu: DropdownMenu,
    MenuSection: DropdownMenuSection,
    Item: DropdownItem,
    ButtonItem: DropdownButtonItem,
    ConfirmButtonItem: DropdownConfirmButtonItem,
    Separator: DropdownSeparator,
});
