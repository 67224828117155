import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from './Popover';

class TooltipPopover extends Component {
    constructor(props) {
        super(props);
        this.popoverRef = React.createRef();
    }

    onMouseEnter() {
        if (this.popoverRef.current) {
            this.popoverRef.current.open();
        }
    }

    onMouseLeave() {
        if (this.popoverRef.current) {
            this.popoverRef.current.close();
        }
    }

    render() {
        const { text, placement, children } = this.props;
        return (
            <Popover
                ref={this.popoverRef}
                contentClassName="popover__tooltip"
                content={<div>{text}</div>}
                placement={placement}
            >
                <div
                    className="popover__tooltip__cursor"
                    onMouseEnter={() => this.onMouseEnter()}
                    onMouseLeave={() => this.onMouseLeave()}
                >
                    {children}
                </div>
            </Popover>
        );
    }
}

TooltipPopover.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    placement: PropTypes.oneOf(['right-center', 'left-center', 'bottom-center']),
};

TooltipPopover.defaultProps = {
    placement: 'right-center',
};

export default TooltipPopover;
