import i18next from 'i18next';
import enMessages from '../locales/en/translation.json';
import frMessages from '../locales/fr/translation.json';

i18next.init({
    compatibilityJSON: 'v3',
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    nsSeparator: null,
    keySeparator: null,
    pluralSeparator: '__',
    contextSeparator: '--',
    saveMissingPlurals: false,
    simplifyPluralSuffix: true,
    resources: {
        en: {
            translation: enMessages,
        },
        fr: {
            translation: frMessages,
        },
    },
});

const tr = i18next.t.bind(i18next);
const changeLanguage = i18next.changeLanguage.bind(i18next);
const addResources = (lng, resources) => {
    i18next.addResourceBundle(lng, 'translation', resources);
};
const getLocale = () => i18next.language;
export default tr;
export { tr, changeLanguage, addResources, getLocale };
