import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

export default class Tooltip extends Component {
    render() {
        const { className, text, direction, align, noDelay, wrap, children, testId, ...rest } = this.props;
        const classes = cl(
            'tooltip',
            className,
            `tooltipped-${direction}`,
            align && `tooltipped-align-${align}-2`,
            noDelay && 'tooltipped-no-delay',
            wrap && 'tooltipped-multiline'
        );
        delete rest.closeCb; // Avoid none DOM props
        return (
            <div aria-label={text} data-testid={testId} {...rest} className={classes}>
                {children}
            </div>
        );
    }
}

Tooltip.defaultProps = {
    testId: 'tooltip',
    className: '',
    direction: 'e',
    align: undefined,
    children: null,
    noDelay: false,
    text: '',
    wrap: false,
};

Tooltip.propTypes = {
    align: PropTypes.oneOf(['left', 'right', undefined]),
    children: PropTypes.node,
    direction: PropTypes.oneOf(['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw']),
    noDelay: PropTypes.bool,
    text: PropTypes.string,
    testId: PropTypes.string,
    className: PropTypes.string,
    wrap: PropTypes.bool,
};
