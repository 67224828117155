import { useRecoilValue } from 'recoil';
import Label from '../../components/Label';
import { labelsState } from '../../_states/label.states';

export const Colors = {
    color1: { dark: 'rgba(159, 241, 155, 1)', light: 'rgba(159, 241, 155, .2)' },
    color2: { dark: 'rgba(90, 211, 80, 1)', light: 'rgba(90, 211, 80, .2)' },
    color3: { dark: 'rgba(56, 180, 43, 1)', light: 'rgba(56, 180, 43, .2)' },

    color4: { dark: 'rgba(142, 222, 209, 1)', light: 'rgba(142, 222, 209, .2)' },
    color5: { dark: 'rgba(76, 207, 180, 1)', light: 'rgba(76, 207, 180, .2)' },
    color6: { dark: 'rgba(19, 155, 128, 1)', light: 'rgba(19, 155, 128, .2)' },

    color10: { dark: 'rgba(161, 214, 234, 1)', light: 'rgba(161, 214, 234, .2)' },
    color11: { dark: 'rgba(75, 172, 241, 1)', light: 'rgba(75, 172, 241, .2)' },
    color12: { dark: 'rgba(13, 109, 181, 1)', light: 'rgba(13, 109, 181, .2)' },

    color13: { dark: 'rgba(157, 176, 234, 1)', light: 'rgba(157, 176, 234, .2)' },
    color14: { dark: 'rgba(83, 129, 211, 1)', light: 'rgba(83, 112, 211, .2)' },
    color15: { dark: 'rgba(26, 53, 155, 1)', light: 'rgba(26, 53, 155, .2)' },

    color16: { dark: 'rgba(225, 183, 246, 1)', light: 'rgba(225, 183, 246, .2)' },
    color17: { dark: 'rgba(207, 121, 244, 1)', light: 'rgba(207, 121, 244, .2)' },
    color18: { dark: 'rgba(156, 88, 194, 1)', light: 'rgba(156, 88, 194, .2)' },

    color19: { dark: 'rgba(250, 173, 220, 1)', light: 'rgba(250, 173, 220, .2)' },
    color20: { dark: 'rgba(255, 108, 189, 1)', light: 'rgba(255, 108, 189, .2)' },
    color21: { dark: 'rgba(200, 69, 144, 1)', light: 'rgba(200, 69, 144, .2)' },

    color22: { dark: 'rgba(241, 172, 166, 1)', light: 'rgba(241, 172, 166, .2)' },
    color23: { dark: 'rgba(248, 89, 72, 1)', light: 'rgba(248, 89, 72, .2)' },
    color24: { dark: 'rgba(202, 52, 42, 1)', light: 'rgba(202, 52, 42, .2)' },

    color25: { dark: 'rgba(250, 189, 125, 1)', light: 'rgba(250, 189, 125, .2)' },
    color26: { dark: 'rgba(255, 144, 37, 1)', light: 'rgba(255, 144, 37, .2)' },
    color27: { dark: 'rgba(188, 97, 17, 1)', light: 'rgba(188, 97, 17, .2)' },

    color28: { dark: 'rgba(250, 228, 115, 1)', light: 'rgba(250, 228, 115, .2)' },
    color29: { dark: 'rgba(250, 214, 23, 1)', light: 'rgba(250, 214, 23, .2)' },
    color30: { dark: 'rgba(206, 147, 0, 1)', light: 'rgba(206, 147, 0, .2)' },

    color31: { dark: 'rgba(200, 188, 161, 1)', light: 'rgba(200, 188, 161, .2)' },
    color32: { dark: 'rgba(139, 126, 93, 1)', light: 'rgba(139, 126, 93, .2)' },
    color33: { dark: 'rgba(81, 73, 58, 1)', light: 'rgba(81, 73, 58, .2)' },

    color34: { dark: 'rgba(200, 161, 167, 1)', light: 'rgba(200, 161, 167, .2)' },
    color35: { dark: 'rgba(139, 93, 97, 1)', light: 'rgba(139, 93, 97, .2)' },
    color36: { dark: 'rgba(78, 52, 54, 1)', light: 'rgba(78, 52, 54, .2)' },

    color37: { dark: 'rgba(167, 172, 200, 1)', light: 'rgba(167, 172, 200, .2)' },
    color38: { dark: 'rgba(93, 99, 139, 1)', light: 'rgba(93, 99, 139, .2)' },
    color39: { dark: 'rgba(49, 52, 78, 1)', light: 'rgba(49, 52, 78, .2)' },

    color7: { dark: 'rgba(184, 200, 167, 1)', light: 'rgba(184, 200, 167, .2)' },
    color8: { dark: 'rgba(115, 139, 93, 1)', light: 'rgba(115, 139, 93, .2)' },
    color9: { dark: 'rgba(57, 75, 42, 1)', light: 'rgba(57, 75, 42, .2)' },

    color40: { dark: 'rgba(167, 193, 200, 1)', light: 'rgba(167, 193, 200, .2)' },
    color41: { dark: 'rgba(93, 128, 139, 1)', light: 'rgba(93, 128, 139, .2)' },
    color42: { dark: 'rgba(42, 69, 78, 1)', light: 'rgba(42, 69, 78, .2)' },
};

function LabelsList({
    selectedLabels,
    isNoLabel,
    isToggleIcon,
    isNoBg,
    isLabelTooltip,
    isClosable,
    onClick,
    closeLabel,
}) {
    const labels = useRecoilValue(labelsState);
    const userLabels = selectedLabels?.filter((selected) => labels?.find((lab) => lab.id === selected.id));
    return (
        <div
            role="button"
            tabIndex={0}
            onKeyDown={() => null}
            className={`labels-list ${userLabels?.length === 0 ? 'labels-list--nolabel' : ''} ${
                isToggleIcon ? 'labels-list--spacing' : ''
            }`}
            onClick={onClick}
        >
            {userLabels?.map((selected) => {
                const current = labels?.find((lab) => lab.id === selected.id);
                return (
                    <Label
                        key={selected.id}
                        noBg={isNoBg}
                        closable={isClosable}
                        closeCb={() => closeLabel(selected)}
                        toggleIcon={isToggleIcon}
                        labelTooltip={isLabelTooltip ? current?.name || selected.name : null}
                        label={isNoLabel ? null : current?.name || selected.name}
                        color={Colors[current?.color || selected.color]?.light}
                        iconColor={Colors[current?.color || selected.color]?.dark}
                    />
                );
            })}
        </div>
    );
}
export default LabelsList;
