import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select/async';
import cl from 'classnames';

/**
 * https://react-select.com/styles#styles
 *
 */
export default class AsyncSelect extends Component {
    render() {
        const { value, inputValue, placeholder, menuIsOpen, size, innerRef, ...rest } = this.props;
        return (
            <ReactSelect
                value={value}
                ref={innerRef}
                inputValue={inputValue}
                placeholder={placeholder}
                menuIsOpen={menuIsOpen}
                components={{ DropdownIndicator: null }}
                className={cl('select', 'select--autocomplete', `select--${size}`)}
                classNamePrefix="asyncselect"
                {...rest}
            />
        );
    }
}

AsyncSelect.defaultProps = {
    value: undefined,
    inputValue: undefined,
    placeholder: '',
    size: 'normal',
    menuIsOpen: undefined,
    innerRef: null,
};
AsyncSelect.propTypes = {
    value: PropTypes.object,
    innerRef: PropTypes.object,
    inputValue: PropTypes.string,
    placeholder: PropTypes.string,
    menuIsOpen: PropTypes.bool,
    size: PropTypes.oneOf(['big', 'normal', 'small', 'mini']),
};
