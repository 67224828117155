import React, { useRef, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useRecoilValue } from 'recoil';
import { gifsState } from '../../_states/template.states';

import Button from '../../components/Button';
import Input from '../../components/Input';
import { tr } from '../../common/locale';
import Popover from '../../components/Popover';
import useLinkdInActions from '../../_actions/linkedin.actions';

function GifPicker({
    onGifClick,
    btnIcon,
    btnClassName,
    popoverClassName,
    btnIconSize,
    btnSize,
    btnType,
    isRound,
    placement,
}) {
    const linkedInActions = useLinkdInActions();
    const popoverEmojiRef = useRef();
    const gifs = useRecoilValue(gifsState);
    const searchFunc = (value) => {
        linkedInActions.searchGifs(value);
    };
    useEffect(() => {
        linkedInActions.searchGifs(' ');
    }, []);
    const debouncedSearch = useCallback(debounce(searchFunc, 300), []);
    const onClick = (gif) => {
        popoverEmojiRef.current.close();
        onGifClick(gif);
    };
    return (
        <Popover
            bounce={false}
            ref={popoverEmojiRef}
            placement={placement}
            contentClassName={popoverClassName || 'popover__content__gif'}
            content={
                <div className="gif-picker">
                    <div className="gif-picker-header">
                        <Input
                            icon="search"
                            isFilter
                            customIconSize={20}
                            isNoBorder
                            onChange={(e) => {
                                debouncedSearch(e.target.value);
                            }}
                            placeholder={tr('Search')}
                            type="search"
                            isFocusedDefault={false}
                        />
                    </div>
                    <div className="gif-picker-content">
                        {gifs?.map((gif) => (
                            <div
                                className="gif-picker-item"
                                onClick={() => onClick(gif)}
                                onKeyDown={() => onClick(gif)}
                                role="button"
                                tabIndex={-1}
                            >
                                <img src={gif.previewMedia.url} alt={gif.title} />
                            </div>
                        ))}
                    </div>
                </div>
            }
        >
            <Button
                className={btnClassName || 'gif'}
                type={btnType || 'link'}
                size={btnSize || undefined}
                icon={btnIcon || 'gif'}
                iconSize={btnIconSize || 24}
                onClick={() => popoverEmojiRef.current.toggle()}
                isRound={isRound}
            />
        </Popover>
    );
}
export default GifPicker;
