import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default function withClickOutside(WrappedComponent, ignoreQuerySelector, enableByCb) {
    class WithClickOutside extends Component {
        constructor(props) {
            super(props);
            const { forwardedRef } = this.props;
            this.enabled = false;
            this.ref = forwardedRef || React.createRef();
            if (!enableByCb) {
                this.enable();
            }
        }

        componentWillUnmount() {
            if (typeof window === 'object' && this.enabled) {
                document.removeEventListener('mousedown', this.listener);
                document.removeEventListener('touchstart', this.listener);
            }
        }

        enable = () => {
            console.log('enable clickoutside');
            if (typeof window === 'object' && !this.enabled) {
                this.enabled = true;
                document.addEventListener('mousedown', this.listener);
                document.addEventListener('touchstart', this.listener);
            }
        };

        listener = (event) => {
            const { disableCloseOutside } = this.props;
            if (disableCloseOutside) {
                return;
            }
            // Do nothing if parent has a class to be ignored
            if (
                ignoreQuerySelector &&
                [...document.querySelectorAll(ignoreQuerySelector)].some(
                    (el) => el !== event.target && el.contains(event.target)
                )
            ) {
                return;
            }

            // Do nothing if parent has a class to be ignored
            if (
                [...document.querySelectorAll('.ignore-click-outside')].some(
                    (el) => el === event.target || el.contains(event.target)
                )
            ) {
                return;
            }

            // Do nothing if clicking ref's element or descendent elements
            if (
                !this.ref.current ||
                !this.ref.current.clickOutsideRef ||
                !this.ref.current.clickOutsideRef.current ||
                this.ref.current.clickOutsideRef.current.contains(event.target)
            ) {
                return;
            }

            if (this.ref.current.handleClickOutside) {
                this.ref.current.handleClickOutside(event);
            }
        };

        render() {
            const { forwardedRef, disableCloseOutside, ...rest } = this.props;
            return (
                <WrappedComponent
                    ref={this.ref}
                    {...rest}
                    disableCloseOutside={disableCloseOutside || undefined}
                    enableClickOutsideCb={this.enable}
                />
            );
        }
    }
    WithClickOutside.defaultProps = {
        forwardedRef: null,
        disableCloseOutside: false,
    };
    WithClickOutside.propTypes = {
        forwardedRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        disableCloseOutside: PropTypes.bool,
    };

    WithClickOutside.displayName = `WithClickOutside(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;
    return React.forwardRef((props, ref) => <WithClickOutside {...props} forwardedRef={ref} />);
}
