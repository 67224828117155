import React, { Component } from 'react';
import cl from 'classnames';
import PropTypes from 'prop-types';
import Animate, { AnimatePresence } from '../common/Animate';
import Button from './Button';

const SnackBarContext = React.createContext();

export default class SnackBar extends Component {
    constructor(props) {
        super(props);
        this.defaultDuration = 5000;
        this.state = { open: false, text: '' };
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    openSnackBar = (text, duration) => {
        this.setState({ open: true, text });
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(this.closeSnackBar, duration || this.defaultDuration);
    };

    closeSnackBar = () => {
        this.setState({ open: false });
    };

    render() {
        const { children, type, ...rest } = this.props;
        const { open, text } = this.state;
        const classes = cl('snackbar', type ? `snackbar--${type}` : null);
        return (
            <SnackBarContext.Provider
                value={{
                    openSnackBar: this.openSnackBar,
                    closeSnackBar: this.closeSnackBar,
                }}
            >
                {children}
                <AnimatePresence>
                    {open && (
                        <Animate className={cl('snackbar__wrapper')} data-testid="snackbar">
                            <div className={classes} {...rest}>
                                <div className="snackbar__text">{text}</div>
                                <Button icon="close" type="link" onClick={this.closeSnackBar} />
                            </div>
                        </Animate>
                    )}
                </AnimatePresence>
            </SnackBarContext.Provider>
        );
    }
}

export { SnackBarContext };

SnackBar.defaultProps = {
    type: undefined,
    children: undefined,
};

SnackBar.propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf([undefined, 'success', 'error', 'info', 'warning']),
};
