import { useRef, useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { tr } from '../../common/locale';
import Input from '../../components/Input';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import useBoardActions from '../../_actions/board.actions';
import { selectedBoardIdState } from '../../_states/lnusers.states';

function AddBoard({ context }) {
    const actions = useBoardActions();
    const addBoardModalRef = useRef();
    const [newName, setNewName] = useState('');
    const selectedBoardId = useRecoilValue(selectedBoardIdState);
    useEffect(() => {
        if (addBoardModalRef) {
            addBoardModalRef.current.close();
            setNewName('');
        }
    }, [selectedBoardId]);
    return (
        <>
            {context === 'header' && (
                <Button size="small" icon="plus" label={tr('New')} onClick={() => addBoardModalRef.current.open()} />
            )}
            {context === 'filter' && (
                <Button
                    icon="board-plus"
                    iconSize={20}
                    type="link"
                    onClick={(e) => {
                        e.stopPropagation();
                        addBoardModalRef.current.open();
                    }}
                />
            )}
            {context === 'sidebar' && (
                <Button
                    type="link"
                    label={tr('+ Add Pipeline')}
                    onClick={(e) => {
                        e.stopPropagation();
                        addBoardModalRef.current.open();
                    }}
                />
            )}
            {context === 'empty' && (
                <Button
                    size="large"
                    icon="plus"
                    iconSize={18}
                    label={tr('Create a Pipeline')}
                    onClick={(e) => {
                        e.stopPropagation();
                        addBoardModalRef.current.open();
                    }}
                />
            )}
            <Modal
                ref={addBoardModalRef}
                disabled={!newName}
                validateCb={() => actions.createBoard(newName)}
                title={tr('New Pipeline')}
                labelValidate={tr('Create')}
            >
                <Input placeholder={tr('Pipeline name')} value={newName} onChange={(e) => setNewName(e.target.value)} />
            </Modal>
        </>
    );
}
export default AddBoard;
