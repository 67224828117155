import React, { useState } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import { tr } from '../../common/locale';

const OPERATORS = {
    '=': tr('Equals'),
    '!=null': tr('Is provided'),
    '=null': tr('Is not provided'),
    exists: tr('Is added'),
    not_exists: tr('Is not added'),
    search_exists: tr('Is duplicated'),
    search_not_exists: tr('Is not duplicated'),
    relation_exists: tr('Is a connection'),
    relation_not_exists: tr('Is not a connection'),
    email_exists: tr('Is provided'),
    email_not_exists: tr('Is not provided'),
    ':true': tr('Is'),
    is_match: tr('Match'),
    is_not_match: tr('Not a Match'),
    'is_duplicate:true': tr('Is duplicated'),
    'is_duplicate!:true': tr('Is not duplicated'),
    '_is_enrichvalid:true': tr('Is checked'),
    'is_premium:true': tr('Is Premium'),
    'is_opentowork:true': tr('Is Open to work'),
    'is_open_profile:true': tr('Is Open Profile'),
    'is_premium!:true': tr('Is Not Premium'),
    'is_opentowork!:true': tr('Is Not Open to work'),
    'is_open_profile!:true': tr('Is Not Open Profile'),
    '!:true': tr('Is not'),
    '!=': tr('Not equals'),
    '<': tr('Inferior than'),
    '>': tr('Superior than'),
    '~': tr('Contains'),
    '!~': tr('Not contains'),
};
function Filter({ property, operator, value, operatorChoices, read, addCb, removeCb, removeFilterCb }) {
    const [val, setVal] = useState('');
    const [op, setOp] = useState(operatorChoices ? operatorChoices[0] : null);
    const hasInput = (paramOp) =>
        paramOp &&
        !paramOp.includes(':') &&
        paramOp.indexOf('=null') === -1 &&
        paramOp.indexOf('exists') === -1 &&
        paramOp.indexOf('is_') !== 0;
    const add = (paramOp) => {
        if (paramOp && (!hasInput(paramOp) || (hasInput(paramOp) && val))) {
            addCb({
                property,
                operator: paramOp,
                value: val,
            });
        }
    };
    if (read) {
        return (
            <div className="label label--mini label--green-sf">
                <div className="label__name">
                    <span className="label__name__operator">{tr(OPERATORS[operator])}</span>
                    <span className="label__name__value">{value}</span>
                </div>
                <Button
                    type="link"
                    iconSize={12}
                    icon="close"
                    className="leads__filtered__item__remove"
                    onClick={() => removeCb()}
                />
            </div>
        );
    }
    const hasElements = operatorChoices.find((element) => !!element);
    if (!hasElements) {
        return null;
    }

    return (
        <div className="leads__filter__add">
            <div className="leads__filter__add__btn-group">
                <Dropdown
                    iconRight
                    btnFaceIconSize={12}
                    label={tr(OPERATORS[op]) || tr('Select operator')}
                    onClick={(e) => e.stopPropagation()}
                    onDoubleClick={(e) => e.stopPropagation()}
                >
                    <Dropdown.Menu direction="s">
                        {operatorChoices.map(
                            (element) =>
                                element && (
                                    <Dropdown.ButtonItem
                                        key={element}
                                        btnLabel={
                                            tr(OPERATORS[element]) +
                                            (element === ':' || element === '!:' ? ` ${tr(property)}` : '')
                                        }
                                        onClick={() => {
                                            setOp(element);
                                            add(element);
                                        }}
                                    />
                                )
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                {hasInput(op) && (
                    <Input
                        value={val}
                        type={op === '<' || op === '>' ? 'number' : 'text'}
                        placeholder={tr('Type in your search')}
                        onChange={(e) => setVal(e.target.value)}
                    />
                )}
                <Button type="green-sf" icon="plus" disabled={hasInput ? false : val} onClick={() => add(op)} />
            </div>
            <Button className="leads__filter__remove" icon="close" onClick={removeFilterCb} type="link" iconSize={16} />
        </div>
    );
}

export default Filter;
