import { setRecoil } from 'recoil-nexus';
import { newMessageState, newReactionState } from '../_states/lnuser.states';

let realtimePort = null;

export default function initRealtime() {
    if (realtimePort) {
        realtimePort.onMessage.removeListener();
        realtimePort.onDisconnect.removeListener();
        realtimePort.disconnect();
    }
    // eslint-disable-next-line no-undef
    const port = chrome.runtime.connect(`${process.env.REACT_APP_CHROME_EXTENSION_ID}`);
    port.onMessage.addListener((message) => {
        console.log('Realtime message', message);
        if (message?.reactionSummary) {
            setRecoil(newReactionState, message);
        } else {
            setRecoil(newMessageState, message);
        }
    });
    port.onDisconnect.addListener(() => {
        setTimeout(initRealtime, 2000);
    });
    realtimePort = port;
}
