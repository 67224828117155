/* eslint-disable no-console */
export default {
    error: (...args) => {
        console.error(...args);
    },
    warn: (...args) => {
        console.warn(...args);
    },
    info: (...args) => {
        console.error(...args);
    },
};
