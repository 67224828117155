import { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { tr } from '../../common/locale';
import {
    userState,
    teamUsersState,
    teamUsersLoadingState,
    changeSubscriptionUserState,
    subscriptionsState,
} from '../../_states/user.states';
import useUserActions from '../../_actions/user.actions';
import Avatar from '../../components/Avatar';
import ConfirmButton from '../../components/ConfirmButton';
import Dropdown from '../../components/Dropdown';

function Team() {
    const actions = useUserActions();
    const subscriptions = useRecoilValue(subscriptionsState);
    const user = useRecoilValue(userState);
    const [changeSubscriptionUser, setChangeSubscriptionUser] = useRecoilState(changeSubscriptionUserState);
    const [changeSubscriptionPlan, setChangeSubscriptionPlan] = useState(null);
    const [changeSubscriptionPlanAutom, setChangeSubscriptionPlanAutom] = useState(false);
    const [shareSearches, setShareSearches] = useState(false);
    const teamUsers = useRecoilValue(teamUsersState);
    const teamUsersLoading = useRecoilValue(teamUsersLoadingState);
    useEffect(() => {
        actions.getSubscriptions();
        actions.updateBrowsing();
        actions.refreshMe();
        actions.listTeam(true);
    }, []);

    useEffect(() => {
        if (user?.team && user.team.is_shared_leads !== shareSearches) {
            setShareSearches(user.team.is_shared_leads);
        }
    }, [user]);

    useEffect(() => {
        if (changeSubscriptionUser) {
            actions.changeSubscription(changeSubscriptionPlan, changeSubscriptionPlanAutom);
        }
    }, [changeSubscriptionUser]);

    const hasMore = !teamUsers || teamUsers.count > teamUsers.items.length;
    const isTeamOwner = user && user.team && user.team.owner === user.id;
    const fakeLoader = [];
    for (let i = 0; i <= 20; i++) {
        fakeLoader.push(
            <div key={i} className="list__fake list__fake--team">
                <div className="list__col list__col--spacer" />
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item" />
                </div>

                <div className="list__col">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--mini">
                    <div className="list__fake__item" />
                </div>
            </div>
        );
    }
    const labelSub = (plan, currentUser) => {
        if (plan.product === 'FUSION' && currentUser?.appsumo_code1) {
            if (plan.has_automations) {
                return 'CONNECT+SCRAPE - Autom.';
            }
            return 'CONNECT+SCRAPE';
        }
        return plan.product;
    };
    const hasSeats =
        subscriptions?.filter(
            (sub) => sub.product !== 'TRIAL' && sub.product !== 'AI_CREDITS' && sub.product !== 'EMAIL_CREDITS'
        ).length > 0;

    const canChangeSubscription = (currentUser) =>
        isTeamOwner &&
        hasSeats &&
        (currentUser.plan.product === 'TRIAL' || subscriptions.find((sub) => sub.id === currentUser.plan.id));
    return (
        <div className="content team">
            <div className="content__body">
                <div className="team__list">
                    <div className="team__header">
                        <div className="team__header__title">
                            <h2 className="title">{tr('My team')}</h2>
                        </div>
                        {subscriptions && hasSeats && isTeamOwner && (
                            <div className="team__seats">
                                <h3 className="team__seat-title">{tr('Used seats')}</h3>
                                {subscriptions
                                    .filter(
                                        (sub) =>
                                            sub.product !== 'TRIAL' &&
                                            sub.product !== 'AI_CREDITS' &&
                                            sub.product !== 'EMAIL_CREDITS'
                                    )
                                    .map((sub) => (
                                        <div
                                            className={`team__seat ${
                                                sub.quantity === sub.users_count ? 'not-available' : ''
                                            } ${sub.product.toLowerCase()}`}
                                            key={sub.product}
                                        >
                                            <span>
                                                {labelSub(sub, user)}&nbsp;: {sub.users_count} / {sub.quantity}
                                            </span>
                                            {sub.quantity === sub.users_count && (
                                                <span className="info">{tr('No seats left')}</span>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>

                    <div className="list__header">
                        <div className="list__cols">
                            <div className="list__col list__col--spacer" />
                            <div className="list__col list__col--fill">{tr('Teammate')}</div>
                            <div className="list__col">{tr('Is owner')}</div>
                            <div className="list__col list__col--large">{tr('Subscription')}</div>
                            <div className="list__col">{tr('Created at')}</div>
                            <div className="list__col list__col--mini" />
                        </div>
                    </div>
                    <div
                        id="connectionsContent"
                        className={`connections--list list--pattern ${
                            teamUsersLoading || (teamUsers && teamUsers?.items.length > 0) ? '' : 'list--empty'
                        }`}
                    >
                        {teamUsersLoading && (!teamUsers || teamUsers?.items.length === 0) && fakeLoader}
                        <InfiniteScroll
                            next={actions.listTeam}
                            dataLength={teamUsers?.items.length || 0}
                            className="list"
                            hasMore={hasMore}
                            loader={fakeLoader}
                            scrollableTarget="connectionsContent"
                        >
                            {teamUsers &&
                                teamUsers.items.map((currentUser) => (
                                    <div className="list__item" key={currentUser.id}>
                                        <div className="list__row">
                                            <div className="list__cols">
                                                <div className="list__col list__col--spacer" />
                                                <div className="list__col list__col--fill">
                                                    <div className="team-avatar">
                                                        <Avatar
                                                            className="avatar"
                                                            src={currentUser.picture}
                                                            alt={`${currentUser.firstname} ${currentUser.lastname}`}
                                                        />
                                                        <span className="ellipsis">
                                                            {currentUser.firstname}&nbsp;{currentUser.lastname}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="list__col">
                                                    {currentUser.id === user.team.owner ? tr('Owner') : null}
                                                </div>
                                                <div className="list__col list__col list__col--large overflow">
                                                    {canChangeSubscription(currentUser) && (
                                                        <Dropdown
                                                            iconRight
                                                            label={labelSub(currentUser.plan, user)}
                                                            disabled={!!changeSubscriptionUser}
                                                            onClick={(e) => e.stopPropagation()}
                                                            onDoubleClick={(e) => e.stopPropagation()}
                                                        >
                                                            <Dropdown.Menu direction="sw">
                                                                <Dropdown.ButtonItem
                                                                    label="TRIAL"
                                                                    onClick={() => {
                                                                        setChangeSubscriptionPlan('TRIAL');
                                                                        setChangeSubscriptionPlanAutom(true);
                                                                        setChangeSubscriptionUser(currentUser);
                                                                    }}
                                                                />
                                                                {subscriptions
                                                                    ?.filter(
                                                                        (sub) =>
                                                                            sub.product !== 'TRIAL' &&
                                                                            sub.product !== 'AI_CREDITS' &&
                                                                            sub.product !== 'EMAIL_CREDITS'
                                                                    )
                                                                    .map((sub) => (
                                                                        <Dropdown.ButtonItem
                                                                            key={sub.product}
                                                                            label={`${labelSub(sub, user)} (${
                                                                                sub.quantity - sub.users_count
                                                                            })`}
                                                                            disabled={sub.quantity === sub.users_count}
                                                                            onClick={() => {
                                                                                setChangeSubscriptionPlan(sub.product);
                                                                                setChangeSubscriptionPlanAutom(
                                                                                    sub.has_automations
                                                                                );
                                                                                setChangeSubscriptionUser(currentUser);
                                                                            }}
                                                                        />
                                                                    ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )}
                                                    {!canChangeSubscription(currentUser) &&
                                                        labelSub(currentUser.plan, user)}
                                                </div>
                                                <div className="list__col">
                                                    {moment(currentUser.created_at).format('LL')}
                                                </div>
                                                <div className="list__col list__col--mini">
                                                    {isTeamOwner && currentUser.id !== user?.id && (
                                                        <ConfirmButton
                                                            type="link"
                                                            icon="trash-can"
                                                            confirmLabel={tr(
                                                                'Do you confirm you want to remove this teammate?'
                                                            )}
                                                            onConfirm={() => actions.removeFromTeam(currentUser.id)}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Team;
