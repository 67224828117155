import { useSetRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { tr } from '../common/locale';
import useFetchWrapper from '../_helpers/fetchWrapper.helpers';
import { templatesState, loadingState, templateState, savingTemplateState } from '../_states/template.states';
import useError from '../_helpers/errorsWrapper.helpers';
import { snackMessageState } from '../_states/alert.states';

export default function useTemplateActions() {
    const fetchWrapper = useFetchWrapper();
    const setTemplates = useSetRecoilState(templatesState);
    const setTemplate = useSetRecoilState(templateState);
    const setLoading = useSetRecoilState(loadingState);
    const setSavingTemplate = useSetRecoilState(savingTemplateState);
    const setSnackMessage = useSetRecoilState(snackMessageState);
    const { fetchNetworkError } = useError();
    const manageError = (e) => {
        setLoading(false);
        setSavingTemplate(false);
        fetchNetworkError(null, e);
    };

    function get() {
        setLoading(true);
        return fetchWrapper
            .get('templates')
            .then((data) => {
                setTemplates(data);
                setLoading(false);
            })
            .catch((e) => manageError(e));
    }

    function add(title, content, alternative, type, file, alternateFiles) {
        setLoading(false);
        setSavingTemplate(true);
        return fetchWrapper
            .post('templates', {
                title,
                content,
                alternative,
                type: type || 'message',
                attachment: file ? JSON.stringify(file) : null,
                alternative_attachment: alternateFiles ? JSON.stringify(alternateFiles) : null,
            })
            .then((data) => {
                setSnackMessage({ text: tr('Template saved!'), type: 'info' });
                setSavingTemplate(false);
                setTemplate(data);
                get();
            })
            .catch((e) => manageError(e));
    }

    function deleteTemplate(id) {
        setLoading(false);
        setSavingTemplate(true);
        const template = getRecoil(templateState);
        return fetchWrapper
            .delete(`templates/${id}`)
            .then(() => {
                setSavingTemplate(false);
                if (template?.id === id) {
                    setTemplate(null);
                }
                get();
            })
            .catch((e) => manageError(e));
    }

    function update(id, title, content, alternative, type, file, alternateFiles) {
        setLoading(false);
        const template = getRecoil(templateState);
        setSavingTemplate(true);
        return fetchWrapper
            .put(`templates/${id}`, {
                title,
                content,
                alternative,
                type,
                attachment: file ? JSON.stringify(file) : null,
                alternative_attachment: alternateFiles ? JSON.stringify(alternateFiles) : null,
            })
            .then((data) => {
                setSnackMessage({ text: tr('Template saved!'), type: 'info' });
                setSavingTemplate(false);
                if (template?.id === id) {
                    setTemplate(data);
                }
                get();
            })
            .catch((e) => manageError(e));
    }

    function createDefaultTemplates(language) {
        if (language === 'fr') {
            add(
                'FR - Je serais ravi de...',
                'Bonjour {{firstname}},\n je serais ravi de faire partie de votre réseau :)',
                'invitation'
            );
            add(
                'FR - Groupe en commun',
                "Bonjour {{firstname}},\nJe me permets de vous contacter car je vois que nous sommes tous deux membres du groupe [Nom du groupe].\nPuisque vous vous intéressez au [Sujet], j'aimerais savoir ce que vous pensez de [problématique à laquelle répond votre offre] ! \nJ'apprécierais de pouvoir échanger avec vous sur le sujet !\nPas de manipulation de vente agressive, je vous le promets. 😊\nMerci",
                'message'
            );
        } else {
            add('EN - Would love to join...', 'Hi {{firstname}},\n I would love to join your network :)', 'invitation');
            add(
                'EN - Common group',
                'Hello {{firstname}},\nI see that we are both members of the group [Name of the group].\nSince you are interested in [Subject], I would like to know what you think of [issue addressed by your offer]!\nI would appreciate being able to exchange with you on the subject!\nNo hard sell manipulation, I promise. 😊\nThanks',
                'message'
            );
        }
    }

    return {
        createDefaultTemplates,
        get,
        add,
        deleteTemplate,
        update,
    };
}
