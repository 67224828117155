import { useEffect, useRef, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import cl from 'classnames';
import { useRecoilValue, useRecoilState } from 'recoil';
import debounce from 'lodash.debounce';
import { tr } from '../../common/locale';
import Input from '../../components/Input';
import Avatar from '../../components/Avatar';
import Dropdown from '../../components/Dropdown';
import Icon from '../../components/Icon';
import useUserActions from '../../_actions/user.actions';
import { usersState, loadingUsersState, filterUsersState, filterTypesState } from '../../_states/user.states';

function Connections() {
    const actions = useUserActions();
    const scrollRef = useRef();
    const loading = useRecoilValue(loadingUsersState);
    const users = useRecoilValue(usersState);
    const [filterUsers, setFilterUsers] = useRecoilState(filterUsersState);
    const [filterTypes, setFilterTypes] = useRecoilState(filterTypesState);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        actions.list(true);
        if (scrollRef?.current) {
            scrollRef.current.pageLoaded = 0;
            scrollRef.current.el.scrollTop = 0;
        }
    }, [filterUsers, filterTypes]);
    useEffect(() => {
        setFilterUsers('');
        setFilterTypes('all');
    }, []);
    const search = (value) => {
        setFilterUsers(value);
    };
    const debouncedSearch = useCallback(debounce(search, 300), []);

    const hasMore = !users || users.count > users.items.length;
    console.log('hasMor', hasMore, users?.count, users?.items?.length);
    const fakeLoader = [];
    for (let i = 0; i <= 20; i++) {
        fakeLoader.push(
            <div key={i} className="list__fake list__fake--admin">
                <div className="list__col list__col--spacer" />
                <div className="list__col list__col--fill">
                    <div className="list__fake__item" />
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--large" />
                <div className="list__col list__col--email">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--small">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col">
                    <div className="list__fake__item list__col--large" />
                </div>
                <div className="list__col list__col--large">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--large">
                    <div className="list__fake__item" />
                </div>
                <div className="list__col list__col--action">
                    <div className="list__fake__item" />
                </div>
            </div>
        );
    }
    let filterLabel = tr('All');
    if (filterTypes === 'appsumo') {
        filterLabel = tr('Appsumo clients');
    } else if (filterTypes === 'clients') {
        filterLabel = tr('Other clients');
    } else if (filterTypes === 'trial') {
        filterLabel = tr('Trials');
    }
    return (
        <div className="content">
            <div className="content__body admin">
                <div className={cl('list__filter', { 'list__filter--activated': searchText })}>
                    <div className="list__filter--actions__unread">
                        <Dropdown
                            btnFace="bulk"
                            btnFaceIconSize={16}
                            label={filterLabel}
                            onClick={(e) => e.stopPropagation()}
                            onDoubleClick={(e) => e.stopPropagation()}
                        >
                            <Dropdown.Menu direction="se">
                                <Dropdown.ButtonItem
                                    btnLabel={tr('All')}
                                    active={filterTypes === 'all'}
                                    onClick={() => setFilterTypes('all')}
                                />
                                <Dropdown.ButtonItem
                                    btnLabel={tr('Appsumo Clients')}
                                    active={filterTypes === 'appsumo'}
                                    onClick={() => setFilterTypes('appsumo')}
                                />
                                <Dropdown.ButtonItem
                                    btnLabel={tr('Not appsumo Clients')}
                                    active={filterTypes === 'clients'}
                                    onClick={() => setFilterTypes('clients')}
                                />
                                <Dropdown.ButtonItem
                                    btnLabel={tr('Trial')}
                                    active={filterTypes === 'trial'}
                                    onClick={() => setFilterTypes('trial')}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>{' '}
                    <Input
                        isFilter
                        isNoBorder
                        icon="search"
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                            debouncedSearch(e.target.value);
                        }}
                        placeholder={tr('Search by name')}
                        type="search"
                        isFocusedDefault={false}
                    />
                </div>
                <div className="list__header">
                    <div className="list__cols">
                        <div className="list__col list__col--spacer" />
                        <div className="list__col list__col--fill">{tr('Users')}</div>
                        <div className="list__col list__col--large">{tr('Plan')}</div>
                        <div className="list__col list__col--email">{tr('Email')}</div>
                        <div className="list__col list__col--small">{tr('Id')}</div>
                        <div className="list__col list__col--small">{tr('Credits')}</div>
                        <div className="list__col list__col--large">{tr('Trial automations')}</div>
                        <div className="list__col list__col--large">{tr('Created at')}</div>
                        <div className="list__col list__col--large">{tr('Browsed at')}</div>
                        <div className="list__col list__col--action">{tr('See profile on LinkedIn')}</div>
                        <div className="list__col list__col--action">{tr('Actions')}</div>
                    </div>
                </div>
                <div
                    id="connectionsContent"
                    className={`connections--list list--pattern ${
                        loading || (users && users?.items.length > 0) ? '' : 'list--empty'
                    }`}
                >
                    {loading && (!users || users?.items.length === 0) && fakeLoader}
                    {users && users?.items.length > 0 ? (
                        <InfiniteScroll
                            dataLength={users?.items.length || 0}
                            next={actions.list}
                            className="list"
                            hasMore={hasMore}
                            loader={fakeLoader}
                            ref={scrollRef}
                            scrollableTarget="connectionsContent"
                        >
                            {users &&
                                users.items.map((currentUser) => (
                                    <div className="list__item" key={currentUser.id}>
                                        <div className="list__row">
                                            <div className="list__cols">
                                                <div className="list__col list__col--spacer" />
                                                <div className="list__col list__col--info list__col--fill">
                                                    <div className="connections__list__user">
                                                        <Avatar
                                                            className="connections__list__user__avatar"
                                                            src={currentUser.picture}
                                                            alt={`${currentUser.firstname} ${currentUser.lastname}`}
                                                        />
                                                        <div className="connections__list__user__item">
                                                            <div className="connections__list__user__info">
                                                                <span className="connections__list__user__name">
                                                                    <span className="name">
                                                                        {`${currentUser.firstname} ${currentUser.lastname}`}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div className="connections__list__user__message" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list__col list__col--large">
                                                    <span
                                                        className={`plan-label ${
                                                            currentUser.appsumo_code1 ? 'appsumo' : ''
                                                        } ${currentUser.affiliate_code ? 'partner' : ''} ${
                                                            currentUser.affiliate ? 'affiliate' : ''
                                                        } ${currentUser.plan?.product !== 'TRIAL' ? 'client' : ''}`}
                                                    >
                                                        <span className="ellipsis">
                                                            {currentUser.appsumo_code1 ? 'appsumo ' : ''}
                                                            {currentUser.affiliate_code ? 'partner ' : ''}
                                                            {currentUser.affiliate ? 'affiliate ' : ''}
                                                            {currentUser.plan?.product !== 'TRIAL'
                                                                ? currentUser.plan?.product
                                                                : ''}
                                                        </span>
                                                    </span>
                                                </div>

                                                <div className="list__col list__col--email">{currentUser.email}</div>
                                                <div className="list__col list__col--small">{currentUser.id}</div>
                                                <div className="list__col list__col--small">
                                                    {currentUser.team?.email_credits}
                                                </div>
                                                <div className="list__col list__col--large">
                                                    {moment(currentUser.plan?.trial_date_automations).format('LLL')}
                                                </div>
                                                <div className="list__col list__col--large">
                                                    {moment(currentUser.created_at).format('LLL')}
                                                </div>
                                                <div className="list__col list__col--large">
                                                    {moment(currentUser.browse_at).format('LLL')}
                                                </div>
                                                <div className="list__col">
                                                    <a
                                                        className="btn btn--secondary-blue"
                                                        href={`https://www.linkedin.com/in/${currentUser.linkedin_public_id}`}
                                                        type="secondary-blue"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {tr('See on LinkedIn')}
                                                    </a>
                                                </div>
                                                <div className="list__col list__col--action">
                                                    <Dropdown
                                                        iconRight
                                                        btnFaceIconSize={20}
                                                        btnFaceIcon="menu"
                                                        btnFace="link"
                                                        onClick={(e) => e.stopPropagation()}
                                                        onDoubleClick={(e) => e.stopPropagation()}
                                                    >
                                                        <Dropdown.Menu direction="w">
                                                            <Dropdown.ConfirmButtonItem
                                                                label={tr('Remove subscription')}
                                                                placement="left-center"
                                                                confirmLabel={tr(
                                                                    'The user will be downgraded to a trial and will lose all of the credits. Do you want to continue?'
                                                                )}
                                                                onConfirm={() => {
                                                                    actions.removeSubscription(currentUser.id);
                                                                }}
                                                            />
                                                            <Dropdown.ConfirmButtonItem
                                                                label={tr('Add trial automations')}
                                                                placement="left-center"
                                                                confirmLabel={tr(
                                                                    'The user will have 1 week of free trial for automations'
                                                                )}
                                                                onConfirm={() => {
                                                                    actions.trialAutomations(currentUser.id);
                                                                }}
                                                            />
                                                            <Dropdown.ConfirmButtonItem
                                                                label={tr('Delete member')}
                                                                placement="left-center"
                                                                confirmLabel={tr(
                                                                    'User will be deleted and its labels / pipeline lost definitively. Do you want to continue?'
                                                                )}
                                                                onConfirm={() => {
                                                                    actions.deleteUser(currentUser.id);
                                                                }}
                                                            />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </InfiniteScroll>
                    ) : (
                        <>
                            {!loading && (
                                <div className="empty">
                                    <Icon isEmpty icon="empty-connection" size={400} />
                                    <h1>{tr('Nothing here')}</h1>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
export default Connections;
