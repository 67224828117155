import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { tr } from '../../common/locale';
import Modal from '../../components/Modal';
import useLnUsersActions from '../../_actions/lnusers.actions';
import Flash from '../../components/Flash';
import useBoardAction from '../../_actions/board.actions';
import { boardLaneAllUsersState, boardLaneAllUsersLoadingState } from '../../_states/lnusers.states';

function BulkEnrichAction({ boardId, laneId, selectedUsers, closeCb }) {
    const lnUsersActions = useLnUsersActions();
    const boardActions = useBoardAction();
    const boardLaneAllUsers = useRecoilValue(boardLaneAllUsersState);
    const boardLaneAllUsersLoading = useRecoilValue(boardLaneAllUsersLoadingState);

    const [usersToProcess, setUsersToProcess] = useState(selectedUsers);

    const bulkEnrich = () => {
        lnUsersActions.enrich(usersToProcess);
    };

    useEffect(() => {
        if (!boardLaneAllUsersLoading && boardLaneAllUsers.length > 0) {
            setUsersToProcess(boardLaneAllUsers);
        }
    }, [boardLaneAllUsersLoading, boardLaneAllUsers]);

    useEffect(() => {
        if (boardId && laneId && !usersToProcess) {
            boardActions.getUsersAll(boardId, laneId);
        }
    }, []);

    return (
        <Modal
            closeCb={closeCb}
            labelValidate={tr('Enrich')}
            defaultIsOpen
            disabled={boardLaneAllUsersLoading}
            title={tr('Enrich profile of selected members')}
            validateCb={bulkEnrich}
        >
            <Flash color="blue" icon={boardLaneAllUsersLoading ? 'load' : null}>
                <p>{tr('{{count}} members selected', { count: boardLaneAllUsers.length })}</p>
            </Flash>
            <div className="mt mt--20">
                {tr(
                    "We are going to look for email addresses of selected members. Only members with a company will be enriched. Connections you've not updated won't have a company, so they will be ignored. It may take up to 10 minutes before members profiles get updated."
                )}
            </div>
        </Modal>
    );
}
export default BulkEnrichAction;
