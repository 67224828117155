import { useSetRecoilState } from 'recoil';
import { tr } from '../common/locale';
import useFetchWrapper from '../_helpers/fetchWrapper.helpers';
import { labelsState, loadingState } from '../_states/label.states';
import useError from '../_helpers/errorsWrapper.helpers';

export default function useLabelActions() {
    const fetchWrapper = useFetchWrapper();
    const setLabels = useSetRecoilState(labelsState);
    const setLoading = useSetRecoilState(loadingState);
    const { fetchNetworkError } = useError();
    const manageError = (e) => {
        setLoading(false);
        fetchNetworkError(null, e);
    };

    function get() {
        setLoading(true);
        return fetchWrapper
            .get('labels')
            .then((data) => {
                setLabels(data);
                setLoading(false);
            })
            .catch((e) => manageError(e));
    }

    function add(name, color) {
        setLoading(false);
        return fetchWrapper
            .post('labels', { name, color })
            .then((data) => {
                get();
                return data;
            })
            .catch((e) => manageError(e));
    }

    function deleteLabel(id) {
        setLoading(false);
        return fetchWrapper
            .delete(`labels/${id}`)
            .then(() => {
                get();
            })
            .catch((e) => manageError(e));
    }

    function update(id, name, color) {
        setLoading(false);
        return fetchWrapper
            .put(`labels/${id}`, { name, color })
            .then(() => {
                get();
            })
            .catch((e) => manageError(e));
    }

    function createDefaultTags() {
        add(tr('Colleagues'), 'color29');
        add(tr('Friends'), 'color11');
        add(tr('Leads'), 'color18');
    }

    return {
        createDefaultTags,
        get,
        add,
        deleteLabel,
        update,
    };
}
