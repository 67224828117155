import { useRef, useState } from 'react';
import { components } from 'react-select';
import Button from '../../components/Button';
import AsyncSelect from '../../components/AsyncSelect';
import Avatar from '../../components/Avatar';
import Modal from '../../components/Modal';
import useCampaignActions from '../../_actions/campaign.actions';
import utils from '../../common/utils';
import { tr } from '../../common/locale';

const Option = (props) => {
    const { data } = props;
    // const leadLabel = data.is_lead ? `${tr('Lead')} ` : '';
    return (
        <components.Option {...props}>
            <div className="board__add-member__avatar">
                <Avatar className="board__add-member__avatar__img" src={data.picture} alt={data.name} />
            </div>
            <div className="board__add-member__info">
                <div className="board__add-member__name">
                    <span>{data.name}</span>
                </div>
            </div>
        </components.Option>
    );
};

const CampaignMessagePreview = ({ campaign, message, messageAlternate }) => {
    const modalRef = useRef();
    const actions = useCampaignActions();
    const [defaultUsers, setDefaultUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const loadUsers = (newValue, callback) => {
        setIsOpen(true);
        actions.autocomplete(campaign.recipient_from_label_id, campaign.recipient_from_list_id, newValue, callback);
    };
    const onChange = (selected) => {
        if (selected) {
            setSelectedUser(selected);
        }
    };
    let messageToSend = null;
    if (selectedUser && message) {
        messageToSend = utils.nl2br(
            utils.canReplaceVariables(message, selectedUser) || !messageAlternate
                ? utils.replaceVariables(message, selectedUser)
                : utils.replaceVariables(messageAlternate, selectedUser)
        );
    }

    return (
        <div>
            <Modal
                overflow
                ref={modalRef}
                title={tr('Preview message')}
                className="message-preview"
                labelValidate={tr('Close')}
            >
                <p className="mb--10">{tr('Select a recipient to display the message that will be sent to them:')}</p>
                <AsyncSelect
                    blurInputOnSelect
                    isClearable
                    components={{ Option }}
                    onFocus={() => setIsOpen(true)}
                    loadOptions={loadUsers}
                    defaultOptions={defaultUsers}
                    onChange={onChange}
                    value={selectedUser}
                    placeholder={tr('Type in to search for a member')}
                    onBlur={() => setIsOpen(false)}
                    menuIsOpen={isOpen}
                />

                <div className="message-preview__item mt--20">{messageToSend}</div>
            </Modal>
            {message && utils.hasOneVariable(message) && (
                <Button
                    type="yellow"
                    label={tr('Messages preview')}
                    onClick={() => {
                        actions.autocomplete(
                            campaign.recipient_from_label_id,
                            campaign.recipient_from_list_id,
                            'e',
                            (users) => {
                                setDefaultUsers(users);
                                if (users.length > 0) {
                                    setSelectedUser(users[0]);
                                }
                            }
                        );
                        modalRef.current.open();
                    }}
                />
            )}
        </div>
    );
};

export default CampaignMessagePreview;
