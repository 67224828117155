import { atom } from 'recoil';
import { syncEffect } from 'recoil-sync';
import { object, string, number } from '@recoiljs/refine';

const objAtomRefine = object({
    id: number(),
    linkedin_id: string(),
    lead: object({
        firstname: string(),
        lastname: string(),
    }),
});

export const lnUserState = atom({
    key: 'lnUserState',
    default: null,
    effects: [syncEffect({ refine: objAtomRefine })],
});

export const sendingState = atom({
    key: 'sendingState',
    default: false,
});

export const loadingLnUserState = atom({
    key: 'loadingLnUserState',
    default: false,
});

export const enrichLnUserState = atom({
    key: 'enrichLnUserState',
    default: false,
});

export const lnUserConversationsState = atom({
    key: 'lnUserConversationsState',
    default: null,
});

export const lnUserConversationState = atom({
    key: 'lnUserConversationState',
    default: null,
});

export const lnUserUpdateConnectionLoadingState = atom({
    key: 'lnUserUpdateConnectionLoadingState',
    default: null,
});

export const lnUserFollowLoadingState = atom({
    key: 'lnUserFollowLoadingState',
    default: false,
});

export const messagesState = atom({
    key: 'lnUserMessagesState',
    default: null,
});

export const newMessageState = atom({
    key: 'lnUsernewMessageState',
    default: null,
});

export const newReactionState = atom({
    key: 'lnUsernewReactionState',
    default: null,
});

export const messageState = atom({
    key: 'lnUserMessageState',
    default: '',
});

export const fileState = atom({
    key: 'lnUserFileState',
    default: null,
});

export const attachmentsState = atom({
    key: 'attachmentsState',
    default: [],
});

export const notesState = atom({
    key: 'lnUserNotesState',
    default: null,
});

export const noteState = atom({
    key: 'lnUserNoteState',
    default: '',
});

export const loadingNotesState = atom({
    key: 'notesLoadingState',
    default: false,
});

export const loadingRemindState = atom({
    key: 'loadingRemindState',
    default: false,
});

export const loadingLabelsState = atom({
    key: 'loadingLabelsState',
    default: false,
});

export const lnUserUpdateConversationLoadingState = atom({
    key: 'lnUserUpdateConversationLoadingState',
    default: false,
});

export const lnUserSendingMessageState = atom({
    key: 'lnUserSendingMessageState',
    default: false,
});

export const syncLnUserState = atom({
    key: 'syncLnUserState',
    default: false,
});
