import { atom } from 'recoil';

export const alertState = atom({
    key: 'alertState',
    default: {
        type: 'error',
        message: null,
    },
});

export const snackMessageState = atom({
    key: 'snackMessageState',
    default: {
        text: null,
        type: 'info',
    },
});
