import React, { Component } from 'react';
import cl from 'classnames';
import ConfirmationPopover from './ConfirmationPopover';
import Button from './Button';

export default class ConfirmButtonItem extends Component {
    constructor(props) {
        super(props);
        this.confirmRef = React.createRef();
    }

    close = (e) => {
        if (e) {
            e.stopPropagation();
        }
        if (this.confirmRef.current && this.confirmRef.current.close) {
            this.confirmRef.current.close();
        }
    };

    onConfirm = (e) => {
        e.stopPropagation();
        const { closeCb, onConfirm } = this.props;
        if (closeCb) closeCb();
        if (onConfirm) onConfirm(e);
    };

    render() {
        const {
            type,
            onConfirm,
            icon,
            label,
            isLoading,
            className,
            disabled,
            closeCb,
            confirmLabel,
            isImportant,
            isBordered,
            placement,
            size,
            ...rest
        } = this.props;
        // onConfirm and closeCb are put in this list to remove them from ...rest.
        return (
            <ConfirmationPopover
                ref={this.confirmRef}
                text={confirmLabel}
                confirmCb={this.onConfirm}
                placement={placement}
            >
                <Button
                    className={cl(className)}
                    size={size}
                    isBordered={isBordered}
                    type={type}
                    isLoading={isLoading}
                    disabled={disabled}
                    label={label}
                    icon={icon}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.confirmRef.current.toggle();
                    }}
                    {...rest}
                />
            </ConfirmationPopover>
        );
    }
}
