import { useRef, useState, useEffect } from 'react';
import { components } from 'react-select';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from '../../components/Button';
import AsyncSelect from '../../components/AsyncSelect';
import Avatar from '../../components/Avatar';
import Modal from '../../components/Modal';
import useLnUsersActions from '../../_actions/lnusers.actions';
import useBoardActions from '../../_actions/board.actions';
import { tr } from '../../common/locale';
import { boardState, initBoardLanesState } from '../../_states/lnusers.states';
import { userState, upgradeState } from '../../_states/user.states';

const Option = (props) => {
    const { data } = props;
    // const leadLabel = data.is_lead ? `${tr('Lead')} ` : '';
    return (
        <components.Option {...props}>
            <div className="board__add-member__avatar">
                <Avatar className="board__add-member__avatar__img" src={data.picture} alt={data.name} />
            </div>
            <div className="board__add-member__info">
                <div className="board__add-member__name">
                    <span>{data.name}</span>
                    <span
                        className={`${data.is_connection ? 'status-relation' : 'status-contact'} ${
                            data.is_lead ? 'status-lead' : ''
                        } ${data.is_sponsor ? 'status-sponsor' : ''}`}
                    >
                        {data.is_connection ? tr('Connection') : tr('Contact')}
                    </span>
                </div>
                <div className="board__add-member__headline">{data.headline}</div>
            </div>
        </components.Option>
    );
};

const CustomAddCard = ({ laneId }) => {
    const modalAddCardRef = useRef();
    const board = useRecoilValue(boardState);
    const actions = useLnUsersActions();
    const boardActions = useBoardActions();
    const [defaultUsers, setDefaultUsers] = useState([]);
    const user = useRecoilValue(userState);
    const setUpgrade = useSetRecoilState(upgradeState);
    const setInitBoardLanes = useSetRecoilState(initBoardLanesState);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (isOpen) {
            actions.autocomplete('e', (users) => setDefaultUsers(users));
        }
    }, [isOpen]);
    const loadUsers = (newValue, callback) => {
        setIsOpen(true);
        actions.autocomplete(newValue, callback);
    };
    const onChange = (selected) => {
        if (selected) {
            actions.addboard({ id: selected?.value }, board.id, laneId).then(() => {
                setInitBoardLanes(false);
                boardActions.getBoard(board.id);
            });

            setIsOpen(false);
            modalAddCardRef.current.close();
        }
    };
    return (
        <div className="react-trello-lane__footer">
            <Modal
                overflow
                ref={modalAddCardRef}
                title={tr('Type in to search a member...')}
                className="board__modal--addcard"
                mainButtonType="transparent"
                labelValidate={tr('Close')}
            >
                <AsyncSelect
                    blurInputOnSelect
                    isClearable
                    components={{ Option }}
                    onFocus={() => setIsOpen(true)}
                    loadOptions={loadUsers}
                    defaultOptions={defaultUsers}
                    onChange={onChange}
                    placeholder={tr('Type in to search for a member')}
                    onBlur={() => setIsOpen(false)}
                    menuIsOpen={isOpen}
                />
            </Modal>
            {!board.campaign && (
                <Button
                    type="link"
                    label={tr('+ Add member')}
                    onClick={() => {
                        if (user.plan.product === 'SCRAPE') {
                            setUpgrade(true);
                        } else {
                            modalAddCardRef.current.open();
                        }
                    }}
                />
            )}
        </div>
    );
};

export default CustomAddCard;
