import React, { Component } from 'react';
import cl from 'classnames';
import PropTypes from 'prop-types';
import uniqueId from '../common/uniqueid';
import Icon from './Icon';

export default class Input extends Component {
    constructor(props) {
        super(props);
        this.inputId = uniqueId();
        this.inputRef = React.createRef();
        this.state = { showPassword: false };
    }

    componentDidMount() {
        const { isFocusedDefault } = this.props;
        if (isFocusedDefault && this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    onKeyDown = (e) => {
        const { onPressEnter, onPressCmdEnter } = this.props;
        if ((e.keyCode === 10 || e.keyCode === 13) && (e.ctrlKey || e.metaKey) && onPressCmdEnter) {
            e.preventDefault();
            onPressCmdEnter(e);
        }
        if (e.keyCode === 13 && onPressEnter) {
            e.preventDefault();
            onPressEnter(e);
        }
    };

    renderInput = () => {
        const {
            type,
            name,
            disabled,
            onChange,
            stopPropagation,
            value,
            error,
            placeholder,
            autocomplete,
            checked,
            size,
            customIconSize,
            length,
            maxlength,
            onBlur,
            onFocus,
            icon,
            enableTogglePassword,
        } = this.props;

        const { showPassword } = this.state;

        let iconSize = size;

        switch (iconSize) {
            case 'normal':
                iconSize = 24;
                break;
            case 'large':
                iconSize = 26;
                break;
            case 'small':
                iconSize = 22;
                break;
            default:
                iconSize = 24;
        }
        return (
            <div className="input__container">
                {icon ? <Icon icon={icon} size={customIconSize || iconSize} /> : null}
                {React.createElement(type === 'textarea' ? 'textarea' : 'input', {
                    className: cl('input__item', {
                        error,
                    }),
                    contenteditable: type === 'textarea',
                    id: this.inputId,
                    ref: this.inputRef,
                    'data-testid': 'input',
                    name,
                    checked,
                    autoComplete: autocomplete ? '' : 'off',
                    placeholder,
                    onClick: stopPropagation
                        ? (e) => {
                              e.stopPropagation();
                          }
                        : undefined,
                    onBlur,
                    onFocus,
                    onKeyDown: this.onKeyDown,
                    type: type === 'password' && showPassword ? 'text' : type,
                    value,
                    size: length,
                    maxLength: maxlength,
                    disabled,
                    onChange,
                })}
                {type === 'password' && enableTogglePassword && (
                    <span
                        onKeyDown={() => ({})}
                        role="button"
                        tabIndex={0}
                        onClick={() => this.setState({ showPassword: !showPassword })}
                    >
                        <Icon
                            icon={showPassword ? 'eye-crossed-out' : 'eye'}
                            size={iconSize}
                            className={cl('icon--password-toggle', { showed: showPassword })}
                        />
                    </span>
                )}
            </div>
        );
    };

    render() {
        const { hideLabel, error, label, className, size, type, icon, isFilter, isNoBorder, isNoPadding, disabled } =
            this.props;
        const errorComponent = error ? (
            <span className="input__error" data-testid="input-error">
                {error}
            </span>
        ) : null;
        const classesContainer = cl(
            'input',
            isFilter && type !== 'checkbox' && type !== 'radio' && type !== 'textarea' ? 'input--filter' : null,
            isNoBorder && type !== 'checkbox' && type !== 'radio' ? 'input--noborder' : null,
            isNoPadding && type !== 'checkbox' && type !== 'radio' ? 'input--nopadding' : null,
            { 'input--iconed': icon },
            { 'input--password-toggle': type === 'password' },
            `input--${size}`,
            type === 'checkbox' || type === 'radio' ? `input--${type}` : null,
            disabled ? 'input--disabled' : null,
            className
        );
        return (
            <div className={cl(classesContainer)}>
                <div className="input__line">
                    {hideLabel ? (
                        this.renderInput()
                    ) : (
                        <>
                            {label && (
                                <label className={cl('input__label')} htmlFor={this.inputId}>
                                    {label}
                                </label>
                            )}
                            {this.renderInput()}
                        </>
                    )}
                </div>
                {errorComponent}
            </div>
        );
    }
}

Input.propTypes = {
    value: PropTypes.string,
    size: PropTypes.oneOf(['large', 'normal', 'small', 'mini']),
    customIconSize: PropTypes.number,
    isFilter: PropTypes.bool,
    stopPropagation: PropTypes.bool,
    isNoBorder: PropTypes.bool,
    isNoPadding: PropTypes.bool,
    autocomplete: PropTypes.bool,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    hideLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    length: PropTypes.number,
    maxlength: PropTypes.string,
    name: PropTypes.string,
    checked: PropTypes.bool,
    isFocusedDefault: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'tel', 'email', 'checkbox', 'textarea', 'radio', 'number', 'search']),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onPressEnter: PropTypes.func,
    onPressCmdEnter: PropTypes.func,
    enableTogglePassword: PropTypes.bool,
};

Input.defaultProps = {
    isFocusedDefault: false,
    isFilter: false,
    isNoBorder: false,
    isNoPadding: false,
    name: null,
    value: undefined,
    stopPropagation: false,
    size: 'normal',
    customIconSize: 24,
    autocomplete: false,
    disabled: false,
    checked: undefined,
    placeholder: '',
    label: null,
    hideLabel: false,
    icon: null,
    error: null,
    className: null,
    type: 'text',
    onChange: null,
    onPressEnter: null,
    onPressCmdEnter: null,
    length: undefined,
    maxlength: undefined,
    onBlur: null,
    onFocus: null,
    enableTogglePassword: false,
};
