import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import Popover from './Popover';
import { userState } from '../_states/user.states';
import utils from '../common/utils';
import { tr } from '../common/locale';

function mapChildrenWithProps(children, childrenRefs, props) {
    if (children) {
        return React.Children.map(children, (child, index) => {
            if (React.isValidElement(child)) {
                // only add ref to non functional components
                return React.cloneElement(child, {
                    ...child.props,
                    ...props,
                    ref:
                        !child.type.prototype || (child.type.prototype && child.type.prototype.render)
                            ? (elem) => {
                                  // eslint-disable-next-line no-param-reassign
                                  childrenRefs[index] = elem;
                              }
                            : undefined,
                });
            }
            return child;
        });
    }
    return null;
}

function SubscriptionPopover({ placement, children, force }) {
    const popoverRef = useRef();
    const user = useRecoilValue(userState);
    const navigate = useNavigate();
    const isFree = user?.plan?.product === 'TRIAL' && utils.trialEnd(user) <= 0;
    if (!isFree && !force) {
        return children;
    }
    const content = <div>{tr('Please upgrade your subscription to use this feature.')}</div>;
    const childrenWithProps = mapChildrenWithProps(
        children,
        {},
        {
            onClick: () => {
                navigate('/subscription');
            },
        }
    );
    return (
        <Popover
            ref={popoverRef}
            contentClassName="popover__tooltip popover__upgrade"
            content={content}
            placement={placement || 'bottom-end'}
        >
            <div
                className="popover__tooltip__cursor"
                onMouseEnter={() => popoverRef.current.open()}
                onMouseLeave={() => popoverRef.current.close()}
            >
                {childrenWithProps}
            </div>
        </Popover>
    );
}

export default SubscriptionPopover;
