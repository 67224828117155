import React from 'react';
import cl from 'classnames';
import { useRecoilState } from 'recoil';
import Button from './Button';
import Tooltip from './Tooltip';
import Icon from './Icon';
import { toggleIconState } from '../_states/label.states';

export default function Label({
    size = 'normal',
    label = '',
    noBg = false,
    color = undefined,
    iconColor = undefined,
    className = '',
    closable = false,
    selected = false,
    isSelectable = false,
    closeCb = null,
    iconSize = 24,
    labelTooltip = undefined,
    onClick = undefined,
    testId = 'label',
    toggleIcon = false,
    ...rest
}) {
    const [toggleIconSt, setToggleIconSt] = useRecoilState(toggleIconState);

    const classes = cl(
        'label',
        { 'label--iconed': !label || (!toggleIconSt && toggleIcon) },
        { 'label--selectable': isSelectable },
        { 'label--selected': selected },
        { 'label--nobg': toggleIconSt && toggleIcon ? false : noBg },
        { 'label--nano': toggleIconSt && toggleIcon },

        size ? `label--${size}` : null,
        className
    );
    const onClickCb = (e) => {
        if (toggleIcon) {
            e.stopPropagation();
            setToggleIconSt(!toggleIconSt);
        }
        if (onClick) {
            onClick();
        }
    };
    return (
        <span
            role="button"
            tabIndex={0}
            className={classes}
            data-testid={testId}
            style={color ? { backgroundColor: color } : undefined}
            onClick={onClickCb}
            onKeyDown={() => null}
            {...rest}
        >
            {(!toggleIconSt || !toggleIcon) && (
                <>
                    {labelTooltip ? (
                        <Tooltip text={labelTooltip} direction="w">
                            <Icon size={iconSize} icon="label-filled" fill={!!iconColor} fillColor={iconColor} />
                        </Tooltip>
                    ) : (
                        <Icon size={iconSize} icon="label-filled" fill={!!iconColor} fillColor={iconColor} />
                    )}
                </>
            )}

            {(toggleIconSt || !toggleIcon) && (
                <span className="label__name" data-testid={`${testId}-label`}>
                    {label}
                </span>
            )}
            {closable && (
                <Button
                    className="ml--auto"
                    type="link"
                    iconSize={12}
                    icon="close"
                    onClick={(e) => {
                        e.stopPropagation();
                        closeCb();
                    }}
                    testId={`${testId}-button`}
                />
            )}
        </span>
    );
}
