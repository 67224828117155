import { useState, useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import Select, { components } from 'react-select';
import { tr } from '../../common/locale';
import Dropdown from '../../components/Dropdown';
import Modal from '../../components/Modal';
import Label from '../../components/Label';
import Flash from '../../components/Flash';
import useLeadsActions from '../../_actions/leads.actions';
import { labelsState } from '../../_states/label.states';
import Labels, { Colors } from '../lnuser/LabelsList';
import { boardsState } from '../../_states/lnusers.states';
import { toLnUsersState } from '../../_states/leads.states';

const LabelOption = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <Label
                noBg
                key={data.id}
                label={data.name}
                color={Colors[data.color].light}
                iconColor={Colors[data.color].dark}
            />
        </components.Option>
    );
};

function AddInboxModal({ exportState, searchId, filters, hasFilter, count, closeCb, lead }) {
    const actions = useLeadsActions();
    const exportRef = useRef(null);
    const boards = useRecoilValue(boardsState);
    const toLnUsers = useRecoilValue(toLnUsersState);
    const [selectedBoard, setSelectedBoard] = useState(null);
    const [selectedStep, setSelectedStep] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [processing, setProcessing] = useState(false);
    const labels = useRecoilValue(labelsState);
    useEffect(() => {
        if (!toLnUsers && processing) {
            exportRef.current.close();
        }
    }, [toLnUsers]);
    let title = tr('Add all Leads to my Inbox');
    if (lead) {
        title = tr('Add lead to my Inbox');
    } else if (hasFilter) {
        title = tr('Add {{count}} Leads to my Inbox', { count });
    }
    return (
        <Modal
            ref={exportRef}
            overflow
            defaultIsOpen
            closeCb={closeCb}
            title={title}
            labelValidate={tr('Add')}
            validateCb={() => {
                setProcessing(true);
                actions.toLnUsers(searchId, filters, selectedBoard, selectedStep, selectedLabel?.value, lead?.id);
            }}
            disabled={(!selectedBoard || !selectedStep) && exportState === 'pipeline'}
        >
            <p>
                {exportState === 'pipeline' && (
                    <strong>{tr('You can use Pipelines to create visual workflows with your leads.')}</strong>
                )}
                {exportState === 'inbox' && (
                    <strong>
                        {tr('Organizing your leads in the inbox enables you to easily invite and message them.')}
                    </strong>
                )}
            </p>
            {!lead && (
                <Flash color="blue" className="no-ellipsis my--20">
                    <span>
                        {tr('Add {{count}} Leads?', {
                            count,
                        })}
                    </span>
                </Flash>
            )}
            {exportState === 'inbox' && (
                <>
                    <div className="mb--10">{tr('Choose a label:')}</div>
                    <Select
                        closeMenuOnSelect
                        className="react-select-container"
                        classNamePrefix="react-select"
                        onChange={(selected) => {
                            setSelectedLabel(selected);
                        }}
                        components={{ Option: LabelOption }}
                        options={labels?.map((label) => ({
                            value: label.id,
                            name: label.name,
                            color: label.color,
                            label: label.name,
                        }))}
                        styles={{
                            multiValue: (styles, { data }) => ({
                                ...styles,
                                backgroundColor: Colors[data.color].light,
                            }),
                        }}
                    />
                    <Labels disableSelect />
                </>
            )}
            {exportState === 'pipeline' && (
                <>
                    <div className="mt--20 mb--10">{tr('Choose a Pipeline:')}</div>
                    <div className="btn--group">
                        <Dropdown
                            iconRight
                            btnFaceIconSize={16}
                            btnFace="secondary-dark"
                            btnClassName="btn--group-item"
                            label={selectedBoard?.name || tr('Select a Pipeline')}
                            onClick={(e) => e.stopPropagation()}
                            onDoubleClick={(e) => e.stopPropagation()}
                        >
                            {boards?.length > 0 && (
                                <Dropdown.Menu direction="s">
                                    {boards
                                        ?.filter((elem) => !elem.campaign)
                                        .map((element) => (
                                            <Dropdown.ButtonItem
                                                key={element?.id}
                                                btnLabel={element.name || tr('+ Add to Pipeline')}
                                                onClick={() => {
                                                    setSelectedBoard(element);
                                                    setSelectedStep(null);
                                                }}
                                            />
                                        ))}
                                </Dropdown.Menu>
                            )}
                        </Dropdown>
                        <Dropdown
                            iconRight
                            btnFaceIconSize={16}
                            btnFace="secondary-dark"
                            btnClassName="btn--group-item"
                            disabled={!selectedBoard}
                            label={selectedStep?.title || tr('Select a step')}
                            onClick={(e) => e.stopPropagation()}
                            onDoubleClick={(e) => e.stopPropagation()}
                        >
                            {selectedBoard && (
                                <Dropdown.Menu direction="s">
                                    {selectedBoard.lanes?.map((element) => (
                                        <Dropdown.ButtonItem
                                            key={element?.id}
                                            btnLabel={element.title}
                                            onClick={() => {
                                                setSelectedStep(element);
                                            }}
                                        />
                                    ))}
                                </Dropdown.Menu>
                            )}
                        </Dropdown>
                    </div>
                </>
            )}
        </Modal>
    );
}
export default AddInboxModal;
