/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

export default class Switch extends Component {
    handleKeyPress = (e) => {
        if (e.keyCode === 32 || e.code === '32') {
            const { checked, onChange } = this.props;
            e.preventDefault();
            onChange(!checked);
        }
    };

    render() {
        const { id, text, checked, onChange, name, optionLabels, isSmall, disabled } = this.props;
        return (
            <div className={cl('switch', { 'switch--small': isSmall })}>
                <div className="switch__container">
                    <input
                        type="checkbox"
                        name={name}
                        className="switch__checkbox"
                        id={id}
                        checked={checked}
                        onChange={(e) => onChange(e.target.checked)}
                        disabled={disabled}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    />
                    <label
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="switch"
                        aria-checked={checked}
                        className="switch__label"
                        htmlFor={id}
                        tabIndex={disabled ? -1 : 1}
                        onKeyDown={this.handleKeyPress}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <span
                            className="switch__inner"
                            data-yes={optionLabels[0]}
                            data-no={optionLabels[1]}
                            tabIndex={-1}
                        />
                        <span className="switch__controller" tabIndex={-1} />
                    </label>
                </div>
                {text ? <span className={cl('switch__text', { 'switch__text--disabled': disabled })}>{text}</span> : ''}
            </div>
        );
    }
}

// Set optionLabels for rendering.
Switch.defaultProps = {
    optionLabels: ['', ''],
    name: null,
    disabled: false,
    text: '',
    isSmall: false,
};

Switch.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    optionLabels: PropTypes.array,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    isSmall: PropTypes.bool,
};
