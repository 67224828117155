import React, { useState, useEffect } from 'react';

export default function Sticky({
    scrollContainer,
    top,
    stuckClasses = '',
    unstuckClasses = '',
    stuckStyles = {},
    unstuckStyles = {},
    children,
}) {
    const [isSticky, setSticky] = useState(false);
    const ref = React.createRef();

    const classes = isSticky ? stuckClasses : unstuckClasses;
    const styles = isSticky ? stuckStyles : unstuckStyles;

    const inlineStyles = {
        position: 'sticky',
        top: top || 105,
        ...styles,
    };

    const handleScroll = (container) => {
        if (container) {
            setSticky(container.getBoundingClientRect().top <= top);
        }
    };

    useEffect(() => {
        const container = ref.current;
        const listenedContainer = scrollContainer || window;
        listenedContainer.addEventListener('scroll', () => handleScroll(container));

        return () => {
            listenedContainer.removeEventListener('scroll', () => handleScroll(container));
        };
    }, [scrollContainer]);

    return (
        <div ref={ref} style={inlineStyles} className={classes}>
            {children}
        </div>
    );
}
