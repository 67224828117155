/* eslint-disable camelcase */
import { useRecoilValue, useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { PieChart, ResponsiveContainer, Label, Pie } from 'recharts';
import { userState, quotasState } from '../../_states/user.states';
import { tr } from '../../common/locale';
import 'rc-slider/assets/index.css';

function Settings() {
    const user = useRecoilValue(userState);
    const [quotas, setQuotas] = useRecoilState(quotasState);

    useEffect(() => {
        if (user) {
            setQuotas(user.quotas);
        }
    }, [user]);
    const daily_import_standard_used =
        user?.quotas?.daily_import_standard_used > quotas?.daily_import_standard_conf
            ? quotas?.daily_import_standard_conf
            : user?.quotas?.daily_import_standard_used || 0;
    const daily_import_salesnav_used =
        user?.quotas?.daily_import_salesnav_used > quotas?.daily_import_salesnav_conf
            ? quotas?.daily_import_salesnav_conf
            : user?.quotas?.daily_import_salesnav_used || 0;
    const daily_quota_invitations_used =
        user?.quotas?.daily_quota_invitations_used > quotas?.daily_quota_invitations_conf
            ? quotas?.daily_quota_invitations_conf
            : user?.quotas?.daily_quota_invitations_used || 0;
    const daily_quota_messages_used =
        user?.quotas?.daily_quota_messages_used > quotas?.daily_quota_messages_conf
            ? quotas?.daily_quota_messages_conf
            : user?.quotas?.daily_quota_messages_used || 0;
    const daily_quota_visits_used =
        user?.quotas?.daily_quota_visits_used > quotas?.daily_quota_visits_conf
            ? quotas?.daily_quota_visits_conf
            : user?.quotas?.daily_quota_visits_used || 0;
    const daily_quota_follow_used =
        user?.quotas?.daily_quota_follow_used > quotas?.daily_quota_follow_conf
            ? quotas?.daily_quota_follow_conf
            : user?.quotas?.daily_quota_follow_used || 0;
    const daily_quota_like_used =
        user?.quotas?.daily_quota_like_used > quotas?.daily_quota_like_conf
            ? quotas?.daily_quota_like_conf
            : user?.quotas?.daily_quota_like_used || 0;

    const renderPie = (used, conf) => (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie
                    data={[
                        {
                            value: conf - used,
                            fill: '#eee',
                            stroke: '#eee',
                        },
                        {
                            value: used,
                            fill: 'rgb(52, 116, 255)',
                            stroke: 'rgb(52, 116, 255)',
                        },
                    ]}
                    nameKey="name"
                    dataKey="value"
                    innerRadius="92%"
                    outerRadius="100%"
                    fill="black"
                >
                    <Label width={80} fill="black" position="center">
                        {`${used} / ${conf}`}
                    </Label>
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
    return (
        <div className="content__body consumption">
            <div className="settings__header">
                <h2 className="title">{tr('Consumption')}</h2>
            </div>
            <div className="settings__content">
                <div className="donut__section">
                    <h2 className="donut__legend">{tr('Daily users imports from Linkedin')}</h2>
                    {renderPie(daily_import_standard_used, quotas?.daily_import_standard_conf)}
                </div>

                <div className="donut__section">
                    <h2 className="donut__legend">{tr('Daily users imports from Sales Navigator')}</h2>
                    {renderPie(daily_import_salesnav_used, quotas?.daily_import_salesnav_conf)}
                </div>

                <div className="donut__section">
                    <h2 className="donut__legend">{tr('Daily connection requests sent')}</h2>
                    {renderPie(daily_quota_invitations_used, quotas?.daily_quota_invitations_conf)}
                </div>
                <div className="donut__section">
                    <h2 className="donut__legend">{tr('Daily messages sent')}</h2>
                    {renderPie(daily_quota_messages_used, quotas?.daily_quota_messages_conf)}
                </div>
                <div className="donut__section">
                    <h2 className="donut__legend">{tr('Daily profile visits')}</h2>
                    {renderPie(daily_quota_visits_used, quotas?.daily_quota_visits_conf)}
                </div>
                <div className="donut__section">
                    <h2 className="donut__legend">{tr('Daily followed users')}</h2>
                    {renderPie(daily_quota_follow_used, quotas?.daily_quota_follow_conf)}
                </div>
                <div className="donut__section">
                    <h2 className="donut__legend">{tr('Daily Liked posts')}</h2>
                    {renderPie(daily_quota_like_used, quotas?.daily_quota_like_conf)}
                </div>
            </div>
        </div>
    );
}
export default Settings;
