import { useEffect, useState, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { tr } from '../../common/locale';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Tooltip from '../../components/Tooltip';
import Label from '../../components/Label';
import Flash from '../../components/Flash';
import Modal from '../../components/Modal';
import Popover from '../../components/Popover';
import Dropdown from '../../components/Dropdown';
import messageUtils from '../../common/messageUtils';
import { labelsState } from '../../_states/label.states';
import Labels, { Colors } from '../lnuser/LabelsList';
import useLinkdInActions from '../../_actions/linkedin.actions';
import useLnUsersActions from '../../_actions/lnusers.actions';
import {
    bulkSendProgressState,
    enrichLoadingState,
    bulkInviteProgressState,
    bulkSyncProgressState,
    bulkMoveProgressState,
    bulkBoardProgressState,
    bulkLabelProgressState,
    bulkHideProgressState,
    bulkArchiveProgressState,
    bulkRemindProgressState,
    bulkUnreadProgressState,
    bulkProcessLeadsProgressState,
    bulkDisconnectProgressState,
    bulkAcceptInviteProgressState,
    boardsState,
} from '../../_states/lnusers.states';
import { userState, upgradeState } from '../../_states/user.states';
import BulkInviteAction from './BulkInviteAction';
import BulkSendMessageAction from './BulkSendMessageAction';

const LabelOption = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <Label
                noBg
                key={data.id}
                label={data.name}
                color={Colors[data.color].light}
                iconColor={Colors[data.color].dark}
            />
        </components.Option>
    );
};

function BulkActions({ selectedUsers, resetSelectedUsers, bulkActionsList }) {
    const linkedInActions = useLinkdInActions();
    const lnUsersActions = useLnUsersActions();
    const labels = useRecoilValue(labelsState);
    const enrichLoading = useRecoilValue(enrichLoadingState);
    const dropdownMenuRef = useRef();
    const [labelAdd, setLabelAdd] = useState(true);
    const [selectedBoard, setSelectedBoard] = useState(null);
    const [selectedStep, setSelectedStep] = useState(null);
    const user = useRecoilValue(userState);
    const refSelected = useRef();
    const bulkBoardRef = useRef();
    const bulkLabelsRef = useRef();
    const bulkEnrichRef = useRef();
    const boards = useRecoilValue(boardsState);
    const setUpgrade = useSetRecoilState(upgradeState);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [removeAllLabels, setRemoveAllLabels] = useState(false);
    const [bulkInviteProgress, setBulkInviteProgress] = useRecoilState(bulkInviteProgressState);
    const [bulkAcceptInviteProgress, setBulkAcceptInviteProgress] = useRecoilState(bulkAcceptInviteProgressState);

    const [bulkSendProgress, setBulkSendProgress] = useRecoilState(bulkSendProgressState);
    const [bulkSyncProgress, setBulkSyncProgress] = useRecoilState(bulkSyncProgressState);
    const [bulkBoardProgress, setBulkBoardProgress] = useRecoilState(bulkBoardProgressState);
    const [bulkLabelProgress, setBulkLabelProgress] = useRecoilState(bulkLabelProgressState);
    const [bulkHideProgress, setBulkHideProgress] = useRecoilState(bulkHideProgressState);
    const [bulkArchiveProgress, setBulkArchiveProgress] = useRecoilState(bulkArchiveProgressState);
    const [bulkUnreadProgress, setBulkUnreadProgress] = useRecoilState(bulkUnreadProgressState);
    const [bulkRemindProgress, setBulkRemindProgress] = useRecoilState(bulkRemindProgressState);
    const [bulkDisconnectProgress, setBulkDisconnectProgress] = useRecoilState(bulkDisconnectProgressState);
    const [bulkProcessLeadsProgress, setBulkProcessLeadsProgress] = useRecoilState(bulkProcessLeadsProgressState);
    const [bulkMoveProgress, setBulkMoveProgress] = useRecoilState(bulkMoveProgressState);
    // const [bulkInboxProgress, setBulkInboxProgress] = useRecoilState(bulkInboxProgressState);
    const [bulkInviteOpen, setBulkInviteOpen] = useState(false);
    const [bulkSendMessageOpen, setBulkSendMessageOpen] = useState(false);
    const canEnrich =
        user?.linkedin_public_id === 'jeancarriere' ||
        user?.linkedin_public_id === 'claireanselcarriere' ||
        user?.linkedin_public_id === 'serge-rohani';
    useEffect(() => {
        if (bulkSendProgress) {
            setBulkSendMessageOpen(false);
        }
    }, [bulkSendProgress]);
    useEffect(() => {
        if (bulkInviteProgress) {
            setBulkInviteOpen(false);
        }
    }, [bulkInviteProgress]);
    useEffect(() => {
        if (!enrichLoading) {
            bulkEnrichRef?.current?.close();
        }
    }, [enrichLoading]);
    useEffect(() => {
        if (bulkLabelProgress) {
            bulkLabelsRef?.current?.close();
        }
    }, [bulkLabelProgress]);
    useEffect(() => {
        if (bulkBoardProgress) {
            bulkBoardRef?.current?.close();
        }
    }, [bulkBoardProgress]);

    const bulkAddBoard = () => {
        lnUsersActions.bulkAddBoard(selectedBoard.id, selectedStep.id, selectedUsers);
    };
    const bulkRemoveBoard = () => {
        lnUsersActions.bulkRemoveBoard(selectedUsers);
    };
    const bulkAddLabels = () => {
        lnUsersActions.bulkAddLabels(selectedLabels, selectedUsers, labelAdd, removeAllLabels);
    };
    const bulkHide = () => {
        lnUsersActions.bulkHide(true);
    };
    const bulkShow = () => {
        lnUsersActions.bulkHide(false);
    };
    const bulkArchive = (archive) => {
        linkedInActions.bulkArchive(archive);
    };
    const bulkUnread = (unread) => {
        linkedInActions.bulkUnread(unread);
    };
    const bulkDisconnect = () => {
        linkedInActions.bulkDisconnect();
    };
    const bulkRemind = (remind) => {
        setBulkRemindProgress(undefined);
        lnUsersActions.bulkRemind(selectedUsers, remind);
    };
    const bulkProcessLeads = (process) => {
        lnUsersActions.bulkProcess(process);
    };
    const bulkMove = (move) => {
        lnUsersActions.bulkMove(move);
    };
    if (selectedUsers.length === 0) {
        return null;
    }
    const disableActions = user?.plan.product === 'SCRAPE';
    const renderContent = () => (
        <ul className="bulk-actions__selected-members">
            {selectedUsers.map((current) => (
                <li key={current.id}>
                    <img
                        className="avatar"
                        src={current.lead.picture}
                        alt={`${current.lead.firstname} ${current.lead.lastname}`}
                    />
                    <div className="bulk-actions__selected-members__info">
                        <span className="bulk-actions__selected-members__name">{`${current.lead.firstname} ${current.lead.lastname}`}</span>
                        <span className="bulk-actions__selected-members__status">
                            <span
                                className={`${current?.is_connection ? 'status-relation' : 'status-contact'} ${
                                    current?.is_lead ? 'status-lead' : ''
                                } ${current?.is_sponsor ? 'status-sponsor' : ''}`}
                            >
                                {messageUtils.userRelation(current)}
                            </span>
                        </span>
                    </div>
                </li>
            ))}
        </ul>
    );
    const renderAction = (action) => {
        if (action === 'archive') {
            return (
                <div key="archive" className="list__filter--actions__btn">
                    <Tooltip text={tr('Archive Conversations')} direction="s">
                        <Button
                            icon="inbox-archive"
                            isLoading={!!(bulkArchiveProgress !== undefined && bulkArchiveProgress !== 'done')}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkArchiveProgress(undefined);
                                    bulkArchive(true);
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'read') {
            return (
                <div key="read" className="list__filter--actions__btn">
                    <Tooltip text={tr('Mark conversations as read')} direction="s">
                        <Button
                            icon="mail-opened"
                            isLoading={!!(bulkUnreadProgress !== undefined && bulkUnreadProgress !== 'done')}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkUnreadProgress(undefined);
                                    bulkUnread(false);
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'unread') {
            return (
                <div key="unread" className="list__filter--actions__btn">
                    <Tooltip text={tr('Mark conversations as Unread')} direction="s">
                        <Button
                            icon="mail"
                            isLoading={!!(bulkUnreadProgress !== undefined && bulkUnreadProgress !== 'done')}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkUnreadProgress(undefined);
                                    bulkUnread(true);
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'disconnect') {
            return (
                <div key="disconnect" className="list__filter--actions__btn">
                    <Tooltip text={tr('Remove connections')} direction="s">
                        <Button
                            icon="user-remove"
                            isLoading={!!(bulkDisconnectProgress !== undefined && bulkDisconnectProgress !== 'done')}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkDisconnectProgress(undefined);
                                    bulkDisconnect();
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'inbox') {
            return (
                <div key="inbox" className="list__filter--actions__btn">
                    <Tooltip text={tr('Add to my Inbox')} direction="s">
                        <Button
                            icon="inbox"
                            isLoading={!!(bulkArchiveProgress !== undefined && bulkArchiveProgress !== 'done')}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkArchiveProgress(undefined);
                                    bulkArchive(false);
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }
        if (action === 'process') {
            return (
                <div key="process" className="list__filter--actions__btn">
                    <Tooltip text={tr('Archive Members')} direction="s">
                        <Button
                            icon="archive-circle"
                            isLoading={
                                !!(bulkProcessLeadsProgress !== undefined && bulkProcessLeadsProgress !== 'done')
                            }
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkProcessLeadsProgress(undefined);
                                    bulkProcessLeads(true);
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }
        if (action === 'unprocess') {
            return (
                <div key="unprocess" className="list__filter--actions__btn">
                    <Tooltip text={tr('Unarchive Members')} direction="s">
                        <Button
                            icon="unarchive-circle"
                            isLoading={
                                !!(bulkProcessLeadsProgress !== undefined && bulkProcessLeadsProgress !== 'done')
                            }
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkProcessLeadsProgress(undefined);
                                    bulkProcessLeads(false);
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'move') {
            return (
                <div key="move" className="list__filter--actions__btn">
                    <Tooltip text={tr('Archive Members')} direction="s">
                        <Button
                            icon="archive-circle"
                            isLoading={!!(bulkMoveProgress !== undefined && bulkMoveProgress !== 'done')}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkMoveProgress(undefined);
                                    bulkMove(true);
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }
        if (action === 'remove') {
            return (
                <div key="remove" className="list__filter--actions__btn">
                    <Tooltip text={tr('Add to my Inbox')} direction="s">
                        <Button
                            icon="inbox-plus"
                            isLoading={!!(bulkMoveProgress !== undefined && bulkMoveProgress !== 'done')}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkMoveProgress(undefined);
                                    bulkMove(false);
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }
        if (action === 'message') {
            return (
                <>
                    <div key="message" className="list__filter--actions__btn">
                        <Tooltip text={tr('Send a message')} direction="s">
                            <Button
                                icon="message"
                                type="bulk"
                                disabled={!!(bulkSendProgress !== undefined && bulkSendProgress !== 'done')}
                                onClick={() => {
                                    if (disableActions) {
                                        setUpgrade(true);
                                    } else {
                                        setBulkSendMessageOpen(true);
                                        setBulkSendProgress(undefined);
                                    }
                                }}
                            />
                        </Tooltip>
                        {bulkSendMessageOpen && (
                            <BulkSendMessageAction
                                closeCb={() => setBulkSendMessageOpen(false)}
                                selectedUsers={selectedUsers}
                            />
                        )}
                    </div>
                    <div className="list__filter--actions__btn">
                        <Tooltip text={tr('Reminder')} direction="s">
                            <Dropdown
                                btnFaceIcon="clock"
                                btnFace="bulk"
                                disabled={!!(bulkRemindProgress !== undefined && bulkRemindProgress !== 'done')}
                                ref={dropdownMenuRef}
                                onClick={(e) => {
                                    if (disableActions) {
                                        setUpgrade(true);
                                    } else {
                                        e.stopPropagation();
                                    }
                                }}
                                onDoubleClick={(e) => e.stopPropagation()}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.ButtonItem
                                        label={tr('In 1 day')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            bulkRemind({ type: '1day', date: new Date() });
                                        }}
                                    />
                                    <Dropdown.ButtonItem
                                        label={tr('In 3 days')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            bulkRemind({ type: '3days', date: new Date() });
                                        }}
                                    />
                                    <Dropdown.ButtonItem
                                        label={tr('In 1 week')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            bulkRemind({ type: '1week', date: new Date() });
                                        }}
                                    />
                                    <li className="dropdown__menu__item">
                                        <DatePicker
                                            selected={new Date()}
                                            minDate={new Date()}
                                            onChange={(date) => {
                                                bulkRemind({
                                                    type: 'custom',
                                                    date: moment(date).startOf('day').toDate(),
                                                });
                                                dropdownMenuRef?.current?.handleClickOutside();
                                            }}
                                            customInput={<Dropdown.ButtonItem label={tr('Pick a date')} />}
                                        />
                                    </li>
                                    <Dropdown.ButtonItem
                                        label={tr('Remove reminder')}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            bulkRemind({ type: 'remove', date: new Date() });
                                        }}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Tooltip>
                    </div>
                </>
            );
        }

        if (action === 'invite') {
            return (
                <div key="invite" className="list__filter--actions__btn">
                    <Tooltip text={tr('Request connection')} direction="s">
                        <Button
                            icon="user-add"
                            type="bulk"
                            disabled={!!(bulkInviteProgress !== undefined && bulkInviteProgress !== 'done')}
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkInviteOpen(true);
                                    setBulkInviteProgress(undefined);
                                }
                            }}
                        />
                    </Tooltip>
                    {bulkInviteOpen && (
                        <BulkInviteAction closeCb={() => setBulkInviteOpen(false)} selectedUsers={selectedUsers} />
                    )}
                </div>
            );
        }

        if (action === 'accept_invite') {
            return (
                <div key="accept_invite" className="list__filter--actions__btn">
                    <Tooltip text={tr('Ignore connection request')} direction="s">
                        <Button
                            icon="user-arrow-left-close"
                            type="bulk"
                            disabled={!!(bulkAcceptInviteProgress !== undefined && bulkAcceptInviteProgress !== 'done')}
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkAcceptInviteProgress(undefined);
                                    linkedInActions.bulkAcceptInvite(null, true);
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'withdraw_invite') {
            return (
                <div key="withdraw_invite" className="list__filter--actions__btn">
                    <Tooltip text={tr('Withdraw connection request')} direction="s">
                        <Button
                            icon="user-arrow-right-close"
                            type="bulk"
                            disabled={!!(bulkAcceptInviteProgress !== undefined && bulkAcceptInviteProgress !== 'done')}
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkAcceptInviteProgress(undefined);
                                    linkedInActions.bulkAcceptInvite(null, false);
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'hide') {
            return (
                <div key="hide" className="list__filter--actions__btn">
                    <Tooltip text={tr('Hide members')} direction="s">
                        <Button
                            icon="hide"
                            isLoading={!!(bulkHideProgress !== undefined && bulkHideProgress !== 'done')}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkHideProgress(undefined);
                                    bulkHide();
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'show') {
            return (
                <div key="unhide" className="list__filter--actions__btn">
                    <Tooltip text={tr('Unhide members')} direction="s">
                        <Button
                            icon="show"
                            isLoading={!!(bulkHideProgress !== undefined && bulkHideProgress !== 'done')}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkHideProgress(undefined);
                                    bulkShow();
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'synchronize') {
            return (
                <div key="synchronize" className="list__filter--actions__btn">
                    <Tooltip text={tr('Synchronize profiles from LinkedIn')} direction="s">
                        <Button
                            icon="sync"
                            isLoading={!!(bulkSyncProgress !== undefined && bulkSyncProgress !== 'done')}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    setBulkSyncProgress(undefined);
                                    linkedInActions.bulkSync();
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'enrich' && canEnrich) {
            return (
                <div key="enrich" className="list__filter--actions__btn">
                    <Tooltip text={tr('Find emails')} direction="s">
                        <Button
                            icon="arobase"
                            isLoading={!!enrichLoading}
                            type="bulk"
                            onClick={() => {
                                if (disableActions) {
                                    setUpgrade(true);
                                } else {
                                    bulkEnrichRef.current.open();
                                }
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (action === 'labels' && labels?.length > 0) {
            return (
                <>
                    <div key="labelsadd" className="list__filter--actions__btn">
                        <Tooltip text={tr('Add labels')} direction="s">
                            <Button
                                icon="label-add"
                                disabled={!!(bulkLabelProgress !== undefined && bulkLabelProgress !== 'done')}
                                type="bulk"
                                onClick={() => {
                                    if (disableActions) {
                                        setUpgrade(true);
                                    } else {
                                        setLabelAdd(true);
                                        setBulkLabelProgress(undefined);
                                        bulkLabelsRef?.current?.open();
                                    }
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div key="labelsrem" className="list__filter--actions__btn">
                        <Tooltip text={tr('Remove labels')} direction="s">
                            <Button
                                icon="label-remove"
                                disabled={!!(bulkLabelProgress !== undefined && bulkLabelProgress !== 'done')}
                                type="bulk"
                                onClick={() => {
                                    if (disableActions) {
                                        setUpgrade(true);
                                    } else {
                                        setLabelAdd(false);
                                        setBulkLabelProgress(undefined);
                                        bulkLabelsRef?.current?.open();
                                    }
                                }}
                            />
                        </Tooltip>
                    </div>
                </>
            );
        }

        if (action === 'boards' && boards?.length > 0) {
            return (
                <>
                    <div key="boards" className="list__filter--actions__btn">
                        <Tooltip text={tr('Add to a Pipeline')} direction="s">
                            <Button
                                icon="board-plus"
                                disabled={!!(bulkBoardProgress !== undefined && bulkBoardProgress !== 'done')}
                                type="bulk"
                                onClick={() => {
                                    if (disableActions) {
                                        setUpgrade(true);
                                    } else {
                                        setBulkBoardProgress(undefined);
                                        bulkBoardRef?.current?.open();
                                    }
                                }}
                            />
                        </Tooltip>
                    </div>
                    <div key="labels" className="list__filter--actions__btn">
                        <Tooltip text={tr('Remove from Pipeline')} direction="s">
                            <Button
                                icon="board-minus"
                                disabled={!!(bulkBoardProgress !== undefined && bulkBoardProgress !== 'done')}
                                type="bulk"
                                onClick={() => {
                                    if (disableActions) {
                                        setUpgrade(true);
                                    } else {
                                        bulkRemoveBoard();
                                    }
                                }}
                            />
                        </Tooltip>
                    </div>
                </>
            );
        }

        return null;
    };
    return (
        <>
            <div className="select-indicator">
                <Popover
                    ref={refSelected}
                    content={renderContent()}
                    contentClassName="bulk-actions__selected-user-popover"
                >
                    <div
                        className="bold"
                        onMouseEnter={() => refSelected.current.toggle()}
                        onBlur={() => refSelected.current.close()}
                    >
                        {tr('{{count}} members', { count: selectedUsers.length })}
                    </div>
                </Popover>

                <Tooltip className="tooltip--large" text={tr('Unselect all')} direction="s">
                    <Button
                        size="mini"
                        iconSize={14}
                        icon="close"
                        type="link-primary"
                        label={tr('Unselect')}
                        onClick={() => resetSelectedUsers()}
                    />
                </Tooltip>
            </div>

            {bulkActionsList.map((action) => renderAction(action))}

            <Modal
                ref={bulkEnrichRef}
                overflow
                className="bulk-actions-modal__labels"
                title={tr('Find emails')}
                validateCb={() => {
                    lnUsersActions.enrich();
                }}
                labelValidate={tr('Retrieve')}
            >
                <Flash fill color="orange" className="mb--20">
                    {tr(
                        "You're going to launch an enrichment process. We are going to look for email addresses of selected members. It may take up several minutes before members profiles get updated."
                    )}
                </Flash>
            </Modal>

            <Modal
                ref={bulkBoardRef}
                overflow
                className="bulk-actions-modal__boards"
                title={tr('Add to a Pipeline')}
                validateCb={bulkAddBoard}
                disabled={!selectedBoard || !selectedStep}
                labelValidate={tr('Add')}
            >
                <div className="btn--group">
                    <Dropdown
                        iconRight
                        btnFaceIconSize={16}
                        btnFace="secondary-dark"
                        btnClassName="btn--group-item"
                        label={selectedBoard?.name || tr('Select a Pipeline')}
                        onDoubleClick={(e) => e.stopPropagation()}
                    >
                        <Dropdown.Menu direction="s">
                            {boards
                                ?.filter((elem) => !elem.campaign)
                                .map((element) => (
                                    <Dropdown.ButtonItem
                                        key={element?.id}
                                        btnLabel={element.name || tr('+ Add to Pipeline')}
                                        onClick={() => {
                                            setSelectedBoard(element);
                                            setSelectedStep(null);
                                        }}
                                    />
                                ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown
                        iconRight
                        btnFaceIconSize={16}
                        btnFace="secondary-dark"
                        btnClassName="btn--group-item"
                        disabled={!selectedBoard}
                        label={selectedStep?.title || tr('Select a step')}
                        onClick={(e) => e.stopPropagation()}
                        onDoubleClick={(e) => e.stopPropagation()}
                    >
                        {selectedBoard && (
                            <Dropdown.Menu direction="s">
                                {selectedBoard.lanes?.map((element) => (
                                    <Dropdown.ButtonItem
                                        key={element?.id}
                                        btnLabel={element.title}
                                        onClick={() => {
                                            setSelectedStep(element);
                                        }}
                                    />
                                ))}
                            </Dropdown.Menu>
                        )}
                    </Dropdown>
                </div>
            </Modal>
            <Modal
                ref={bulkLabelsRef}
                overflow
                className="bulk-actions-modal__labels"
                title={labelAdd ? tr('Add labels') : tr('Remove labels')}
                validateCb={bulkAddLabels}
                disabled={!removeAllLabels && (!selectedLabels || selectedLabels.length === 0)}
                labelValidate={labelAdd ? tr('Add') : tr('Remove')}
            >
                <Select
                    closeMenuOnSelect={false}
                    isMulti
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={(selected) => {
                        setSelectedLabels(selected.map((item) => item.value));
                    }}
                    components={{ Option: LabelOption }}
                    options={labels?.map((label) => ({
                        value: label.id,
                        name: label.name,
                        color: label.color,
                        label: label.name,
                    }))}
                    styles={{
                        multiValue: (styles, { data }) => ({
                            ...styles,
                            backgroundColor: Colors[data.color].light,
                        }),
                    }}
                />
                <Labels disableSelect />
                {!labelAdd && (
                    <div className="mt--20">
                        <Input
                            type="checkbox"
                            label="Remove all labels"
                            onChange={(e) => setRemoveAllLabels(e.target.checked)}
                        />
                    </div>
                )}
            </Modal>
        </>
    );
}
export default BulkActions;
