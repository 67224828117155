/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import cl from 'classnames';
import PropTypes from 'prop-types';
import Animate, { AnimatePresence } from '../common/Animate';
import Button from './Button';
import withClickOutside from '../common/withClickOutside';
import { tr } from '../common/locale';
import Icon from './Icon';

class Modal extends Component {
    constructor(props) {
        super(props);
        const { defaultIsOpen, enableClickOutsideCb } = this.props;
        this.state = { open: defaultIsOpen, isLoading: false };
        this.modalRef = React.createRef();
        this.clickOutsideRef = null;
        if (defaultIsOpen) {
            enableClickOutsideCb();
        }
    }

    componentDidMount() {
        this.handleEscFunction('add');
    }

    componentDidUpdate(prevProps) {
        const { isLoading } = this.state;
        const { closesWithEscape: prevClosesWithEscape } = prevProps;
        const { hasErrors, closesWithEscape } = this.props;
        if (closesWithEscape && !prevClosesWithEscape) this.handleEscFunction('add');
        else if (!closesWithEscape && prevClosesWithEscape) this.handleEscFunction();
        if (hasErrors && isLoading) {
            this.setLoading(false);
        }
    }

    componentWillUnmount() {
        this.handleEscFunction();
    }

    handleClickOutside = () => {
        this.close();
    };

    setLoading(loading) {
        this.setState({ isLoading: loading });
    }

    handleEscFunction = (key) => {
        const { closesWithEscape } = this.props;
        if (closesWithEscape && key === 'add') document.addEventListener('keydown', this.escFunction);
        else document.removeEventListener('keydown', this.escFunction);
    };

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.close();
        }
    };

    open = () => {
        const { enableClickOutsideCb } = this.props;
        enableClickOutsideCb();
        this.setState({ open: true });
    };

    close = () => {
        const { closeCb } = this.props;
        this.setState({ open: false, isLoading: false }, closeCb);
    };

    validate = () => {
        const { validateCb } = this.props;
        if (validateCb) {
            this.setLoading(true);
            validateCb();
        } else {
            this.close();
        }
    };

    displayCloseButton = () => {
        const { isFull, disableCloseOutside, displayCloseButtonOutside } = this.props;
        return !isFull && !disableCloseOutside && displayCloseButtonOutside;
    };

    renderFooter = () => {
        const {
            footer,
            labelValidate,
            labelCancel,
            errorLabel,
            validateCb,
            validateBtnProps,
            disabled,
            mainButtonType,
            mainButtonBordered,
            mainButtonSize,
            testId,
            loadingLayer,
        } = this.props;
        const { isLoading } = this.state;

        if (footer === false) {
            return null;
        }
        if (!footer) {
            return (
                <div className={cl('modal__footer')}>
                    <div className="modal__footer__error">{errorLabel}</div>
                    <>
                        {validateCb && (
                            <Button
                                testId={`${testId}-cancel-button`}
                                label={labelCancel || tr('Cancel')}
                                type="transparent"
                                onClick={this.close}
                            />
                        )}
                        <Button
                            type={mainButtonType}
                            isBordered={mainButtonBordered}
                            size={mainButtonSize}
                            testId={`${testId}-validate-button`}
                            label={labelValidate || tr('Validate')}
                            onClick={this.validate}
                            isLoading={!loadingLayer && isLoading}
                            disabled={disabled || (loadingLayer && isLoading)}
                            {...validateBtnProps}
                        />
                    </>
                </div>
            );
        }
        return <div className="modal__footer">{footer}</div>;
    };

    getRef = () => {
        if (this.displayCloseButton()) {
            this.clickOutsideRef = this.modalRef;
        } else {
            this.clickOutsideRef = null;
        }
        return this.modalRef;
    };

    renderLoadingLayer = () => {
        const { loadingLayerMessage } = this.props;
        return (
            <div className="modal__body__loading-layer">
                <Icon fill icon="load" size={60} />
                <span>{loadingLayerMessage}</span>
            </div>
        );
    };

    render() {
        const { open, isLoading } = this.state;

        const { title, children, className, isFull, header, testId, noHeader, noFooter, loadingLayer, overflow } =
            this.props;
        const tit = open && title;
        const onLoadingLayer = isLoading && loadingLayer;
        return ReactDOM.createPortal(
            <AnimatePresence>
                {open && (
                    <div
                        className={cl('modal', className, {
                            'modal--full': isFull,
                        })}
                        onKeyDown={() => null}
                        onClick={(e) => e.stopPropagation()}
                        data-testid={testId}
                    >
                        <Animate className="modal__wrapper">
                            {this.displayCloseButton() && (
                                <div className="modal__close--outside">
                                    <Icon size={30} icon="close" />
                                </div>
                            )}
                            <div ref={this.getRef()} className="modal__content">
                                {!noHeader && (
                                    <div className="modal__header">{header || <h1 className="title">{tit}</h1>}</div>
                                )}
                                <div
                                    className={cl('modal__body', {
                                        'modal__body--blur': onLoadingLayer,
                                        'modal__body--overflow': overflow,
                                    })}
                                >
                                    {onLoadingLayer && this.renderLoadingLayer()}
                                    {children}
                                </div>
                                {!noFooter && this.renderFooter()}
                            </div>
                        </Animate>
                    </div>
                )}
            </AnimatePresence>,
            document.body
        );
    }
}

Modal.defaultProps = {
    isFull: false,
    disableCloseOutside: false,
    title: null,
    header: null,
    hasErrors: false,
    errorLabel: null,
    className: null,
    footer: null,
    validateCb: null,
    labelValidate: null,
    labelCancel: null,
    closeCb: null,
    defaultIsOpen: false,
    validateBtnProps: {},
    mainButtonType: 'primary',
    mainButtonBordered: false,
    mainButtonSize: undefined,
    testId: 'modal',
    closesWithEscape: false,
    displayCloseButtonOutside: false,
    noHeader: false,
    noFooter: false,
    loadingLayerMessage: '',
    loadingLayer: false,
    overflow: false,
};

Modal.propTypes = {
    isFull: PropTypes.bool,
    disableCloseOutside: PropTypes.bool,
    hasErrors: PropTypes.bool,
    header: PropTypes.node,
    errorLabel: PropTypes.string,
    defaultIsOpen: PropTypes.bool,
    children: PropTypes.node.isRequired,
    validateCb: PropTypes.func,
    closeCb: PropTypes.func,
    footer: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.bool]),
    title: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string]),
    className: PropTypes.string,
    labelValidate: PropTypes.string,
    labelCancel: PropTypes.string,
    validateBtnProps: PropTypes.object,
    mainButtonType: PropTypes.string,
    mainButtonBordered: PropTypes.bool,
    mainButtonSize: PropTypes.string,
    testId: PropTypes.string,
    closesWithEscape: PropTypes.bool,
    displayCloseButtonOutside: PropTypes.bool,
    noHeader: PropTypes.bool,
    noFooter: PropTypes.bool,
    loadingLayerMessage: PropTypes.string,
    loadingLayer: PropTypes.bool,
    overflow: PropTypes.bool,
};

export default withClickOutside(Modal, '.ignore-click-outside-modal', true);
