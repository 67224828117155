import { useRecoilValue } from 'recoil';
import { tr } from '../../common/locale';
import Modal from '../../components/Modal';
import Flash from '../../components/Flash';
import Icon from '../../components/Icon';
import { userState } from '../../_states/user.states';
import useUserActions from '../../_actions/user.actions';
import utils from '../../common/utils';

function Welcome() {
    const user = useRecoilValue(userState);
    const userActions = useUserActions();
    if (!user) {
        return null;
    }
    if (user.tours && user.tours.indexOf('welcome') !== -1) {
        return null;
    }

    const diffDayEndTrial = utils.trialEnd(user);

    return (
        <Modal
            className="welcome-message"
            closeCb={() => {
                userActions.updateTour('welcome');
            }}
            labelValidate={tr('I accept the Kanbox Terms of Service')}
            defaultIsOpen
            title={tr('Welcome to Kanbox!')}
            mainButtonSize="large"
        >
            <div className="welcome-message__image">
                <Icon isEmpty size={200} icon="empty-message" />
            </div>
            <p className="welcome-message__main">
                {tr('Unlock the full potential of LinkedIn and Sales Navigator for a free 15-Day Trial.')}
            </p>
            <Flash color="blue">{tr('{{count}} days free trial left.', { count: diffDayEndTrial })}</Flash>
            <p className="welcome-message__link">
                {tr('By clicking the "Accept" button below, I acknowledge that I have read and accept the Kanbox')}{' '}
                <a href={tr('https://www.kanbox.io/terms-of-service')} title={tr('Terms of service')} target="blank">
                    {tr('Terms of service')}.
                </a>
            </p>
        </Modal>
    );
}
export default Welcome;
