import { useRef, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Outlet } from 'react-router-dom';
import { Crisp } from 'crisp-sdk-web';
import { alertState, snackMessageState } from '../_states/alert.states';
import useAlertActions from '../_actions/alert.actions';
import ServiceMessage from '../components/ServiceMessage';
import SnackBar from '../components/SnackBar';

export default function Main({ children }) {
    const alert = useRecoilValue(alertState);
    const snack = useRecoilValue(snackMessageState);
    const snackRef = useRef(null);
    const alertActions = useAlertActions();
    useEffect(() => {
        Crisp.configure('470f5ea3-23a7-49df-b437-063bae21d9a7');
        if (snack.text) {
            snackRef.current.openSnackBar(snack.text);
        }
    });
    return (
        <>
            {alert.message && <ServiceMessage type={alert.type} text={alert.message} closeCb={alertActions.clear} />}
            <SnackBar ref={snackRef} type={snack.type} />
            {children || <Outlet />}
        </>
    );
}
