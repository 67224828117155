import { useRef, useState, useEffect } from 'react';
import { inferSchema, initParser } from 'udsv';
import { useRecoilState, useRecoilValue } from 'recoil';
import { tr } from '../../common/locale';
import Button from '../../components/Button';
import useLeadsActions from '../../_actions/leads.actions';
import Modal from '../../components/Modal';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Flash from '../../components/Flash';
import { statusImportLeadsState, searchesState } from '../../_states/leads.states';

function ImportLeads({ importLeadsRef }) {
    const actions = useLeadsActions();
    const fileLeadRef = useRef();
    const searches = useRecoilValue(searchesState);
    const [selectedSearch, setSelectedSearch] = useState(null);
    const [listName, setListName] = useState('');
    const [dragActive, setDragActive] = useState(false);
    const [statusImportLeads, setStatusImportLeads] = useRecoilState(statusImportLeadsState);
    const [csvLnId, setCsvLnId] = useState('');
    const [csvFirstname, setCsvFirstname] = useState('');
    const [csvEmail, setCsvEmail] = useState('');
    const [csvPhone, setCsvPhone] = useState('');
    const [csvLastname, setCsvLastname] = useState('');
    const [csvJob, setCsvJob] = useState('');
    const [csvLocation, setCsvLocation] = useState('');
    const [csvCompany, setCsvCompany] = useState('');
    const [csvLanguage, setCsvLanguage] = useState('');
    const [csvIcebreaker, setCsvIcebreaker] = useState('');

    const [schema, setSchema] = useState(null);
    const [parsed, setParsed] = useState(null);
    const [errorParse, setErrorParse] = useState(null);

    useEffect(() => {
        if (statusImportLeads) {
            importLeadsRef.current.setLoading(false);
        }
    }, [statusImportLeads]);

    const onFileLeadChange = (e) => {
        setErrorParse(null);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            // eslint-disable-next-line prefer-destructuring
            files = e.dataTransfer.files;
        } else if (e.target) {
            // eslint-disable-next-line prefer-destructuring
            files = e.target.files;
        }
        setDragActive(false);
        const reader = new FileReader();
        reader.onload = () => {
            try {
                const currentSchema = inferSchema(reader.result);
                console.log('currentSchema', currentSchema);
                currentSchema.cols.forEach((col) => {
                    // eslint-disable-next-line no-param-reassign
                    col.type = 's';
                });
                const parser = initParser(currentSchema);
                const currentParsed = parser.typedObjs(reader.result);
                if (currentParsed.length > 1000) {
                    setErrorParse(tr('Cannot import more than 1000 rows.'));
                }
                setSchema(currentSchema);
                setParsed(currentParsed);
            } catch (ex) {
                setErrorParse(tr('Could not parse CSV file'));
            }
        };
        reader.readAsText(files[0]);
    };

    const handleFileDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const reset = () => {
        setDragActive(false);
        setListName('');
        setSchema(null);
        setParsed(null);
        setStatusImportLeads(null);
        setSelectedSearch(null);
    };

    const getSelect = (label, setState, isRequired) => {
        console.log(
            'schema.cols',
            schema.cols,
            schema.cols.map((current) => ({
                value: current.name,
                name: current.name,
                label: current.name,
            }))
        );
        return (
            <Select
                label={label + (isRequired ? ' *' : '')}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={`${tr('Select')} ${label}`}
                onChange={(selected) => {
                    setState(selected.value);
                }}
                options={schema.cols.map((current) => ({
                    value: current.name,
                    name: current.name,
                    label: current.name,
                }))}
            />
        );
    };
    const lists = searches?.items?.filter((item) => item.name !== 'Individual scrapes');

    return (
        <Modal
            className="csv-import-modal"
            ref={importLeadsRef}
            disabled={(!listName && !selectedSearch) || !parsed || !csvFirstname || !csvLastname || !csvLnId}
            labelValidate={statusImportLeads ? tr('Close') : tr('Import')}
            validateCb={
                statusImportLeads
                    ? null
                    : () => {
                          actions.createSearchFromLeads(
                              listName,
                              selectedSearch,
                              {
                                  firstname: csvFirstname,
                                  lastname: csvLastname,
                                  location: csvLocation,
                                  job: csvJob,
                                  email: csvEmail,
                                  phone: csvPhone,
                                  company: csvCompany,
                                  icebreaker: csvIcebreaker,
                                  language: csvLanguage,
                                  linkedin_public_id: csvLnId,
                              },
                              parsed
                          );
                      }
            }
            closeCb={reset}
            title={tr('Import from a CSV of leads')}
            Ò
        >
            {statusImportLeads && (
                <>
                    {statusImportLeads.errors && statusImportLeads.errors.length > 0 && (
                        <Flash color="red">
                            {tr('{{count}} leads could not be imported because they are missing mandatory fields', {
                                count: statusImportLeads.errors.length,
                            })}
                        </Flash>
                    )}
                    {statusImportLeads.success > 0 && (
                        <Flash color="green" className="mt--20">
                            {tr('{{count}} leads were imported', {
                                count: statusImportLeads.success,
                            })}
                        </Flash>
                    )}
                </>
            )}
            {!statusImportLeads && (
                <>
                    <p className="mb--15">{tr('Upload a CSV containing a list of leads.')}</p>
                    <div className="flex">
                        <Input
                            label={tr('Name your list')}
                            placeholder={tr('Type in a name')}
                            value={listName}
                            onChange={(e) => setListName(e.target.value)}
                        />
                        <span className="mr--20 ml--20">{tr('OR')}</span>
                        <Select
                            label={tr('Select a list')}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder={tr('Select a list')}
                            onChange={(selected) => {
                                setSelectedSearch(selected.value);
                            }}
                            isDisabled={lists?.length === 0}
                            options={lists?.map((current) => ({
                                value: current.id,
                                name: current.name,
                                label: current.name,
                            }))}
                        />
                    </div>

                    {!schema && (
                        <div
                            className={`btn--dropzone-container mt--15 mb--20 ${dragActive ? 'active' : ''}`}
                            onDragEnter={handleFileDrag}
                            onDragLeave={handleFileDrag}
                            onDragOver={handleFileDrag}
                            onDrop={onFileLeadChange}
                        >
                            <input
                                style={{ display: 'none' }}
                                ref={fileLeadRef}
                                type="file"
                                accept=".csv"
                                onChange={onFileLeadChange}
                            />
                            <Button
                                type="dropzone"
                                label={tr('+ Drag a file or click to choose a CSV file...')}
                                onClick={() => {
                                    fileLeadRef.current.click();
                                }}
                            />
                        </div>
                    )}
                    {errorParse && <Flash color="red">{errorParse}</Flash>}

                    {schema && (
                        <>
                            <p className="my--30">
                                <strong>
                                    {tr(
                                        'Select the CSV fields that correspond to the information to be imported. We only retrieve the necessary information to work with this list in Kanbox, such as searching for emails, running a campaign, or adding them to a pipeline.'
                                    )}
                                </strong>
                            </p>
                            <div className="csv-import">
                                <div className="csv-import__fields">
                                    {getSelect(tr('Linkedin Id or URL'), setCsvLnId, true)}
                                    {getSelect(tr('Firstname CSV'), setCsvFirstname, true)}
                                    {getSelect(tr('Lastname CSV'), setCsvLastname, true)}
                                    {getSelect(tr('Job CSV'), setCsvJob)}
                                    {getSelect(tr('Company CSV'), setCsvCompany)}
                                </div>
                                <div className="csv-import__fields">
                                    {getSelect(tr('Location CSV'), setCsvLocation)}
                                    {getSelect(tr('Email'), setCsvEmail)}
                                    {getSelect(tr('Phone'), setCsvPhone)}
                                    {getSelect(tr('Language'), setCsvLanguage)}
                                    {getSelect(tr('Icebreaker'), setCsvIcebreaker)}
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
}
export default ImportLeads;
